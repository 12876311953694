import { reverseRouteName } from '@/../config/helper'

export function getFormatted (value, rate = false, vat = false, currency = false, symbol = true, allowFree = true) {
  if (value === 0 && allowFree) {
    return this.$i18n.t('FREE')
  } else {
    return this.calculatePrice(value, false, symbol, vat, rate)
  }
}

export function dotValue (value) {
  return value.replace(',', '.')
}

export function reverseRoute (name) {
  const defaultUrl = '#'
  const urls = this.$store.state.urls
  if (urls[this.lang] && urls[this.lang][name]) {
    return this.$link(convertUrl(urls[this.lang][name]))
  }
  this.$store.dispatch('addUrlCommit', {name, component: this})
  return defaultUrl
}

function convertUrl (url) {
  return url ? '/' + url.replace(/^\/|\/$/g, '') : url
}

export function getMinPromo (code) {
  return ''
}

export function cdnUrl (url) {
  return this.confCdnUrl + convertUrl('/' + url)
}

export function getSuperSaverPrice (rate = false, vat = false, currency = false, symbol = true, allowFree = true) {
  return ''
}

export function getDashName (designerName) {
  return designerName.replace(' ', '-')
}

export function legacyEscapeName (name) {
  return reverseRouteName(name)
}
export function titleCase (value) {
  return value.toLowerCase().replace(
    /(^|[\s-])\S/g,
    (match) => match.toUpperCase()
  )
}
