export default (Vue) => {
  const types = require('../types')
  const {createHTTPClient} = require('./../../utils/http')
  const config = require('./../../../config')
  const http = createHTTPClient()

  const state = {
    prescriptions: [],
    currentPrescription: null
  }

  const mutations = {
    [types.ACCOUNT_FETCH_ALL_PRESCRIPTIONS]: (state, data) => {
      state.prescriptions = data
    },
    [types.ACCOUNT_FETCH_PRESCRIPTION]: (state, data) => {
      state.currentPrescription = data
    }
  }

  const actions = {
    saveAccountPrescription: async ({ state, dispatch }, data) => {
      const { data: result } = await http.post(config.profiles.savePrescription, data, {
        withCredentials: true,
        headers: { Authorization: `Bearer ${state.user && state.user.hashedPassword}` }
      })
      await dispatch('fetchCurrentPrescription', result.data.id)
    },
    fetchAccountPrescriptions: async ({ state, commit }) => {
      const { data: result } = await http.get(config.profiles.fetchPrescriptions, {
        withCredentials: true,
        headers: { Authorization: `Bearer ${state.user && state.user.hashedPassword}` }
      })
      commit(types.ACCOUNT_FETCH_ALL_PRESCRIPTIONS, result)
    },
    fetchCurrentPrescription: async ({ commit, state }, id) => {
      const { data: result } = await http.get(config.profiles.findPrescription + '/' + id, {
        withCredentials: true,
        headers: { Authorization: `Bearer ${state.user && state.user.hashedPassword}` }
      })
      commit(types.ACCOUNT_FETCH_PRESCRIPTION, result)
    },
    deletePrescription: async ({ dispatch, state }, id) => {
      await http.delete(config.profiles.deletePrescription.replace(/:id/, id), {
        withCredentials: true,
        headers: { Authorization: `Bearer ${state.user && state.user.hashedPassword}` }
      })
      dispatch('fetchAccountPrescriptions')
    }
  }

  const getters = {}

  return {
    state,
    mutations,
    actions,
    getters
  }
}

