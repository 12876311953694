export default {
  loadScript: function (emitCode, scriptTag, params = {}, url) {
    scriptTag = scriptTag || 'script'
    return new Promise((resolve, reject) => {
      try {
        let script = document.createElement(scriptTag)
        script.setAttribute('type', 'text/javascript')
        if (url) {
          script.setAttribute('src', url)
        } else {
          script.innerHTML = this[emitCode](params)
        }
        document.head.appendChild(script)
        resolve()
      } catch (e) {
        reject(e)
      }
    })
  },
  getGa: function (code) {
    this.loadScript('gaNoScript', 'noscript', code || 'GTM-5Z59D4')
    return `var _gaq = _gaq || [];
      _gaq.push(['_setAccount', 'EMPTY']);
      _gaq.push(['_trackPageview']);
      (function() {
      var ga = document.createElement('script'); ga.type = 'text/javascript'; ga.async = true;
      ga.src = ('https:' == document.location.protocol ? 'https://ssl' : 'http://www') + '.google-analytics.com/ga.js';
      var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ga, s);
      })();
          tagManagerId = '${code || 'GTM-5Z59D4'}';
          dataLayer = [];
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      '//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${code || 'GTM-5Z59D4'}');`
  },
  gaNoScript: function (code) {
    return `<iframe src="//www.googletagmanager.com/ns.html?id=${code || 'GTM-5Z59D4'}"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>`
  },
  getEmarsys: function () {
    return `
    var CoreVars = {"base_cdn_url":"https://d9qzjwuieyamt.cloudfront.net/5.3.16.44","base_url":"https://www.selectspecs.com","base_site":"uk","ssl_base_url":"https://www.selectspecs.com","profile_id":"395615","skip_scarab":false,"t":{"NA":"NA"}};

                                                var message = false;
                                    var info = false;
                                    var success = false;
                                    var warning = false;
                                    var error = false;

            var getCountWishlistFun = function() {
                //$.post('https://www.selectspecs.com/de/wishlist/ajaxgetcountwishlist/', null, function(data) {
                //    $('#countWishlist').text(data.count_wishlist ? 'Wunschliste (' + data.count_wishlist + ')' : 'Wunschliste');
                //});
            };

            $(document).ready(function() {
                getCountWishlistFun();
                $('#lang').change(function() {
                    document.location.href = $(this).val();
                });
            });
    if (!CoreVars.skip_scarab) {
    var ScarabQueue = ScarabQueue || [];
    (function(id) {
        if (document.getElementById(id)) return;
        var js = document.createElement('script'); js.id = id;
        js.src = '//cdn.scarabresearch.com/js/12C7039EBD3D5384/scarab-v2.js';
        var fs = document.getElementsByTagName('script')[0];
        fs.parentNode.insertBefore(js, fs);
    })('scarab-js-api');
    };`
  },
  getFbPixel: function () {
    return `!function(f,b,e,v,n,t,s) {if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window,document,'script','https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '428176317752631');
      fbq('track', 'PageView');`
  },
  getRewardsProgram: function (user) {
    return `
  window._talkableq = window._talkableq || [];
  window._talkableq.push(["join_loyalty",{
    email: "${user.email}",
    first_name: "${user.first_name}",
    last_name: "${user.last_name}",
  }]);
`
  }
}
