import AccountBreadcrumbs from '@/components/scripts/AccountPage/AccountBreadcrumbs/index.vue'
import AccountHeader from '@/components/scripts/AccountPage/AccountHeader/index.vue'
import TrustpilotWidget from '@/components/scripts/TrustpilotWidget/index.vue'
import { mapState } from 'vuex'

export default {
  name: 'AccountPage',
  computed: {
    ...mapState({
      loading: (state) => state.loading,
      user: state => ({...state.user}),
      auth: (state) => state.authorization
    })
  },
  components: {
    AccountBreadcrumbs,
    AccountHeader,
    TrustpilotWidget
  },
  created () {
    if (this.auth.toLowerCase() === 'unauthorized') {
      // this.$router.push('/')
      window.location.assign('/auth/login')
    }
  }
}
