/* eslint-disable no-unused-vars */
/* no-useless-escape */
export default function (Vue) {
  const types = require('./types')
  const $ = require('jquery')
  const querystring = require('querystring')
  const auth = require('./authorization')
  const config = require('./../../config')
  const {translateField} = require('../utils/handlebars')
  const catalogueInit = require('./catalogue').default
  const cheapGlassesInit = require('./cheapGlasses').default
  const {createHTTPClient} = require('./../utils/http')
  const {reverseRouteName} = require('./../../config/helper')
  const qs = require('qs')

  Vue.http.options.xhr = {withCredentials: true}

  let isServer = false
  if (typeof global !== 'undefined') {
    isServer = global['process'] && global['process'].env && global['process'].env.VUE_ENV && global['process'].env.VUE_ENV === 'server'
  }

  const getIsServer = () => {
    return global && global['process'] && global['process'].env && global['process'].env.VUE_ENV === 'server'
  }

  const http = createHTTPClient()
  const httpSSv4 = createHTTPClient(false)

  var urlParamt = function (name) {
    // var url = new URL(window.location.href)
    // return url.searchParams.get(name)
    var results = new RegExp('[/?&]' + name + '=([^&#]*)').exec(window.location.href)
    if (results == null) {
      return null
    } else {
      return decodeURI(results[1]) || 0
    }
  }

  var hostAddr = urlParamt('host')
  if (hostAddr) {
    console.log(hostAddr)
    config.integrationHost = hostAddr
  }

  const getAuthConfig = (config, state) => {
    const {user: {hashedPassword = ''}} = state
    if (hashedPassword) {
      if (!config['headers']) {
        config['headers'] = {}
      }
      config['headers']['Authorization'] = 'Basic ' + btoa(hashedPassword + ':')
    }
    return config
  }

  return {
    onChangeForm: ({commit}, el) => {
      const formName = el.target.form.name || 'default'
      let value = !el.target.checked ? el.target.type === 'checkbox' ? false : el.target.value : parseInt(el.target.value)

      commit(types.CHANGE_FORM_DATA, {
        key: el.target.name,
        value,
        formName
      })
    },

    onSubmitPopupLogin: ({commit, state, dispatch}, router) => {
      const {formData: {popupLogin = {}}} = state
      const {email, password} = popupLogin

      commit(types.REQUEST_START)
      // var url = new URL(window.location.href)
      // var sspay = url.searchParams.get('sspay')
      var sspay = urlParamt('sspay')

      return http.post((sspay ? (config.integrationHost + config.integrationPrefix + config.users.loginSsv4 + '/') : config.users.login), {
        LoginForm: {
          username: email,
          password,
          rememberMe: true
        },
        csrf: state.csrf
      }, {
        withCredentials: true
      })
        .then(r => dispatch(auth.AUTH_ACTION_SUCCESS, r), e => dispatch(auth.AUTH_ACTION_ERROR, e))
        .then(result => {
          commit(types.FORM_SUCCESS, {msg: 'Logged successfully', formName: 'popupLogin'})
          if (!sspay) {
            commit(types.USER_SIGNED_POPUP, {data: result.data, router})
          } else {
            commit(types.SSV4_USER_SIGNED_POPUP, {data: result.data})
          }
          dispatch('getBasket')
          dispatch('loadItemsWishlist')
          commit(types.REQUEST_END)
        })
        .catch(res => {
          commit(types.FORM_ERROR, {msg: res.response.data.error, formName: 'popupLogin'})
          commit(types.REQUEST_END)
          return res.response
        })
    },

    onSubmitLogin: ({commit, state, dispatch}, router) => {
      const {formData: {login = {}}} = state
      const {email, password, account, home} = login

      if (!account) {
        commit(types.FORM_ERROR, {msg: null, formName: 'login'})
        router.push({name: 'Register', params: {lang: state.lang}})
        return
      }

      commit(types.REQUEST_START)
      // var url = new URL(window.location.href)
      // var sspay = url.searchParams.get('sspay')
      var sspay = urlParamt('sspay')
      return http.post((sspay ? (config.integrationHost + config.integrationPrefix + config.users.loginSsv4 + '/') : config.users.login), {
        LoginForm: {
          username: email,
          password,
          rememberMe: true
        },
        csrf: state.csrf
      }, {
        withCredentials: true
      })
        .then(r => dispatch(auth.AUTH_ACTION_SUCCESS, r), e => dispatch(auth.AUTH_ACTION_ERROR, e))
        .then(result => {
          commit(types.FORM_SUCCESS, {msg: 'Logged successfully', formName: 'login'})
          if (!sspay) {
            commit(types.USER_SIGNED, {data: result.data})
          } else {
            commit(types.SSV4_USER_SIGNED, {data: result.data})
          }
          dispatch('getBasket')
          dispatch('loadItemsWishlist')
          if (home) {
            router.push({name: 'Main_' + state.lang, params: {lang: state.lang}})
            return
          }
          if (!sspay) {
            commit(types.USER_SIGNED, {data: result.data, router})
          }
        })
        .catch(res => commit(types.FORM_ERROR, {msg: res.response.data.error, formName: 'login'}))
        .then(() => commit(types.REQUEST_END), () => commit(types.REQUEST_END))
    },

    onModalLogin: ({commit, state, dispatch}) => {
      const {formData: {modalLogin = {}}} = state
      const {email, password, account} = modalLogin

      if (!account) {
        commit(types.FORM_ERROR, {msg: 'Access not permitted', formName: 'modalLogin'})
        throw new Error()
      }
      commit(types.REQUEST_START)
      return http.post(config.users.login, {
        LoginForm: {
          username: email,
          password
        },
        csrf: state.csrf
      }, {
        withCredentials: true
      })
        .then(r => dispatch(auth.AUTH_ACTION_SUCCESS, r), e => dispatch(auth.AUTH_ACTION_ERROR, e))
        .then(result => {
          commit(types.USER_SIGNED_POPUP, {data: result.data})
          commit(types.REQUEST_END)
          dispatch('getBasket')
          dispatch('loadItemsWishlist')
        })
        .catch(err => {
          let error = err.response.data
          commit(types.FORM_ERROR, {msg: error.error, formName: 'modalLogin'})
          commit(types.REQUEST_END)
          throw new Error(error)
        })
    },

    onModalRegister: ({state, commit, dispatch}, {data}) => {
      commit(types.REQUEST_START)

      return http.post(config.users.get, data, {
        withCredentials: true
      })
        .then(r => dispatch(auth.AUTH_ACTION_SUCCESS, r), e => dispatch(auth.AUTH_ACTION_ERROR, e))
        .then(result => {
          commit(types.CHANGE_FORM_DATA_MULTI, {
            data: {
              email: result.data.email,
              account: 1,
              password: result.data.password
            },
            formName: 'modalLogin'
          })
          dispatch('formMessage', {message: 'Thank you for registering', type: 'success'})

          commit(types.REQUEST_END)
          return dispatch('onModalLogin')
        })
        .catch(err => {
          let error = err.response.data
          if (error.isArray) {
            error.forEach(error => {
              return commit(types.FORM_ERROR_FIELD, {
                msg: error.message,
                field: error.field,
                formName: 'modalRegister'
              })
            })
          } else {
            dispatch('formMessage', {message: 'Error while registering', type: 'error'})
            commit(types.FORM_ERROR, {msg: error.message, formName: 'modalRegister'})
            throw new Error(error)
          }
          commit(types.REQUEST_END)
        })
    },

    onPasswordReset: ({commit, dispatch}, email) => {
      commit(types.REQUEST_START)

      /* TODO: adapt to current api
      SiteVars::add_data(array(
        'link' => base_url() . 'auth/restore_password/' . $user['id'] . '/' . $code->getPlaintext(),
      ));

      $emailSend = $this->_sendEmail(
        'noreply@' . Domain::get_instance()->host,
        $user['email']  ,
        get_translation('newPasswordRequest'),
        get_translation('newPasswordRequestText')
      );

      if (!$emailSend) {
        throw new Exception('Could not send an email');
      } else {
        $this->session->set_flashdata('message', get_translation('SentEmailLink'));
        $this->session->set_flashdata('success', true);
        redirect('/');
      }
      */

      return http.post(config.users.resetPass, email, {
        withCredentials: true
      })
        .then(r => dispatch(auth.AUTH_ACTION_SUCCESS, r), e => dispatch(auth.AUTH_ACTION_ERROR, e))
        .then(result => {
          commit(types.FORM_SUCCESS, {msg: 'Password recovery link sent to email', formName: 'passReset'})
          commit(types.REQUEST_END)
          return result
        })
        .catch(err => {
          commit(types.FORM_ERROR, {msg: err.response.status, formName: 'passReset'})
          commit(types.REQUEST_END)
          return false
        })
    },

    checkModalLogin: ({commit, state, dispatch}, email) => {
      commit(types.REQUEST_START)
      return http.get(config.users.get, getAuthConfig({
        params: {
          email
        }
      }, state))
        .then(r => dispatch(auth.AUTH_ACTION_SUCCESS, r), e => dispatch(auth.AUTH_ACTION_ERROR, e))
        .then(res => {
          const {items: data} = res.data
          const {formData: {modalLogin: {account}}} = state
          commit(types.CHANGE_FORM_DATA, {key: 'account', value: (data.length ? 1 : 0), formName: 'modalLogin'})
          commit(types.CHANGE_FORM_DATA, {
            key: 'familiarEmail',
            value: !account && !!data.length,
            formName: 'modalLogin'
          })
          commit(types.FORM_FIELD_CLEAR, {formName: 'modalLogin'})
          commit(types.REQUEST_END)
        })
        .catch(err => {
          commit(types.CHANGE_FORM_DATA, {key: 'familiarEmail', value: false, formName: 'modalLogin'})
          commit(types.FORM_ERROR, {msg: err.response.data.message, formName: 'modalLogin'})
          commit(types.REQUEST_END)
          throw new Error(err)
        })
    },

    checkLogin: ({commit, state, dispatch}, email) => {
      commit(types.REQUEST_START)
      return http.get(config.users.get, getAuthConfig({
        params: {
          email
        }
      }, state))
        .then(r => dispatch(auth.AUTH_ACTION_SUCCESS, r), e => dispatch(auth.AUTH_ACTION_ERROR, e))
        .then(res => {
          const {items: data} = res.data
          const {formData: {login: {account}}} = state
          commit(types.CHANGE_FORM_DATA, {key: 'familiarEmail', value: !account && !!data.length, formName: 'login'})
          commit(types.CHANGE_FORM_DATA, {key: 'account', value: account || (data.length ? 1 : 0), formName: 'login'})
          commit(types.REQUEST_END)
          return data
        })
        .catch(res => {
          commit(types.CHANGE_FORM_DATA, {key: 'familiarEmail', value: false, formName: 'login'})
          commit(types.REQUEST_END)
          return res
        })
    },

    onSubmitProfile: ({commit, state, dispatch}, data) => {
      const {user} = state
      commit(types.REQUEST_START)

      http.put(config.users.get + '/' + user.user_number, data, {
        headers: {
          'Authorization': 'Basic ' + btoa(user.hashedPassword + ':')
        },
        withCredentials: true
      })
        .then(r => dispatch(auth.AUTH_ACTION_SUCCESS, r), e => dispatch(auth.AUTH_ACTION_ERROR, e))
        .then((result) => {
          commit(types.USER_SIGNED, {data: result.data})
          if (result.data.newPassword) {
            commit(types.FORM_SUCCESS, {
              msg: 'Profile saved. We send confirmation link to your registered email',
              formName: 'profile'
            })
          } else {
            commit(types.FORM_SUCCESS, {msg: 'Profile saved.', formName: 'profile'})
          }
          commit(types.REQUEST_END)
        }, (res) => {
          const error = [
            ...(state.formMeta.profile && state.formMeta.profile.formError || []),
            {
              field: 'profile',
              message: res.response.data
            },
            ...res.response.data
          ]
          commit(types.REQUEST_END)
          commit(types.FORM_ERROR, {msg: error, formName: 'profile'})
        })
    },

    onUpdateProfile: ({commit, state, dispatch}, data) => {
      const {user} = state
      commit(types.REQUEST_START)

      return http.put(config.users.get + '/' + user.user_number, data, {
        headers: {
          'Authorization': 'Basic ' + btoa(user.hashedPassword + ':')
        },
        withCredentials: true
      })
        .then(r => dispatch(auth.AUTH_ACTION_SUCCESS, r), e => dispatch(auth.AUTH_ACTION_ERROR, e))
        .then((res) => {
          commit(types.USER_SIGNED, {data: res.data})
          dispatch('formMessage', {message: 'Profile saved', type: 'success'})
          commit(types.REQUEST_END)
          return true
        }, (err) => {
          let errObj = err.response.data
          console.log(err.response.data)
          errObj.forEach(error => {
            commit(types.FORM_ERROR_FIELD, {
              msg: error.message,
              field: error.field,
              formName: 'profileUpdate'
            })
          })
          dispatch('formMessage', {
            message: 'Ensure you correct any highlighted errors before continuing',
            type: 'error'
          })
          commit(types.REQUEST_END)
          return false
        })
    },
    onUpdatePassword: ({commit, state, dispatch}, data) => {
      const {user} = state
      const updatedUser = {
        ...user,
        ...data
      }
      commit(types.REQUEST_START)

      return http.put(config.users.get + '/' + user.user_number, updatedUser, {
        headers: {
          'Authorization': 'Basic ' + btoa(user.hashedPassword + ':')
        },
        withCredentials: true
      })
        .then(r => dispatch(auth.AUTH_ACTION_SUCCESS, r), e => dispatch(auth.AUTH_ACTION_ERROR, e))
        .then((res) => {
          commit(types.USER_SIGNED, {data: res.data})
          dispatch('formMessage', {message: 'Password saved', type: 'success'})
          commit(types.FORM_FIELD_CLEAR, 'updatePassword')
          commit(types.REQUEST_END)
          return true
        }, (err) => {
          let errObj = err.response.data
          console.log(err.response.data)
          errObj.forEach(error => {
            commit(types.FORM_ERROR_FIELD, {
              msg: error.message,
              field: error.field,
              formName: 'updatePassword'
            })
          })
          dispatch('formMessage', {
            message: 'Ensure you correct any highlighted errors before continuing',
            type: 'error'
          })
          commit(types.REQUEST_END)
          return false
        })
    },

    onSubmitAddress: ({commit, state, dispatch}, {e, address = {}}) => {
      e.preventDefault()
      const {user: {hashedPassword, profile}} = state
      const {address_type: formName} = address
      let promise

      commit(types.REQUEST_START)

      if (address.id) {
        promise = http.put(config.profiles.addresses + '/' + address.id, address, {
          headers: {
            'Authorization': 'Basic ' + btoa(hashedPassword + ':')
          },
          withCredentials: true
        })
      } else {
        address.profile_id = profile.profile_number
        promise = http.post(config.profiles.addresses, address, {
          headers: {
            'Authorization': 'Basic ' + btoa(hashedPassword + ':')
          },
          withCredentials: true
        })
      }

      return promise
        .then(r => dispatch(auth.AUTH_ACTION_SUCCESS, r), e => dispatch(auth.AUTH_ACTION_ERROR, e))
        .then(
          (result) => {
            const {data: address} = result
            let addresses = [
              ...profile.addresses
            ]
            const index = addresses.findIndex((item) => item.address_type === address.address_type)
            if (index >= 0) {
              addresses[index] = address
            } else {
              addresses.push(address)
            }

            const user = {
              ...state.user,
              profile: {
                ...profile,
                addresses
              }
            }

            commit(types.USER_SIGNED, {data: user})
            commit(types.FORM_SUCCESS, {msg: 'address saved', formName})
            commit(types.REQUEST_END)

            let {...errors} = state.formMeta.profile && state.formMeta.profile.formError || []
            const filter = Array.prototype.filter.bind(errors)
            errors = filter(item => (item.field !== (address.address_type + 'Address')))
            commit(types.FORM_ERROR, {msg: errors, formName: 'profile'})
            return address
          },
          (result) => {
            const data = {}
            result.response.data.map(item => (data[item.field] = item.message))
            const error = [
              ...(state.formMeta.profile && state.formMeta.profile.formError || []),
              {
                field: address.address_type + 'Address',
                message: data
              }
            ]
            commit(types.REQUEST_END)
            commit(types.FORM_ERROR, {msg: error, formName: 'profile'})
          })
    },

    onUpdateAddress: ({commit, state, dispatch}, address) => {
      const {user: {hashedPassword, profile}} = state
      const formName = address.address_type + 'Edit'

      commit(types.REQUEST_START)

      return http.put(config.profiles.addresses + '/' + address.number, address, {
        headers: {
          'Authorization': 'Basic ' + btoa(hashedPassword + ':')
        },
        withCredentials: true
      })
        .then(
          (result) => {
            const {data: address} = result
            let addresses = [
              ...profile.addresses
            ]
            const index = addresses.findIndex((item) => item.address_type === address.address_type)
            if (index >= 0) {
              addresses[index] = address
            } else {
              addresses.push(address)
            }

            commit(types.FORM_SUCCESS, {msg: 'Address Saved', formName})
            commit(types.REQUEST_END)
          },
          (error) => {
            let errObj = error.response.data
            console.log(errObj)
            errObj.forEach(error => {
              return commit(types.FORM_ERROR_FIELD, {
                msg: error.message,
                field: error.field,
                formName
              })
            })
            commit(types.REQUEST_END)
          })
    },

    updateAddress: ({commit, state}, address) => {
      commit(types.INPUT_ADDRESS, address)
    },

    userSsv4LogIn: ({commit, state}, userData) => {
      commit(types.SSV4_USER_DATA, userData)
    },

    updateRegdata: ({commit, state}, address) => {
      commit(types.NEW_USER_DATA, address)
    },
    updateShipping: ({commit, state}, shipId) => {
      commit(types.SHIPPING_ID, shipId)
    },

    // BASKET
    removeItemFromBasket: ({state, commit, dispatch}, itemID) => {
      commit(types.REQUEST_START)
      const {user: {hashedPassword = ''}} = state
      const requestConfig = {
        withCredentials: true
      }
      if (hashedPassword) {
        requestConfig['headers'] = {
          Authorization: 'Basic ' + btoa(hashedPassword + ':')
        }
      }

      return dispatch('checkSession').then(() =>
        http.post(config.basket.itemDelete, {'ordered_item_number': itemID}, requestConfig)
          .then(r => dispatch(auth.AUTH_ACTION_SUCCESS, r), e => dispatch(auth.AUTH_ACTION_ERROR, e))
          .then(result => {
            commit(types.BASKET_LOADED, result.data)
          })
          .then(() => commit(types.REQUEST_END), () => commit(types.REQUEST_END)))
    },
    removeItemFromCartSSv4: ({commit, dispatch}, itemID) => {
      commit(types.REQUEST_START)

      return httpSSv4.post(config.integrationHost + config.integrationPrefix + config.cartSSv4.itemDelete, querystring.stringify({'ordered_item_number': itemID}), {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
        .then(r => dispatch(auth.AUTH_ACTION_SUCCESS, r), e => dispatch(auth.AUTH_ACTION_ERROR, e))
        .then(result => {
          commit(types.SSV4_ORDER_LOADED, result.data)
        })
        .then(() => commit(types.REQUEST_END), () => commit(types.REQUEST_END))
    },
    clearBasket: ({state, commit, dispatch}) => {
      commit(types.REQUEST_START)

      return dispatch('checkSession').then(() =>
        http.post(config.basket.clear, {}, getAuthConfig({
          withCredentials: true
        }, state))
          .then(r => dispatch(auth.AUTH_ACTION_SUCCESS, r), e => dispatch(auth.AUTH_ACTION_ERROR, e))
          .then(() => {
            commit(types.CLEAR_BASKET)
          })
          .then(() => commit(types.REQUEST_END), () => commit(types.REQUEST_END)))
    },
    addToSave: ({commit, state, dispatch}, itemID) => {
      commit(types.REQUEST_START)

      http.post(state.apiHost + config.prefix + config.basket.addToSave, {'ordered_item_number': itemID}, getAuthConfig({
        withCredentials: true
      }, state))
        .then(r => dispatch(auth.AUTH_ACTION_SUCCESS, r), e => dispatch(auth.AUTH_ACTION_ERROR, e))
        .then(result => {
          commit(types.SET_SAVE_FOR_LATER, result.data)
        })
        .then(() => commit(types.REQUEST_END), () => commit(types.REQUEST_END))
    },
    loadSavedItemsCartSSv4: ({commit, state, dispatch}) => {
      commit(types.REQUEST_START)
      let options = {
        withCredentials: true
      }

      return httpSSv4.get(config.integrationHost + config.integrationPrefix + config.cartSSv4.getSavedItems, options)
        .then(r => dispatch(auth.AUTH_ACTION_SUCCESS, r), e => dispatch(auth.AUTH_ACTION_ERROR, e))
        .then(result => {
          const saved = []
          Object.values(result.data.saved_items || []).map(item => {
            saved.push({
              ...item,
              item: {
                ...result.data.items[item.item_id],
                ...item.Item
              }
            })
          })
          commit(types.SSV4_SAVED_ITEMS, saved)
        })
        .then(() => commit(types.REQUEST_END), () => commit(types.REQUEST_END))
    },
    loadCurrencyCartSSv4: ({commit, state, dispatch}) => {
      const {currency} = state
      let options = {
        withCredentials: true
      }

      commit(types.REQUEST_START)
      commit(types.REQUEST_CURRENCY_START)
      return httpSSv4.get(config.integrationHost + config.integrationPrefix + config.cartSSv4.getCurrencies, options)
        .then(r => dispatch(auth.AUTH_ACTION_SUCCESS, r), e => dispatch(auth.AUTH_ACTION_ERROR, e))
        .then(result => commit(types.LOAD_CURRENCIES, {
          currencies: result.data,
          selected: currency.selected
        }), (err) => console.log('err::', err))
        .then(() => {
          commit(types.REQUEST_END)
          commit(types.REQUEST_CURRENCY_FINISHED)
        }, () => {
          commit(types.REQUEST_END)
          commit(types.REQUEST_CURRENCY_FINISHED)
        })
    },
    setCurrencyCartSSv4: ({commit, state, dispatch}, {id, orderID}) => {
      commit(types.REQUEST_START)

      return httpSSv4.post(config.integrationHost + config.integrationPrefix + config.cartSSv4.setCurrency, querystring.stringify({
        'order_id': orderID,
        id
      }), {
        withCredentials: true
      })
        .then(r => dispatch(auth.AUTH_ACTION_SUCCESS, r), e => dispatch(auth.AUTH_ACTION_ERROR, e))
        .then(result => {
          commit(types.SSV4_ORDER_LOADED, result.data)
        })
        .then(() => commit(types.REQUEST_END), () => commit(types.REQUEST_END))
    },
    addToSaveCartSSv4: ({commit, state, dispatch}, itemID) => {
      commit(types.REQUEST_START)

      return httpSSv4.post(config.integrationHost + config.integrationPrefix + config.cartSSv4.addToSave, querystring.stringify({'ordered_item_number': itemID}), {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
        .then(r => dispatch(auth.AUTH_ACTION_SUCCESS, r), e => dispatch(auth.AUTH_ACTION_ERROR, e))
        .then(result => {
          commit(types.SSV4_ORDER_LOADED, result.data)
          return dispatch('loadSavedItemsCartSSv4')
        })
        .then(() => commit(types.REQUEST_END), () => commit(types.REQUEST_END))
    },
    checkLoginCartSSv4: ({commit, state, dispatch}) => {
      commit(types.REQUEST_START)
      let options = {
        withCredentials: true
      }

      return httpSSv4.get(config.integrationHost + config.integrationPrefix + config.cartSSv4.checkUser, options)
        .then(r => dispatch(auth.AUTH_ACTION_SUCCESS, r), e => dispatch(auth.AUTH_ACTION_ERROR, e))
        .then(result => {
          commit(types.SSV4_USER, result.data)
        })
        .then(() => commit(types.REQUEST_END), () => commit(types.REQUEST_END))
    },
    delFromSave: ({commit, state, dispatch}, itemID) => {
      commit(types.REQUEST_START)

      http.post(state.apiHost + config.prefix + config.basket.delFromSave, {'ordered_item_number': itemID}, getAuthConfig({
        withCredentials: true
      }, state))
        .then(r => dispatch(auth.AUTH_ACTION_SUCCESS, r), e => dispatch(auth.AUTH_ACTION_ERROR, e))
        .then(data => {
          commit(types.DEL_SAVE_FOR_LATER, data.body)
        })
        .then(() => commit(types.REQUEST_END), () => commit(types.REQUEST_END))
    },
    moveFromSaveCartSSv4: ({commit, state, dispatch}, itemID) => {
      commit(types.REQUEST_START)

      return httpSSv4.post(config.integrationHost + config.integrationPrefix + config.cartSSv4.moveFromSave, querystring.stringify({'saved_number': itemID}), {
        withCredentials: true
      })
        .then(r => dispatch(auth.AUTH_ACTION_SUCCESS, r), e => dispatch(auth.AUTH_ACTION_ERROR, e))
        .then(response => {
          commit(types.SSV4_ORDER_LOADED, response.data)
          return dispatch('loadSavedItemsCartSSv4')
        })
        .then(() => commit(types.REQUEST_END), () => commit(types.REQUEST_END))
    },
    removeFromSaveCartSSv4: ({commit, state, dispatch}, itemID) => {
      commit(types.REQUEST_START)

      return httpSSv4.post(config.integrationHost + config.integrationPrefix + config.cartSSv4.removeFromSave, querystring.stringify({'saved_number': itemID}), {
        withCredentials: true
      })
        .then(r => dispatch(auth.AUTH_ACTION_SUCCESS, r), e => dispatch(auth.AUTH_ACTION_ERROR, e))
        .then(() => {
          return dispatch('loadSavedItemsCartSSv4')
        })
        .then(() => commit(types.REQUEST_END), () => commit(types.REQUEST_END))
    },
    switchWishlist: ({commit, state}, itemData) => {
      commit(types.REQUEST_START)
      let options = {
        withCredentials: true
      }
      return http.post(config.wishlist.switchWishlist, {params: itemData}, getAuthConfig(options, state))
        .then(data => {
          commit(types.SWITCH_WISHLIST, data.data)
        })
        .then(() => commit(types.REQUEST_END), () => commit(types.REQUEST_END))
    },
    loadItemsWishlist: ({commit, state}, silence = false) => {
      commit(types.REQUEST_START)
      let options = {
        withCredentials: true
      }

      return http.get(config.wishlist.itemReadylist, getAuthConfig(options, state))
        .then(result => {
          commit(types.WISHLIST_LOADED, result.data)
          commit(types.REQUEST_END)
        })
        .catch(() => {
          commit(types.REQUEST_END)
        })
    },
    cleanWishlist: ({commit, state}, silence = false) => {
      commit(types.REQUEST_START)
      let options = {
        withCredentials: true
      }
      return http.delete(config.wishlist.cleanWishlist, getAuthConfig(options, state))
        .then(data => {
          commit(types.CLEAN_WISHLIST)
        })
        .then(() => commit(types.REQUEST_END), () => commit(types.REQUEST_END))
    },
    setActiveWishlist: ({commit, state}, itemData) => {
      commit(types.ACTIVE_WL, itemData)
    },
    setQtyItem: ({state, commit, dispatch}, item) => {
      commit(types.REQUEST_START)
      const {user: {hashedPassword = ''}} = state
      const requestConfig = {
        withCredentials: true
      }
      if (hashedPassword) {
        requestConfig['headers'] = {
          Authorization: 'Basic ' + btoa(hashedPassword + ':')
        }
      }

      return dispatch('checkSession').then(() =>
        http.post(config.basket.changeQuantity, item, requestConfig)
          .then(r => dispatch(auth.AUTH_ACTION_SUCCESS, r), e => dispatch(auth.AUTH_ACTION_ERROR, e))
          .then(result => {
            commit(types.BASKET_LOADED, result.data)
            commit(types.REQUEST_END)
          })
          .catch(() => commit(types.REQUEST_END)))
    },
    setQtyItemSSv4: ({commit, dispatch}, item) => {
      commit(types.REQUEST_START)

      return httpSSv4.post(config.integrationHost + config.integrationPrefix + config.cartSSv4.changeQuantity, querystring.stringify(item), {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
        .then(r => dispatch(auth.AUTH_ACTION_SUCCESS, r), e => dispatch(auth.AUTH_ACTION_ERROR, e))
        .then(result => {
          commit(types.SSV4_ORDER_LOADED, result.data)
          commit(types.REQUEST_END)
        })
        .catch(() => commit(types.REQUEST_END))
    },
    putAdditionalPrice: ({commit, state}, addPrice) => {
      commit(types.ADD_ADDITIONAL_PRICE, addPrice)
    },
    // end BASKET

    // CURRENCIES
    loadAllCurrencies: ({commit, state, dispatch}) => {
      const {integrationHost, apiHost, currency} = state

      commit(types.REQUEST_START)
      commit(types.REQUEST_CURRENCY_START)
      // var url = new URL(window.location.href)
      // var sspay = url.searchParams.get('sspay')
      var sspay = urlParamt('sspay')
      return http.get((sspay === 'true' ? integrationHost : apiHost) + (sspay === 'true' ? config.integrationPrefix : config.prefix) + config.currencies.allCurrencies + (sspay === 'true' ? '/' : ''), getAuthConfig({
        withCredentials: true
      }, state))
        .then(r => dispatch(auth.AUTH_ACTION_SUCCESS, r), e => dispatch(auth.AUTH_ACTION_ERROR, e))
        .then(result => commit(types.LOAD_CURRENCIES, {
          currencies: result.data,
          selected: currency.selected
        }), (err) => console.log('err::', err))
        .then(() => {
          commit(types.REQUEST_END)
          commit(types.REQUEST_CURRENCY_FINISHED)
        }, () => {
          commit(types.REQUEST_END)
          commit(types.REQUEST_CURRENCY_FINISHED)
        })
    },
    loadGeoIp: ({state, commit}, ip) => {
      commit(types.GEO_IP_REQUEST, true)
      commit(types.REQUEST_START)

      let options = {
        withCredentials: true
      }
      const {user: {hashedPassword = ''}} = state
      if (hashedPassword) {
        options['headers'] = {
          Authorization: 'Basic ' + btoa(hashedPassword + ':')
        }
      }

      if (ip) {
        options.params = {address: ip}
      }
      return http.get(config.countries.ip, options)
        .then(result => {
          commit(types.SET_GEO_IP_INFO, result.data)
          commit(types.REQUEST_END)
          commit(types.GEO_IP_REQUEST, false)
        })
        .catch(() => {
          commit(types.REQUEST_END)
          commit(types.GEO_IP_REQUEST, false)
        })
    },
    loadCountries: ({commit, state}) => {
      commit(types.COUNTRY_LOAD_START)
      commit(types.REQUEST_START)
      const {user: {hashedPassword = ''}} = state
      const requestConfig = {
        withCredentials: true
      }
      if (hashedPassword) {
        requestConfig['headers'] = {
          Authorization: 'Basic ' + btoa(hashedPassword + ':')
        }
      }

      return http.get(config.countries.get, requestConfig)
        .then(result => {
          commit(types.SET_COUNTRIES, result.data.items)
          commit(types.REQUEST_END)
          commit(types.COUNTRY_LOAD_END)
        })
        .catch(() => {
          commit(types.REQUEST_END)
          commit(types.COUNTRY_LOAD_END)
        })
    },
    setCurrency: ({state, commit, dispatch}, id) => {
      commit(types.REQUEST_START)
      commit(types.REQUEST_CURRENCY_START)
      const {user: {hashedPassword = ''}} = state
      const requestConfig = {
        withCredentials: true
      }
      if (hashedPassword) {
        requestConfig['headers'] = {
          Authorization: 'Basic ' + btoa(hashedPassword + ':')
        }
      }

      return dispatch('checkSession').then(() =>
        http.post(config.basket.setCurrency, {id}, requestConfig)
          .then(r => dispatch(auth.AUTH_ACTION_SUCCESS, r), e => dispatch(auth.AUTH_ACTION_ERROR, e))
          .then(res => {
            commit(types.BASKET_LOADED, res.data)
            commit(types.CHANGE_CURRENCY, id)
            commit(types.REQUEST_END)
            commit(types.REQUEST_CURRENCY_FINISHED)
          })
          .catch(() => {
            commit(types.REQUEST_END)
            commit(types.REQUEST_CURRENCY_FINISHED)
          }))
    },
    // end CURRRENCIES

    checkUser: ({commit, dispatch}) => {
      function checkUserComm ({commit, dispatch}) {
        commit(types.REQUEST_START)
        // var url = new URL(window.location.href)
        // var sspay = url.searchParams.get('sspay')
        var sspay = urlParamt('sspay')
        return http.get((sspay ? (config.integrationHost + config.integrationPrefix + config.users.loginSsv4 + '/') : config.users.login), {
          'params': {
            'checkStatus': true
          },
          withCredentials: true
        })
          .then(r => dispatch(auth.AUTH_ACTION_SUCCESS, r), e => dispatch(auth.AUTH_ACTION_ERROR, e))
          .then(result => {
            if (!sspay) {
              commit(types.USER_SIGNED, {data: result.data})
            } else {
              commit(types.SSV4_USER_SIGNED, {data: result.data})
            }
            commit(types.REQUEST_END)
          })
          .catch((res) => {
            commit(types.REQUEST_END)
          })
      }

      // eslint-disable-next-line no-useless-escape
      let matches = document.cookie.match(new RegExp('(?:^|; )' + 'token'.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'))
      if (matches) {
        return http.get(config.users.checkToken, {params: {token: matches[1]}})
          .then(result => {
            return checkUserComm({commit, dispatch})
          })
          .catch(() => {
            return checkUserComm({commit, dispatch})
          })
      } else {
        return checkUserComm({commit, dispatch})
      }
    },
    checkSession: ({dispatch, commit, state}) => {
      // var url = new URL(window.location.href)
      // var sspay = url.searchParams.get('sspay')
      var sspay = urlParamt('sspay')

      function getUserPromise () {
        return http.get((sspay ? (config.integrationHost + config.integrationPrefix + config.users.loginSsv4 + '/') : config.users.login), {
          'params': {
            'checkStatus': true
          },
          withCredentials: true
        })
          .then(r => dispatch(auth.AUTH_ACTION_SUCCESS, r), e => dispatch(auth.AUTH_ACTION_ERROR, e))
      }

      function getBasketPromise () {
        const {currency} = state
        let currencyId = localStorage.getItem('currency') ? localStorage.getItem('currency') : currency.selected
        let options = {
          withCredentials: true
        }
        if (currencyId) {
          options.params = {
            currency_id: currencyId
          }
        }
        const {user: {hashedPassword = ''}} = state
        if (hashedPassword) {
          options['headers'] = {
            Authorization: 'Basic ' + btoa(hashedPassword + ':')
          }
        }

        return http.get(config.orders.myBasket, options)
          .then(r => dispatch(auth.AUTH_ACTION_SUCCESS, r), e => dispatch(auth.AUTH_ACTION_ERROR, e))
          .then(result => {
            const {basket = {}} = state
            if (basket.number !== result.data.number) {
              commit(types.BASKET_LOADED, result.data)
              commit(types.CHANGE_CURRENCY, result.data.order.currency_id)
            }
          })
      }

      return getUserPromise()
        .catch(() => {
          const {user = {}} = state
          if (user.user_number) {
            return dispatch('onLogout')
          }
        })
        .then(() => getBasketPromise(), () => getBasketPromise())
    },

    getBasket: ({dispatch, state, commit}) => {
      commit(types.REQUEST_BASKET_START)
      commit(types.REQUEST_START)
      const {currency} = state
      let currencyId = localStorage && localStorage.getItem('currency') ? localStorage.getItem('currency') : currency.selected
      let options = {
        withCredentials: true
      }
      // eslint-disable-next-line no-useless-escape
      let matches = document.cookie.match(new RegExp('(?:^|; )' + 'order_hash'.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'))
      if (currencyId) {
        options.params = {
          currency_id: currencyId,
          st: matches ? matches[1] : null
        }
      }
      let cookOptions = {
        withCredentials: true,
        params: {
          st: matches ? matches[1] : null
        }
      }
      const {user: {hashedPassword = ''}} = state
      if (hashedPassword) {
        options['headers'] = {
          Authorization: 'Basic ' + btoa(hashedPassword + ':')
        }
        cookOptions['headers'] = {
          Authorization: 'Basic ' + btoa(hashedPassword + ':')
        }
      }

      function chkBasket () {
        return http.get(config.orders.myBasket, options)
          .then(r => dispatch(auth.AUTH_ACTION_SUCCESS, r), e => dispatch(auth.AUTH_ACTION_ERROR, e))
          .then(result => {
            commit(types.BASKET_LOADED, result.data)
            commit(types.CHANGE_CURRENCY, result.data.order.currency_id)
            commit(types.REQUEST_BASKET_FINISHED)
            commit(types.REQUEST_END)
          })
          .catch(() => {
            commit(types.REQUEST_BASKET_FINISHED)
            commit(types.REQUEST_END)
          })
      }

      http.get(config.basket.setBasket, cookOptions)
        .then(rsp => {
          return chkBasket()
        })
        .catch(() => {
          return chkBasket()
        })
    },

    getSSv4Basket: ({dispatch, state, commit}, {order_id}) => {
      commit(types.REQUEST_BASKET_START)
      commit(types.REQUEST_START)
      let options = {
        withCredentials: true
      }

      const apiUrl = order_id ? config.cartSSv4.fetchByID.replace(/<:id>/, order_id) : config.cartSSv4.fetch
      return httpSSv4.get(config.integrationHost + config.integrationPrefix + apiUrl, options)
        .then(r => dispatch(auth.AUTH_ACTION_SUCCESS, r), e => dispatch(auth.AUTH_ACTION_ERROR, e))
        .then(result => {
          commit(types.SSV4_ORDER_LOADED, result.data)
          commit(types.CHANGE_CURRENCY, result.data.currency_id)
          commit(types.REQUEST_BASKET_FINISHED)
          commit(types.REQUEST_END)
        })
        .catch(() => {
          commit(types.REQUEST_BASKET_FINISHED)
          commit(types.REQUEST_END)
        })
    },

    addItemToBasket: ({state, commit, dispatch}, item) => {
      commit(types.REQUEST_START)
      const {user: {hashedPassword = ''}} = state
      const requestConfig = {
        withCredentials: true
      }
      if (hashedPassword) {
        requestConfig['headers'] = {
          Authorization: 'Basic ' + btoa(hashedPassword + ':')
        }
      }

      return dispatch('checkSession').then(() =>
        http.post(config.basket.add, item, requestConfig)
          .then(r => dispatch(auth.AUTH_ACTION_SUCCESS, r), e => dispatch(auth.AUTH_ACTION_ERROR, e))
          .then(result => {
            commit(types.BASKET_LOADED, result.data)
            commit(types.REQUEST_END)
          })
          .catch(() => commit(types.REQUEST_END)))
    },

    createNewUser: ({state, commit, dispatch}, {data, router}) => {
      commit(types.REQUEST_START)

      return http.post(config.users.get, data, getAuthConfig({
        withCredentials: true
      }, state))
        .then(r => dispatch(auth.AUTH_ACTION_SUCCESS, r), e => dispatch(auth.AUTH_ACTION_ERROR, e))
        .then(result => {
          commit(types.CHANGE_FORM_DATA_MULTI, {
            data: {
              email: result.data.email,
              account: 1,
              password: result.data.password,
              home: true
            },
            formName: 'login'
          })

          dispatch('formMessage', {message: 'Thank you for registering', type: 'success'})

          commit(types.REQUEST_END)
          return dispatch('onSubmitLogin', router)
        })
        .catch(err => {
          commit(types.FORM_ERROR, {msg: err.response.data, formName: 'login'})
          commit(types.REQUEST_END)
        })
    },

    onSubmitPromoCode: ({commit, dispatch, state}, promoCode) => {
      const formName = 'basket'
      commit(types.REQUEST_START)
      const {user: {hashedPassword = ''}} = state
      const requestConfig = {
        withCredentials: true
      }
      if (hashedPassword) {
        requestConfig['headers'] = {
          Authorization: 'Basic ' + btoa(hashedPassword + ':')
        }
      }

      return dispatch('checkSession').then(() =>
        http.post(config.orders.promoCode, {
          promoCode
        }, requestConfig)
          .then(r => dispatch(auth.AUTH_ACTION_SUCCESS, r), e => dispatch(auth.AUTH_ACTION_ERROR, e))
          .then(result => {
            commit(types.BASKET_LOADED, result.data)
            commit(types.FORM_SUCCESS, {msg: 'Promo Code Applied', formName})
          }, () => {
            commit(types.FORM_ERROR, {msg: 'Promo code not suitable', formName})
            return dispatch('getBasket')
          })
          .then(() => commit(types.REQUEST_END), () => commit(types.REQUEST_END)))
    },

    onSubmitPromoCodeCartSSv4: ({commit, dispatch, state}, promoCode) => {
      const formName = 'basket'
      commit(types.REQUEST_START)

      return httpSSv4.post(config.integrationHost + config.integrationPrefix + config.cartSSv4.applyPromoCode, querystring.stringify({
        promoCode
      }), {
        withCredentials: true
      })
        .then(r => dispatch(auth.AUTH_ACTION_SUCCESS, r), e => dispatch(auth.AUTH_ACTION_ERROR, e))
        .then(result => {
          commit(types.SSV4_ORDER_LOADED, result.data)
          commit(types.FORM_SUCCESS, {msg: 'Promo Code Applied', formName})
        }, () => commit(types.FORM_ERROR, {msg: 'Promo code not suitable', formName}))
        .then(() => commit(types.REQUEST_END), () => commit(types.REQUEST_END))
    },

    onLogout: ({commit, state, dispatch}, $router) => {
      commit(types.REQUEST_START)
      // eslint-disable-next-line no-useless-escape
      return http.post(config.users.logout, {
        '_csrf': state.csrf
      }, {
        withCredentials: true
      })
        .then(r => dispatch(auth.AUTH_ACTION_SUCCESS, r), e => dispatch(auth.AUTH_ACTION_ERROR, e))
        .then(() => {
          document.cookie = 'token=""; expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/' + config.subDomain + ';'
          document.cookie = 'order_hash=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/' + config.subDomain + ';'
          commit(types.USER_SIGNOUT, $router)
          commit(types.REQUEST_END)
          return dispatch('getBasket')
        })
        .catch(() => {
          document.cookie = 'token=""; expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/' + config.subDomain + ';'
          document.cookie = 'order_hash=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/' + config.subDomain + ';'
          commit(types.USER_SIGNOUT, $router)
          commit(types.REQUEST_END)
          return dispatch('getBasket')
        })
    },

    loadMenus: ({commit, state, dispatch}) => {
      const {user: {hashedPassword = ''}} = state
      const requestConfig = {
        params: {
          lang: state.lang
        },
        headers: {
          'X-Requested-With': 'XMLHttpRequest'
        },
        emulateJSON: true,
        withCredentials: true
      }
      if (hashedPassword) {
        requestConfig['headers']['Authorization'] = 'Basic ' + btoa(hashedPassword + ':')
      }

      return http.get(config.menu.getMenus, requestConfig)
        .then(r => dispatch(auth.AUTH_ACTION_SUCCESS, r), e => dispatch(auth.AUTH_ACTION_ERROR, e))
        .then(response => response.data)
        .then((data) => {
          commit(types.LOAD_MENUS, data.items)
        })
    },
    loadSSv4Menu: ({commit, state, dispatch}) => {
      let options = {
        withCredentials: true,
        params: {lang: state.lang}
      }

      return httpSSv4.get(config.integrationHost + config.integrationPrefix + config.menu.getMenuSSv4, options)
        .then(r => dispatch(auth.AUTH_ACTION_SUCCESS, r), e => dispatch(auth.AUTH_ACTION_ERROR, e))
        .then(result => {
          commit(types.LOAD_MENUS, result.data.items)
        })
    },
    loadSSv4MobileMenu: ({commit, state, dispatch}) => {
      let options = {
        withCredentials: true,
        params: {desktop: 0, lang: state.lang}
      }

      return httpSSv4.get(config.integrationHost + config.integrationPrefix + config.menu.getMenuSSv4, options)
        .then(r => dispatch(auth.AUTH_ACTION_SUCCESS, r), e => dispatch(auth.AUTH_ACTION_ERROR, e))
        .then(result => {
          commit(types.LOAD_MOBILE_MENU, result.data.items)
        })
    },
    loadMobileMenu: ({commit, state, dispatch}) => {
      const {user: {hashedPassword = ''}} = state
      const requestConfig = {
        params: {
          desktop: 0,
          lang: state.lang
        },
        headers: {
          'X-Requested-With': 'XMLHttpRequest'
        },
        emulateJSON: true,
        withCredentials: true
      }
      if (hashedPassword) {
        requestConfig['headers']['Authorization'] = 'Basic ' + btoa(hashedPassword + ':')
      }

      return http.get(config.menu.getMenus, requestConfig)
        .then(r => dispatch(auth.AUTH_ACTION_SUCCESS, r), e => dispatch(auth.AUTH_ACTION_ERROR, e))
        .then(response => response.data)
        .then((data) => {
          commit(types.LOAD_MOBILE_MENU, data.items)
        })
    },
    setRedirects: ({commit}, redirects) => {
      commit(types.SET_REDIRECTS, redirects)
    },
    getMenuDescription: ({commit, dispatch, state}, [menuData, router]) => {
      return http.post(config.routes.search, menuData, getAuthConfig({
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        emulateJSON: true,
        withCredentials: true
      }, state))
        .then(r => dispatch(auth.AUTH_ACTION_SUCCESS, r), e => dispatch(auth.AUTH_ACTION_ERROR, e))
        .then(response => response.data)
        .then(json => {
          let homeTitle = 'HOME'
          if (Vue.config.i18n) {
            homeTitle = Vue.config.i18n.t('Home')
          }

          let breadcrumbs = [{title: homeTitle, path: '/'}]
          let trans = translateField.bind({lang: state.lang})

          if (json.page && json.page.translations && trans(json.page.translations, 'breadcrumb') && trans(json.page.translations, 'breadcrumb').length > 0) {
            breadcrumbs.push({
              title: trans(json.page.translations, 'breadcrumb'),
              path: '#'
            })
          } else {
            if (json.category && json.category.category_name) {
              let categoryName = json.category.category_name
              if (json.category.translations && trans(json.category.translations, 'category_name')) {
                categoryName = trans(json.category.translations, 'category_name')
              }
              breadcrumbs.push({
                title: categoryName,
                path: '#'
              })
            }
          }
          commit(types.SET_BREADCRUMBS, breadcrumbs)
          commit(types.SET_PAGE_DESCRIPTION, json)
        })
    },

    addBreadcrumb: ({commit}, breadcrumb) => {
      commit(types.ADD_BREADCRUMB, breadcrumb)
    },

    setBreadcrumbs: ({commit}, breadcrumb) => {
      commit(types.SET_BREADCRUMBS, breadcrumb)
    },

    changeFormData: ({commit}, {data, formName}) => {
      commit(types.CHANGE_FORM_DATA_MULTI, {data, formName})
    },

    changeFormError: ({commit}, {data, formName}) => {
      commit(types.CHANGE_FORM_ERROR, {data, formName})
    },

    fetchOrder: ({commit}, {user, id}) => {
      return http.get(config.orders.get + '/' + id, {
        headers: {
          'Authorization': 'Basic ' + btoa(user.hashedPassword + ':')
        }
      })
        .then((data) => data.data)
        .catch((err) => (console.error('err::', err)))
    },

    fetchOrders: ({commit}, {user, time}) => {
      commit(types.REQUEST_START)
      commit(types.ORDER_LOADING, true)
      return http.get(config.orders.get, {
        params: {
          user_number: user.user_number,
          created_at: {
            comparator: '>',
            value: time
          }
        },
        headers: {
          'Authorization': 'Basic ' + btoa(user.hashedPassword + ':')
        },
        withCredentials: true
      })
        .then(result => {
          commit(types.FETCH_ORDERS, result.data.items)
          commit(types.REQUEST_END)
          commit(types.ORDER_LOADING, false)
        })
        .catch(() => {
          commit(types.REQUEST_END)
          commit(types.ORDER_LOADING, false)
        })
    },

    deleteOrder: ({commit}, {id, user}) => {
      commit(types.REQUEST_START)
      commit(types.ORDER_LOADING, true)
      return http.delete(config.orders.get + '/' + id, {
        headers: {
          'Authorization': 'Basic ' + btoa(user.hashedPassword + ':')
        },
        withCredentials: true
      })
        .then(result => {
          commit(types.REQUEST_END)
          commit(types.ORDER_LOADING, false)
          return result.body
        })
        .catch(() => {
          commit(types.REQUEST_END)
          commit(types.ORDER_LOADING, false)
        })
    },

    setRouterBack: ({commit}, {path, params}) => {
      commit(types.SET_ROUTER_BACK, {path, params})
    },

    loadDesigners: ({commit, state}) => {
      const requestConfig = {
        params: {
          lang: state.lang
        },
        headers: {
          'X-Requested-With': 'XMLHttpRequest'
        },
        emulateJSON: true,
        withCredentials: true
      }
      return http.get(config.designers.allDesigners, getAuthConfig(requestConfig, state)).then(response => {
        commit(types.LOAD_DESIGNERS, response.data)
      })
    },

    loadCategories: ({commit, state}) => {
      const requestConfig = {
        params: {
          lang: state.lang
        },
        headers: {
          'X-Requested-With': 'XMLHttpRequest'
        },
        emulateJSON: true,
        withCredentials: true
      }
      return http.get(config.categories.allCategories, getAuthConfig(requestConfig, state)).then(response => {
        commit(types.LOAD_CATEGORIES, response.data)
      })
    },

    // Sidebar
    openSidebar: () => {
      $('#pageWrapper').addClass('sidebar-open')
      $('body').addClass('remove-scrollers')
    },
    closeSidebar: () => {
      $('#pageWrapper').removeClass('sidebar-open')
      $('body').removeClass('remove-scrollers')
      $('#sidebarNav > .fixed-top').animate({
        scrollTop: 0
      }, 10)
    },

    [auth.AUTH_ACTION_SUCCESS]: ({commit}, xhrResponse) => {
      if (!xhrResponse) return xhrResponse
      if (xhrResponse.headers) {
        const raw = xhrResponse.headers.map || xhrResponse.headers
        let headers = {}
        Object.keys(raw).map(key => (headers[key.toLowerCase()] = (raw[key] instanceof Array && raw[key][0]) || raw[key]))
        commit(types.SET_AUTHORIZATION, headers[auth.AUTH_HEADER])
      }
      return xhrResponse
    },
    [auth.AUTH_ACTION_ERROR]: ({commit}, xhrErr) => {
      if (!xhrErr || (!xhrErr.headers && !xhrErr.response)) throw xhrErr
      const raw = (xhrErr.headers && xhrErr.headers.map) || xhrErr.response.headers
      let headers = {}
      Object.keys(raw).map(key => (headers[key.toLowerCase()] = (raw[key] instanceof Array && raw[key][0]) || raw[key]))
      commit(types.SET_AUTHORIZATION, headers[auth.AUTH_HEADER])
      throw xhrErr
    },

    setPromiseData: ({commit}, data) => {
      commit(types.LOAD_PROMISE_DATA, data)
    },

    clearPromiseData: ({commit}) => {
      commit(types.CLEAR_PROMISE_DATA)
    },

    clearPromiseDataKey: ({commit}, key) => {
      commit(types.CLEAR_PROMISE_DATA_KEY, key)
    },

    loadBrandsList: ({commit, state}) => {
      let body = {lang: state.lang}
      let categories = []
      if (state.pageMenuDescription.catalogue && Object.keys(state.pageMenuDescription.catalogue.filters).length > 0) {
        Object.values(state.pageMenuDescription.catalogue.filters).filter(obj => obj.categories).map(one => {
          categories = categories.concat(one.categories)
        })
        body.categories = categories
      }
      return http.post(config.designers.allDesignersByLetter, body, getAuthConfig({
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        requestId: 'brandListRequest',
        emulateJSON: true,
        withCredentials: true
      }, state)).then(data => {
        commit(types.LOAD_PROMISE_DATA, {
          brandsList: data.data
        })
      }, (err) => {
        console.log('err::', err)
        throw err
      })
    },

    detectCategoryPath: ({commit, state}) => {
      let categories = []

      if (state.pageMenuDescription.catalogue && Object.keys(state.pageMenuDescription.catalogue.filters).length > 0) {
        Object.values(state.pageMenuDescription.catalogue.filters).filter(obj => obj.categories).map(one => {
          categories = categories.concat(one.categories)
        })
      }

      let path = state.promiseData && state.promiseData.categoryPath ? state.promiseData.categoryPath : {}

      return Promise.all(categories.map(cat => {
        if (!path[cat]) {
          return new Promise((resolve, reject) => {
            http.get(config.routes.findByCategory + '/' + cat, getAuthConfig({
              params: {
                lang: state.lang
              },
              withCredentials: true
            }, state)).then(response => response.data)
              .then(json => {
                if (json.url) {
                  path[cat] = '/' + json.url
                  commit(types.LOAD_PROMISE_DATA, {
                    categoryPath: path
                  })
                }
                resolve()
              })
              .catch(err => reject(err))
          })
        } else {
          return Promise.resolve()
        }
      }))
    },

    setRouteCategoryUrl: ({state, commit}) => {
      let category = null
      let routerObj = state.pageMenuDescription
      if (routerObj.catalogue && routerObj.catalogue.main_category && routerObj.catalogue.main_category.category_number) {
        category = routerObj.catalogue.main_category.category_number
      }
      if (category) {
        return http.get(config.routes.findByCategory + '/' + category, getAuthConfig({
          params: {
            lang: state.lang
          },
          withCredentials: true
        }, state)).then(response => response.data)
          .then(json => {
            if (json.url) {
              routerObj.categoryPath = json.url
              if (json.category && json.category.category_name) {
                let categoryName = json.category.category_name
                if (json.category.translations && json.category.translations[state.lang]) {
                  categoryName = json.category.translations[state.lang].category_name
                }
                routerObj.categoryName = categoryName
              }
              commit(types.SET_PAGE_DESCRIPTION, routerObj)
            }
          })
      } else {
        return Promise.resolve()
      }
    },
    formMessage ({commit}, obj) {
      commit(types.FORM_MESSAGE, obj)
    },

    changeLanguage ({commit}, lang) {
      lang = lang === 'us' ? 'en' : lang
      commit(types.CHANGE_LANGUAGE, lang)
    },

    clearCookies ({commit}) {
      commit(types.CLEAR_COOKIES)
    },
    setCookies ({commit}, cookies) {
      commit(types.SET_COOKIES, cookies)
    },

    getBlocks ({commit, state}, type) {
      return http.get(config.blocks.getByType + type, getAuthConfig({
        withCredentials: true
      }, state)).then(result => (commit(types.BLOCK_LOAD, {
        type,
        data: result.data
      })))
    },

    resetStateData ({commit}, newState) {
      commit(types.RESET_STATE, newState)
    },

    loadCatalogue ({commit, state, dispatch}, {onlyItems, onlyFilters, router}) {
      if (onlyItems === undefined) {
        onlyItems = 1
      }
      if (onlyFilters === undefined) {
        onlyFilters = 0
      }

      let copyCatalogueInit = JSON.stringify(catalogueInit)
      let catalogue = state.promiseData && state.promiseData.catalogue ? state.promiseData.catalogue : JSON.parse(copyCatalogueInit)
      catalogue.filters = JSON.parse(copyCatalogueInit).filters

      if (catalogue.filtersList) {
        Object.keys(catalogue.filtersList).map(key => {
          Object.assign(catalogue.filters, {[key]: []})
        })
      }
      let routerObj = state.pageMenuDescription
      let searchTerm = null
      let params = null
      if (state.route.query) {
        params = state.route.query
      }
      if (params) {
        if (Object.keys(params).indexOf('page') !== -1) {
          catalogue.pagination.currentPage = params.page
          catalogue.pagination.skip += (catalogue.pagination.currentPage - 1) * catalogue.pagination.limit
          delete params.page
        }
        Object.keys(params).map(key => {
          catalogue.filters[key] = params[key]
        })
      }
      commit(types.LOAD_PROMISE_DATA, {
        catalogue: catalogue
      })

      let data = {
        ...catalogue.filters,
        lang: state.lang,
        skip: catalogue.pagination.skip,
        limit: catalogue.pagination.limit,
        onlyFilters: onlyFilters,
        onlyItems: onlyItems,
        price_from: catalogue.filters && catalogue.filters.price_from ? state.calculatePrice(catalogue.filters.price_from, true) : '',
        price_to: catalogue.filters && catalogue.filters.price_to ? state.calculatePrice(catalogue.filters.price_to, true) : ''
      }
      if (state.route.params && state.route.params.search_term) {
        searchTerm = state.route.params.search_term
        Object.assign(data, {q: searchTerm})
      }

      if (routerObj.catalogue) {
        if (Object.keys(routerObj.catalogue.filters).length > 0) {
          Object.assign(data, {filters: Object.values(routerObj.catalogue.filters)})
        }
        Object.assign(data, {filter_groups: routerObj.catalogue.filter_groups})
      }

      let designer = state.route.path.split('/').pop()
      let designerObj = Object.values(state.allDesigners).find(obj => designer === reverseRouteName(obj.name))
      if (designerObj !== undefined) {
        Object.assign(data, {designer_id: designerObj.id})
      }

      let requestId = onlyItems ? 'itemsRequest' : 'filtersRequest'
      let requestOpt = {
        requestId: requestId,
        headers: {
          'X-Requested-With': 'XMLHttpRequest'
        },
        emulateJSON: true,
        withCredentials: true
      }

      if (onlyItems) {
        commit(types.REQUEST_ITEMS_START)
      }
      return http.post(config.products.searchProducts, qs.stringify(data), getAuthConfig(requestOpt, state))
        .then(r => dispatch(auth.AUTH_ACTION_SUCCESS, r), e => dispatch(auth.AUTH_ACTION_ERROR, e))
        .then(response => response.data)
        .then(json => {
          if (json.filters) {
            json.filters.map(key => {
              if (catalogue.filters && Object.keys(catalogue.filters).indexOf(key) === -1) {
                catalogue.filters[key] = []
              }
            })
          }
          if (json.categories) {
            catalogue.filtersList = json.categories
          }

          if (json.items) {
            catalogue.items.data = json.items.items
            catalogue.items.total = json.items.total
            if (searchTerm && json.items.total === 1) {
              let oneItem = json.items.items[0]
              router.push({
                name: 'ItemPage',
                params: {
                  item: parseFloat(oneItem.id / 100).toFixed(2),
                  designer: reverseRouteName(oneItem.designer_name),
                  model: reverseRouteName(oneItem.model_name),
                  lang: state.lang
                }
              })
            }
            if (json.items.aggregate) {
              let aggregate = json.items.aggregate
              catalogue.items.priceFrom = aggregate.min_price >= 0 ? aggregate.min_price : ''
              catalogue.items.priceTo = aggregate.max_price >= 0 ? aggregate.max_price : ''

              catalogue.items.armFrom = aggregate.min_arm >= 0 ? parseInt(aggregate.min_arm) : ''
              catalogue.items.armTo = aggregate.max_arm >= 0 ? parseInt(aggregate.max_arm) : ''

              catalogue.items.bridgeFrom = aggregate.min_bridge >= 0 ? parseInt(aggregate.min_bridge) : ''
              catalogue.items.bridgeTo = aggregate.max_bridge >= 0 ? parseInt(aggregate.max_bridge) : ''

              catalogue.items.lensFrom = aggregate.min_lens >= 0 ? parseInt(aggregate.min_lens) : ''
              catalogue.items.lensTo = aggregate.max_lens >= 0 ? parseInt(aggregate.max_lens) : ''
            }
          }

          commit(types.LOAD_PROMISE_DATA, {
            catalogue: catalogue
          })

          if (catalogue.items.total === 0 && catalogue.filters && (catalogue.filters.price_from || catalogue.filters.price_to)) {
            dispatch('findRangePrice')
          }
          if (onlyItems) {
            commit(types.REQUEST_ITEMS_END)
          }
        })
        .catch(err => {
          if (onlyItems) {
            commit(types.REQUEST_ITEMS_END)
          }
          commit(types.LOAD_PROMISE_DATA, {
            catalogue: catalogue
          })
          console.log('error load catalogue:::', err)
          throw err
        })
    },

    findRangePrice ({state, commit}) {
      let copyCatalogueInit = JSON.stringify(catalogueInit)
      let catalogue = state.promiseData && state.promiseData.catalogue ? state.promiseData.catalogue : JSON.parse(copyCatalogueInit)
      let routerObj = state.pageMenuDescription
      let data = {
        ...catalogue.filters,
        price_from: catalogue.filters.price_from || catalogue.filters.price_from.length > 0 ? state.calculatePrice(catalogue.filters.price_from, true) : '',
        price_to: catalogue.filters.price_to || catalogue.filters.price_to.length > 0 ? state.calculatePrice(catalogue.filters.price_to, true) : ''
      }
      if (routerObj.catalogue) {
        if (Object.keys(routerObj.catalogue.filters).length > 0) {
          Object.assign(data, {filters: Object.values(routerObj.catalogue.filters)})
        }
      }

      let requestId = 'rangePriceRequest'
      let requestOpt = {
        requestId: requestId,
        headers: {
          'X-Requested-With': 'XMLHttpRequest'
        },
        emulateJSON: true,
        withCredentials: true
      }
      return http.post(config.products.getRangePrice, qs.stringify(data), getAuthConfig(requestOpt, state))
        .then(response => response.data)
        .then(json => {
          if (json.min_price) {
            let minPrice = parseInt(state.calculatePrice(json.min_price))
            if (minPrice == catalogue.filters.price_from) {
              json.min_price = parseFloat(json.min_price) - 1
              minPrice = parseInt(state.calculatePricee(json.min_price))
            }
            catalogue.filters.price_from = minPrice.toString()
          }
          if (json.max_price) {
            let maxPrice = parseInt(state.calculatePrice(json.max_price))
            if (maxPrice == catalogue.filters.price_to) {
              json.max_price = parseFloat(json.max_price) + 1
              maxPrice = Math.ceil(state.calculatePrice(json.max_price))
            }
            catalogue.filters.price_to = maxPrice.toString()
          }
          commit(types.LOAD_PROMISE_DATA, {
            catalogue: catalogue
          })
        })
        .catch(res => {
          return res
        })
    },

    getDescriptionForBrandPage ({state, commit, dispatch}) {
      let copyCatalogueInit = JSON.stringify(catalogueInit)
      let catalogue = state.promiseData && state.promiseData.catalogue ? state.promiseData.catalogue : JSON.parse(copyCatalogueInit)
      let routerObj = state.pageMenuDescription
      let filters = []

      let hasBrand = false
      if (routerObj.catalogue && Object.keys(routerObj.catalogue.filters).length > 0) {
        filters = Object.values(routerObj.catalogue.filters)
        hasBrand = filters.filter(obj => obj.designer && obj.designer > 0).length > 0
      }

      let data = {
        filters: filters,
        designer_lang: state.lang
      }

      let designer = state.route.path.split('/').pop()
      let designerObj = Object.values(state.allDesigners).find(obj => designer === reverseRouteName(obj.name))
      if (designerObj !== undefined) {
        data.designer_number = designerObj.id
        hasBrand = true
      }

      let requestId = 'brandsDescriptionRequest'
      let requestOpt = {
        requestId: requestId,
        headers: {
          'X-Requested-With': 'XMLHttpRequest'
        },
        emulateJSON: true,
        withCredentials: true
      }

      if (!hasBrand) {
        return Promise.resolve()
      }
      return http.post(config.products.getDesignerDescription, qs.stringify(data), getAuthConfig(requestOpt, state))
        .then(r => dispatch(auth.AUTH_ACTION_SUCCESS, r), e => dispatch(auth.AUTH_ACTION_ERROR, e))
        .then(response => {
          if (response.data) {
            catalogue.designerDescription = response.data
            commit(types.LOAD_PROMISE_DATA, {
              catalogue: catalogue
            })
          }
        })
        .catch(err => {
          console.log('getDescriptionForBrandPage action error::', err)
          throw err
        })
    },

    loadCheapGlasses ({commit, state}, {append}) {
      let catalogue = state.promiseData && state.promiseData.cheapGlasses ? state.promiseData.cheapGlasses : {...cheapGlassesInit}
      let routerObj = state.pageMenuDescription
      if (state.route.query && state.route.query.page) {
        catalogue.pagination.currentPage = state.route.query.page
        catalogue.pagination.skip += (catalogue.pagination.currentPage - 1) * catalogue.pagination.limit
      }

      let data = {
        skip: catalogue.pagination.skip,
        limit: catalogue.pagination.limit
      }

      if (routerObj.catalogue && Object.values(routerObj.catalogue.filters).length > 0) {
        Object.assign(data, {filters: Object.values(routerObj.catalogue.filters)})
      }

      let requestOpt = {
        requestId: 'cheapGlassesRequest',
        headers: {
          'X-Requested-With': 'XMLHttpRequest'
        },
        emulateJSON: true,
        withCredentials: true
      }

      commit(types.REQUEST_START)
      return http.post(config.products.cheapGlasses, qs.stringify(data), getAuthConfig(requestOpt, state)).then(response => response.data)
        .then(json => {
          if (json.items) {
            if (append && catalogue.items.data) {
              catalogue.items.data = Object.assign({}, catalogue.items.data, json.items)
            } else {
              catalogue.items.data = json.items
            }
            catalogue.items.total = json.total
            commit(types.LOAD_PROMISE_DATA, {
              cheapGlasses: catalogue
            })
          }
          commit(types.REQUEST_END)
        })
        .catch(err => {
          commit(types.REQUEST_END)
          throw err
        })
    },

    fetchItem ({commit, state}, router) {
      let homeTitle = 'HOME'
      let itemId = state.route.params.item
      itemId = itemId.toString().replace('.', '')
      if (Vue.config.i18n) {
        homeTitle = Vue.config.i18n.t('Home')
      }
      let requestOptions = {
        params: {
          item_number: itemId,
          lang: state.lang
        },
        headers: {
          'X-Requested-With': 'XMLHttpRequest'
        },
        emulateJSON: true,
        withCredentials: true
      }

      return http.get(config.products.itemFetch, getAuthConfig(requestOptions, state))
        .then(data => {
          if (data.data.item && Object.keys(data.data.item).length > 1 && data.data.item.options) {
            let item = data.data.item
            let trans = translateField.bind({lang: state.lang})

            const {main_category: {category_name, category_number, short_name}} = item
            item.category_number = category_number
            item.designer_number = item.designer.designer_number
            item.designerUrl = '#'
            let designerName = trans(item.designer.translations, 'designer_name')
            let modelName = trans(item.model.translations, 'model_name')
            if (category_number) {
              return http.get(config.routes.findByCategory + '/' + category_number, getAuthConfig({
                params: {
                  lang: state.lang
                },
                withCredentials: true
              }, state)).then(response => response.data)
                .then(json => {
                  if (json.url) {
                    let itemPath = '/' + json.url + '/' + reverseRouteName(designerName) + '/' + reverseRouteName(modelName) + '/ss' + parseFloat(item.item_number / 100).toFixed(2) + '.html'
                    itemPath = ('/' + config.subDomain + '/' + itemPath).replace(new RegExp('/{2,}', 'g'), '/')
                    if (state.route.path !== itemPath) {
                      router.replace(itemPath)
                    }
                    let breadcrumbs = [{title: homeTitle, path: '/'}]
                    if (json.category && json.category.category_name) {
                      let categoryName = json.category.category_name
                      if (json.category.translations && trans(json.category.translations, 'category_name')) {
                        categoryName = trans(json.category.translations, 'category_name')
                      }
                      item.designerUrl = '/' + json.url + '/' + reverseRouteName(designerName)
                      breadcrumbs = breadcrumbs.concat([
                        {title: categoryName, path: '/' + json.url},
                        {
                          title: designerName,
                          path: item.designerUrl
                        },
                        {title: modelName, path: '#'}])
                    }
                    if (state.backRoute.path && state.backRoute.path.length > 0 && state.backRoute.params.category_name) {
                      breadcrumbs.splice(-1, 0, {
                        title: state.backRoute.params.category_name,
                        path: state.backRoute.path
                      })
                    }
                    commit(types.SET_BREADCRUMBS, breadcrumbs)
                    commit(types.LOAD_PROMISE_DATA, {
                      storeItem: item
                    })
                  }
                })
            } else {
              let itemPath = '/' + reverseRouteName(short_name) + '/' + reverseRouteName(item.designer.designer_name) + '/' + reverseRouteName(item.model_name) + '/ss' + parseFloat(item.item_number / 100).toFixed(2) + '.html'
              if (state.route.path !== itemPath) {
                router.replace(itemPath)
              }
              let breadcrumbs = [{title: homeTitle, path: '/'}]
              if (category_name) {
                let category_path = category_name.split(' ').reverse().join('/').toLowerCase()
                item.designerUrl = '/' + category_path + '/' + reverseRouteName(designerName)
                breadcrumbs = breadcrumbs.concat([
                  {title: category_name, path: '/' + category_path},
                  {
                    title: designerName,
                    path: item.designerUrl
                  },
                  {title: modelName, path: '#'}])
              }
              if (state.backRoute.path && state.backRoute.path.length > 0 && state.backRoute.params.category_name) {
                breadcrumbs.splice(-1, 0, {
                  title: state.backRoute.params.category_name,
                  path: state.backRoute.path
                })
              }
              commit(types.SET_BREADCRUMBS, breadcrumbs)
              commit(types.LOAD_PROMISE_DATA, {
                storeItem: item
              })
            }
          }
        }, (err) => {
          console.log('err::', err)
          throw err
        })
    },
// this Action  fetch data customer`s  reviews about  one item , and convey  the data into Review (Component) via store .
    fetchDataReviews ({commit, state}, itemId) {
      return http.get(config.reviews.reviewItem, getAuthConfig({
        params: {
          item_id: itemId
        },
        withCredentials: true
      }, state))
        .then(res => commit(types.FETCH_DATA_REVIEWS, {reviews: res.data}))
        .catch(error => console.log(error))
    },

    setHeaders ({commit}, headers) {
      commit(types.SET_HEADERS, headers)
    },

    fetchUrlBatchBySID ({commit, state}, data) {
      const params = data.reduce((acc, name) => {
        if (!(state.urls[state.lang] && state.urls[state.lang][name])) {
          acc.push({
            name,
            lang: state.lang
          })
        }
        return acc
      }, [])
      return params.length ? (http.get(config.routes.getUrlBatchBySID, getAuthConfig({
        params: {
          data: params
        },
        withCredentials: true
      }, state)).then(result => commit(types.FETCH_URL_BATCH_BY_SID, result.data.map(item => {
        item.lang = state.lang
        return item
      })))) : Promise.resolve()
    },

    addUrlCommit ({commit, dispatch, state}, {name, component}) {
      const isFirst = !state.urlCommit.data
      const isServer = getIsServer()
      commit(types.URL_COMMIT, name)
      const getBase = component => {
        return component.$options.parent && component.$options.parent.$options.name && getBase(component.$options.parent) || component
      }
      if (isFirst) {
        getBase(component).$nextTick(() => {
          const data = state.urlCommit.data
          if (isServer) {
            delete state.urlCommit.data
          }
          dispatch('fetchUrlBatchBySID', data)
            .then(() => {
              !isServer && commit(types.PREPARE_URL_COMMIT)
            })
        })
      }
    }
  }
}
