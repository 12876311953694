import { mapState } from 'vuex'
import {
  getFormatted,
  reverseRoute,
  getMinPromo,
  cdnUrl,
  getDashName,
  getSuperSaverPrice,
  legacyEscapeName,
  titleCase
} from '@/utils/filter_functions'

import config from '@/../config'
import { translate, translateComponent, translateField, convertContext } from '@/utils/handlebars'

export default {
  data: function () {
    return {
      isMounted: false
    }
  },
  computed: {
    ...mapState({
      auth: (state) => state.authorization,
      calculatePrice: (state) => state.calculatePrice,
      lang: (state) => state.lang,
      isMobile: (state) => state.isMobile,
      ssv4User: (state) => ({...state.ssv4User})
    }),
    confCdnUrl () {
      return config.cdnUrl
    },
    imageSubPath () {
      return (config.subDomain + '/' + config.buildPrefix + '/').replace(new RegExp('/{2,}', 'g'), '/')
    },
    showBadge () {
      let checkProd = config.env.NODE_ENV === '"prod"' || '"production"'

      return !checkProd
    },
    showMenus () {
      return ['Checkout', 'Thank', 'Fail', 'CheckoutPayment'].indexOf(this.$route.name) < 0
    },
    showTemplates () {
      return ['Thank'].indexOf(this.$route.name) < 0
    }
  },
  methods: {
    get_formatted: getFormatted,
    reverse_route: reverseRoute,
    getMinPromo: getMinPromo,
    $trans: translate,
    $transComponent: translateComponent,
    $tf: translateField, // translate field, check on exist translation by current lang, another by deflocale
    cdnUrl: cdnUrl,
    get_dash_name: getDashName,
    getSuperSaverPrice,
    legacy_escape_name: legacyEscapeName,
    $link (link) { // localization link
      return ('/' + config.subDomain + '/' + link).replace(new RegExp('/{2,}', 'g'), '/')
    },
    $rname (name) { // convert router name according with selected language - for correct routing
      return name + '_' + this.lang
    },
    convertContext: convertContext,
    getSSv4Link (slug) {
      return config.integrationHost + slug
    },
    getCartSSv4Link () {
      const order_id = this.$route.query.order_id || false
      return this.getSSv4Link('/cart/index/' + (order_id ? ('?order_id=' + order_id) : ''))
    },
    doCheckSSRmounting () {
      if (!window.document.querySelector('#ssr_mode')) {
        return true
      }

      if (!this.$root.skipSSRhydration) {
        this.$root.skipSSRhydration = 1
      }

      return !!(++this.$root.skipSSRhydration % 2)
    },
    checkSSRmounting () {
      this.isMounted = this.doCheckSSRmounting()
      return this.isMounted
    },
    fetchSSv4Menu () {
      return this.$store.dispatch(this.isMobile ? 'loadSSv4MobileMenu' : 'loadSSv4Menu')
    },
    disableLoader () {
      window.document.getElementById('main_loader').style.display = 'none'
    },
    beforeMount () {
      this.disableLoader()
    }
  },
  filters: {
    get_formatted: getFormatted,
    reverse_route: reverseRoute,
    getMinPromo: getMinPromo,
    cdnUrl: cdnUrl,
    get_dash_name: getDashName,
    getSuperSaverPrice,
    legacy_escape_name: legacyEscapeName,
    titleCase: titleCase
  }
}
