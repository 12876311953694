import { mapState } from 'vuex'

import ReviewGuidesModal from '@/components/scripts/Review/ReviewGuidesModal/index.vue'
import config from '@/../config'

export default {
  name: 'Review',
  components: {
    ReviewGuidesModal
  },
  computed: {
    ...mapState({
      data: (state) => {
        return {
          step: 1,
          ...state.formData['login']
        }
      },
      meta: (state) => ({
        ...state.formMeta.orders || {}
      }),
      loading: (state) => state.loading,
      apiHost: (state) => state.apiHost,
      user: (state) => {
        const {profile, ...user} = state.user
        if (profile) { return user }
        return user
      },
      profile: (state) => {
        const {user: {profile: data}} = state
        const {addresses, ...profile} = data
        if (addresses) { return profile }
        return profile
      }
    })
  },
  data () {
    return {
      item: {},
      ss: null,
      formr: {
        riid: '',
        rrst: '0',
        rrq: '0',
        rrv: '0',
        rcp: '1',
        fit: '3',
        old: '0',
        style: '0',
        rti: '',
        rtt: '',
        image: ''
      },
      egn: ''
    }
  },
  methods: {
    loadItem: function (itemId) {
      let self = this
      this.$axios.get(this.apiHost + config.prefix + config.reviews.reviewItem, Object.assign({}, this.requestOptions, {params: {item_id: itemId}}))
        .then(data => {
          self.item = data.data.item
        }, (err) => console.log('err::', err))
    },
    commitMethod: function () {
      let self = this
      this.$axios.post(this.apiHost + config.prefix + config.reviews.reviewCreate, self.formr).then(function (response) {
        if (response.data.status) {
          self.$router.push(self.$link(config.reviews.reviewAccount))
        } else {
          if (response.data.error_code === 23505) {
            self.$router.push(self.$link(config.reviews.reviewAccount))
          } else if (response.data.error_code === 403) {
            self.$router.push(config.users.login)
          } else {
            alert('Some errors in the page. Please contact support.')
            console.log(response.data.error)
          }
        }
      }, function (error) {
        alert('Some errors in the page. Please contact support.')
        console.log(error)
      })
    },
    onFileChange (e) {
      var files = e.target.files || e.dataTransfer.files
      if (!files.length) { return }
      this.createImage(files[0])
    },
    createImage (file) {
      // var image = new Image()
      var reader = new FileReader()
      var vm = this

      reader.onload = (e) => {
        vm.formr.image = e.target.result
      }
      reader.readAsDataURL(file)
    },
    removeImage: function (message, event) {
      this.formr.image = ''
    }
  },
  mounted () {
    let itemNumber = this.$route.params.item_id
    this.formr.riid = itemNumber
    this.loadItem(itemNumber)
    this.ss = 'ss' + itemNumber.slice(0, -2) + '.' + itemNumber.slice(-2)
  }
}
