import { mapState } from 'vuex'

export default {
  name: 'EditPrescriptionPage',
  data: () => ({
    id: null,
    profile_id: null,
    right: {
      sph: 0,
      cyl: 0,
      axis: 0,
      add: 0,
      pd: 0,
      prism_hor_pow: 0,
      prism_hor_dir: '',
      prism_ver_pow: 0,
      prism_ver_dir: ''
    },
    left: {
      sph: 0,
      cyl: 0,
      axis: 0,
      add: 0,
      pd: 0,
      prism_hor_pow: 0,
      prism_hor_dir: '',
      prism_ver_pow: 0,
      prism_ver_dir: ''
    },
    hasPrism: false,
    pd: 0,
    hasDualPD: false,
    info: '',
    name: '',
    confirmation: false
  }),
  computed: {
    ...mapState({
      prescription: (state) => state.currentPrescription
    }),
    valuesForSph () {
      let i = -20
      const result = []
      while (i <= 12) {
        result.push(i)
        if (i === 0) {
          result.push('PLANO')
          result.push('INFINITY')
        }
        i += 0.25
      }
      return result
    },
    valuesForCyl () {
      let i = -6
      const result = []
      while (i <= 6) {
        result.push(i)
        if (i === 0) {
          result.push('DS')
          result.push('PLANO')
          result.push('INFINITY')
        }
        i += 0.25
      }
      return result
    },
    valuesForAxis () {
      let i = 0
      const result = []
      while (i <= 180) {
        result.push(i)
        i += 1
      }
      return result
    },
    valuesForAdd () {
      let i = 0
      const result = []
      while (i <= 4) {
        result.push(i)
        i += 0.25
      }
      return result
    },
    valuesForPrismPower () {
      let i = 0
      const result = []
      while (i <= 3) {
        result.push(i)
        i += 0.25
      }
      return result
    },
    valuesForPD () {
      let i = 50
      const result = [0, 'average']
      while (i <= 80) {
        result.push(i)
        i += 0.5
      }
      return result
    },
    valuesForDualPD () {
      let i = 25
      const result = [0]
      while (i <= 40) {
        result.push(i)
        i += 0.5
      }
      return result
    }
  },
  methods: {
    formatText (num) {
      if (isNaN(num)) {
        return num
      }
      if (num === 0) {
        return 'NONE'
      }
      const sign = num > 0 ? '+' : '-'
      return `${sign} ${Math.abs(num).toFixed(2)}`
    },
    formatPowerText (num) {
      if (isNaN(num)) {
        return num
      }
      if (num === 0) {
        return 'Power'
      }
      const sign = num > 0 ? '+' : '-'
      return `${sign} ${Math.abs(num).toFixed(2)}`
    },
    formatPDText (num) {
      if (num === 0) {
        return 'Select PD...'
      }
      if (num === 'average') {
        return 'Average / Don\'t know PD'
      }
      return `${Math.abs(num).toFixed(2)}`
    },
    formatDualPDText (num, left = true) {
      if (isNaN(num)) {
        return num
      }
      if (num === 0) {
        return left ? 'Left' : 'Right'
      }
      return `${Math.abs(num).toFixed(2)}`
    },
    async savePrescription () {
      if (!this.confirmation) {
        alert(this.$trans('ConfirmOrderDetails'))
        return
      }
      if (!this.pd && !this.right.pd) {
        alert(this.$trans('EnterPDToProceed'))
        return
      }
      if (!this.hasDualPD && this.pd === 'average') {
        this.right.pd = 'average'
        this.left.pd = 'average'
      } else if (!this.hasDualPD) {
        this.right.pd = this.left.pd = this.pd / 2
      }
      await this.$store.dispatch('saveAccountPrescription', {
        left: this.left,
        right: this.right,
        name: this.name,
        info: this.info,
        id: this.id ? this.id : null,
        profile_id: this.profile_id ? this.profile_id : null
      })
      this.$router.push({
        name: 'AccountPrescriptions'
      })
    }
  },
  watch: {
    prescription (data) {
      if (data && this.$route.params.id && parseInt(this.$route.params.id, 10) === parseInt(data.id, 10)) {
        const left = data.left
        const right = data.right
        if (left.sph_text) {
          left.sph = left.sph_text
        }
        if (left.cyl_text) {
          left.cyl = left.cyl_text
        }
        if (right.sph_text) {
          right.sph = right.sph_text
        }
        if (right.cyl_text) {
          right.cyl = right.cyl_text
        }
        if (right.prism_hor_pow || right.prism_ver_pow || left.prism_hor_pow || left.prism_ver_pow) {
          this.hasPrism = true
        }
        if (!right.prism_hor_dir) {
          right.prism_hor_dir = ''
        }
        if (!right.prism_ver_dir) {
          right.prism_ver_dir = ''
        }
        if (!left.prism_hor_dir) {
          left.prism_hor_dir = ''
        }
        if (!left.prism_ver_dir) {
          left.prism_ver_dir = ''
        }
        this.left = left
        this.right = right
        this.info = data.info
        this.name = data.name
        this.id = data.id
        this.profile_id = data.profile_id
        if (left.pd_text) {
          this.hasDualPD = false
          this.pd = left.pd_text
        } else if (left.pd === right.pd) {
          this.hasDualPD = false
          this.pd = left.pd + right.pd
        } else {
          this.hasDualPD = true
          this.right.pd = right.pd
          this.left.pd = left.pd
        }
      }
    }
  },
  created () {
    if (this.$route.params.id) {
      this.$store.dispatch('fetchCurrentPrescription', this.$route.params.id)
    }
  }
}
