import {mapState} from 'vuex'

import config from '@/../config'

export default {
  name: 'Reviews',
  computed: {
    ...mapState({
      data: (state) => {
        const {user: {profile: data}} = state
        const {...profile} = data

        return {
          step: 1,
          ...state.formData['login'],
          profile
        }
      },
      meta: (state) => ({
        ...state.formMeta.orders || {}
      }),
      loading: (state) => state.loading,
      apiHost: (state) => state.apiHost,
      user: (state) => {
        const {...user} = state.user
        return user
      },
      profile: (state) => {
        const {user: {profile: data}} = state
        const {...profile} = data
        return profile
      }
    })
  },
  data () {
    return {
      items: {
        has_no_review: {},
        has_review: {}
      }
    }
  },
  methods: {
    loadItemsReview () {
      let self = this
      this.$http.get(config.apiHost + config.prefix + config.reviews.reviewList).then(function (response) {
        self.items = response.data
      }, function (error) {
        console.log(error.statusText)
      })
    }
  },
  mounted () {
    this.loadItemsReview()
  }
}
