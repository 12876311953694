import {mapState} from 'vuex'

export default {
  name: 'AccountContents',
  computed: {
    ...mapState({
      auth: (state) => state.authorization,
      data: (state) => {
        const {user: {profile: data}} = state
        const {...profile} = data

        return {profile}
      }
    })
  }
}
