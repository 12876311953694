<template lang="pug">
  #app(data-app="true")
    ProjectVersion
    #pageWrapper
      SideBar(v-if="showMenus && !isSSv4Integration")#sidebarNav.d-lg-none
      SideBarSSv4(v-if="showMenus && isSSv4Integration")#sidebarNav.d-lg-none
      #mainWrapper
        PageHeader(v-if="showTemplates && !isSSv4Integration")
        PageHeaderSSv4(v-if="showTemplates && isSSv4Integration")
        PageNotFound(v-if="notFound")
        router-view(v-else)
        PageFooter(v-if="showTemplates && !isSSv4Integration")
        PageFooterSSv4(v-if="showTemplates && isSSv4Integration")
        ScrollToTop(v-if="showTemplates")
        .sidebar-backdrop(v-if="showMenus", @click="closeSidebar")
    LoginModal
</template>

<script>
  import ProjectVersion from '@/components/scripts/ProjectVersion/index.vue'
  import PageHeader from '@/components/scripts/PageHeader/index.vue'
  import PageFooter from '@/components/scripts/PageFooter/index.vue'
  import ScrollToTop from '@/components/scripts/ScrollToTop/index.vue'
  import SideBar from '@/components/scripts/SideBar/index.vue'
  import SideBarSSv4 from '@/components/scripts/SideBarSSv4/index.vue'
  import LoginModal from '@/components/scripts/LoginModal/index.vue'
  import PageNotFound from '@/components/scripts/PageNotFound/index.vue'
  import PageHeaderSSv4 from '@/components/scripts/PageHeaderSSv4/index.vue'
  import PageFooterSSv4 from '@/components/scripts/PageFooterSSv4/index.vue'

  import {mapState} from 'vuex'
  import {loadedLanguages} from '@/utils/i18n'
  import config from '@/../config'

  import $ from 'jquery'

  export default {
    name: 'mainApp',
    components: {
      ProjectVersion,
      PageHeader,
      PageHeaderSSv4,
      PageFooter,
      PageFooterSSv4,
      ScrollToTop,
      SideBar,
      SideBarSSv4,
      LoginModal,
      PageNotFound
    },
    computed: {
      ...mapState({
        routerObj: state => state.pageMenuDescription,
        currency: state => ({
          ...state.currency.allCurrency[state.currency.selected]
        }),
        exchange: state => state.currency.exchange,
        exchangeBack: state => state.currency.exchangeBack,
        calculatePrice: state => state.calculatePrice
      }),
      isSSv4Integration () {
        return config.ssv4.reduce((acc, path) => {
          return acc || !!this.$route.path.match(new RegExp(path))
        }, false)
      }
    },
    data () {
      return {
        notFound: false
      }
    },
    head: {
      title: function () {
        let title = ''
        if (
          this.routerObj.page &&
          this.routerObj.page.translations &&
          Object.keys(this.routerObj.page.translations).length > 0
        ) {
          title = this.$tf(this.routerObj.page.translations, 'meta_title')
        }
        if (
          this.routerObj.catalogue &&
          this.routerObj.catalogue.translations &&
          Object.keys(this.routerObj.catalogue.translations).length > 0
        ) {
          title = this.$tf(this.routerObj.catalogue.translations, 'meta_title')
        }
        title =
          title !== null && title.length > 0
            ? this.convertMenuContext(title)
            : ''
        return {
          inner: title
        }
      },
      meta: function () {
        let description = ''
        let keywords = ''
        let noindex = 0
        if (this.routerObj.page) {
          if (
            this.routerObj.page.translations &&
            Object.keys(this.routerObj.page.translations).length > 0
          ) {
            description = this.$tf(
              this.routerObj.page.translations,
              'meta_description'
            )
            keywords = this.$tf(
              this.routerObj.page.translations,
              'meta_keywords'
            )
          }
          noindex = this.routerObj.page.noindex
        }
        if (
          this.routerObj.catalogue &&
          this.routerObj.catalogue.translations &&
          Object.keys(this.routerObj.catalogue.translations).length > 0
        ) {
          description = this.$tf(
            this.routerObj.catalogue.translations,
            'meta_description'
          )
          keywords = this.$tf(
            this.routerObj.catalogue.translations,
            'meta_keywords'
          )
        }
        return [
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1, user-scalable=no'
          },
          {
            hid: 'description',
            name: 'description',
            content:
              description !== null && description.length > 0
                ? this.convertMenuContext(description)
                : ''
          },
          keywords !== null && keywords.length > 0
            ? {
              hid: 'keywords',
              name: 'keywords',
              content: keywords
            }
            : {},
          noindex
            ? {
              name: 'robots',
              content: 'noindex,follow'
            }
            : {}
        ]
      }
    },
    created () {
      let lang = this.lang
      if (loadedLanguages.indexOf(lang) === -1) {
        lang = config.defLocale
        this.notFound = true
      } else {
        this.changeMenu()
      }
      this.$i18n.locale = lang
      localStorage.setItem('lang', lang)
      this.$store.dispatch('changeLanguage', lang)
    },
    mounted () {
      this.scrollToTop()
      this.checkDataModal()
    },
    methods: {
      closeSidebar: function () {
        this.$store.dispatch('closeSidebar')
      },
      scrollToTop () {
        $('HTML, BODY, #sidebarNav').animate(
          {
            scrollTop: 0
          },
          10
        )
      },
      convertMenuContext (context) {
        let text = this.convertContext(context)
        const textarea = document.createElement('div')
        textarea.innerHTML = text
        return textarea.innerText
          ? textarea.innerText
          : textarea.textContent
            ? textarea.textContent
            : text
      },
      changeMenu () {
        // this.$store.dispatch('loadMenus')
        // this.$store.dispatch('loadMobileMenu')
      },
      checkDataModal () {
        $(document)
          .find('[data-open]')
          .each(function (index) {
            $(this)
              .attr('data-toggle', 'modal')
              .attr('data-target', '#' + $(this).attr('data-open'))
              .removeAttr('data-open')
          })
      }
    },
    watch: {
      $route (to, from) {
        this.scrollToTop()
      },
      routerObj: {
        handler: function () {
          this.$emit('updateHead')
        },
        deep: true
      },
      lang: function () {
        this.changeMenu()
        let self = this
        this.$nextTick().then(() => {
          self.checkDataModal()
        })
      }
    }
  }
</script>

<style src="bootstrap/dist/css/bootstrap.min.css"></style>
<style src="tether/dist/css/tether.min.css"></style>
<style src="slick-carousel/slick/slick.css"></style>
<style src="slick-carousel/slick/slick-theme.css"></style>
<style src="font-awesome/css/font-awesome.min.css"></style>
<style src="jquery.rateit/scripts/rateit.css"></style>
<style src="swiper/dist/css/swiper.min.css"></style>
<style lang="scss" src="@/components/templates/default/_Styles/rateit-settings.scss"></style>
<style lang="scss" src="@/components/templates/default/_Styles/slick-settings.scss"></style>
<style lang="scss" src="@/components/templates/default/_Styles/swiper-settings.scss"></style>
<style lang="scss" src="@/components/templates/default/_Styles/main.scss"></style>
