import Breadcrumbs from '@/components/scripts/Breadcrumbs/index.vue'
import Intro from '@/components/scripts/Reglaze/Intro/index.vue'
import Offers from '@/components/scripts/Reglaze/Offers/index.vue'
import Accordion from '@/components/scripts/Reglaze/Accordion/index.vue'
import Tabs from '@/components/scripts/Reglaze/Tabs/index.vue'
import TrustpilotWidget from '@/components/scripts/TrustpilotWidget/index.vue'

export default {
  name: 'Reglaze',
  components: {
    Breadcrumbs,
    Intro,
    Offers,
    Accordion,
    Tabs,
    TrustpilotWidget
  }
}
