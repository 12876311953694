import { render, staticRenderFns } from "@/components/templates/default/Prescription/SelectUse/bifocal.pug?vue&type=template&id=200fe0ee&scoped=true&lang=pug&"
var script = {}
import style0 from "@/components/templates/default/Prescription/index.scss?vue&type=style&index=0&id=200fe0ee&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "200fe0ee",
  null
  
)

component.options.__file = "index.vue"
export default component.exports