import VerticalMenu from '@/components/scripts/SideBarSSv4/VerticalMenu/index.vue'

export default {
  name: 'SideBarSSv4',
  components: {VerticalMenu},
  methods: {
    closeSidebar: function () {
      this.$store.dispatch('closeSidebar')
    }
  }
}
