export default {
  name: 'CheckoutIntegration',
  loadScript: function (currThis, currDocument, emitCode, scriptTag, emitUrl, params) {
    scriptTag = scriptTag || 'script'
    emitUrl = emitUrl || false
    let self = currThis
    return new Promise((resolve, reject) => {
      if (self.is_script_loading) {
        self.$root.$on('script_loaded', resolve)
        return
      }
      let script = document.createElement(scriptTag)
      script.setAttribute('type', 'text/javascript')
      if (emitUrl) {
        script.setAttribute('src', emitUrl)
      } else {
        script.innerHTML = this[emitCode](currThis, currDocument, params)
      }
      script.async = true
      self.$root.$emit('loading_script')
      script.onload = () => {
        self.$root.$emit('script_loaded')
        resolve()
      }
      currDocument.head.appendChild(script)
    })
  },
  getGa: function (currThis, currDocument) {
    this.loadScript(currThis, currDocument, 'gaNoScript', 'noscript')
    return `var _gaq = _gaq || [];
      _gaq.push(['_setAccount', 'EMPTY']);
      _gaq.push(['_trackPageview']);
      (function() {
      var ga = document.createElement('script'); ga.type = 'text/javascript'; ga.async = true;
      ga.src = ('https:' == document.location.protocol ? 'https://ssl' : 'http://www') + '.google-analytics.com/ga.js';
      var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ga, s);
      })();
          tagManagerId = 'GTM-5Z59D4';
          dataLayer = [];
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      '//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-5Z59D4');`
  },
  gaNoScript: function () {
    return `<iframe src="//www.googletagmanager.com/ns.html?id=GTM-5Z59D4"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>`
  },
  getEmarsys: function (currThis) {
    return `
    var CoreVars = {"base_cdn_url":"https://d9qzjwuieyamt.cloudfront.net/5.3.16.44","base_url":"https://www.selectspecs.com","base_site":"uk","ssl_base_url":"https://www.selectspecs.com","profile_id":"395615","skip_scarab":false,"t":{"NA":"NA"}};

                                                var message = false;
                                    var info = false;
                                    var success = false;
                                    var warning = false;
                                    var error = false;

            var getCountWishlistFun = function() {
                //$.post('https://www.selectspecs.com/de/wishlist/ajaxgetcountwishlist/', null, function(data) {
                //    $('#countWishlist').text(data.count_wishlist ? 'Wunschliste (' + data.count_wishlist + ')' : 'Wunschliste');
                //});
            };

            $(document).ready(function() {
                getCountWishlistFun();
                $('#lang').change(function() {
                    document.location.href = $(this).val();
                });
            });
    if (!CoreVars.skip_scarab) {
    var ScarabQueue = ScarabQueue || [];
    (function(id) {
        if (document.getElementById(id)) return;
        var js = document.createElement('script'); js.id = id;
        js.src = '//cdn.scarabresearch.com/js/12C7039EBD3D5384/scarab-v2.js';
        var fs = document.getElementsByTagName('script')[0];
        fs.parentNode.insertBefore(js, fs);
    })('scarab-js-api');
    };`
  },
  getEmarsysScarab: function (currThis, currDocument, params) {
    return `if (window.ScarabQueue) {
              var cartItems = ` + params.items + `, cartItemsObj = {}
              if (s) {
                ScarabQueue.push(['searchTerm', s])
              }
            }
            $.each(cartItemsObj, function (i, e) {
              cartItems.push(e)
            })
            ScarabQueue.push(['cart', cartItems])
            ScarabQueue.push(['go'])`
  },
  setEmarsysEmail: function (currThis, currDocument, params) {
    return `if (window.ScarabQueue) {
              ScarabQueue.push(['setEmail', '` + params.email + `'])
              `
  },
  getFbPixel: function () {
    return `!function(f,b,e,v,n,t,s) {if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window,document,'script','https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '428176317752631');
      fbq('track', 'PageView');`
  }
}
