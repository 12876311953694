export default function (price, back = false, print = false, vat = true, rate = true) {
  const {exchange, exchangeBack, selected = 1, allCurrency} = this.currency
  const current = {...allCurrency[selected]}
  let result

  if (back) {
    try {
      result = this.vat(exchangeBack(price, /* toNumber = */ false), back, /* toNumber = */ false).price
    } catch (e) {
      result = exchangeBack(price, /* toNumber = */ false)
    }
  } else {
    try {
      if (vat) {
        price = this.vat(price, back, /* toNumber = */ false).price
      }
      result = exchange(price, /* toFixed = */ false, rate, /* toNumber = */ false)
    } catch (e) {
      result = exchange(price, /* toFixed = */ false, rate, /* toNumber = */ false)
    }
  }

  const precis = (current.precision_digit || current.precision_digit === 0) ? current.precision_digit : 0
  result = precis >= 0 ? result.toFixed(precis) : result.toNumber()

  if (print) {
    let el = global.document.createElement('span')
    el.innerHTML = current.symbol + result
    result = el.innerText ? el.innerText : (el.textContent ? el.textContent : el.innerHTML)
  }
  return result
}
