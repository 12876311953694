<template lang="pug">
  section.account-breadcrumbs
    .container(v-if="($route.name) !== 'AccountIndexPage'")
      .row
        nav(:aria-label='$t("YouAreHere") + ":"', role='navigation').breadcrumb.col-12.col-lg-10.mx-auto.my-3.my-md-0
          span.breadcrumb-item
            router-link(:to="{name: 'AccountIndexPage'}", :title='$t("YourAccount")') {{$t("YourAccount")}}
          span.breadcrumb-item
            router-link(v-if="$route.name === 'OrderView'", :to="{name: 'AccountOrders'}", :title='$t("YourOrders")') {{$t("YourOrders")}}
            span(v-else).active
              | {{$t(breadcrumb)}}
          span.breadcrumb-item(v-if="$route.name === 'OrderView'")
            span.active
              | {{$route.params.id}}

</template>

<style lang="scss" scoped>
  @import '../../../templates/default/_Styles/mixins';

  .account-breadcrumbs {
    height: 35px;
    @include desktop {
      margin-bottom: 1rem;
    }
    .breadcrumb {
      margin: 0;
      background-color: #fff;
      .breadcrumb-item {
        font-weight: 700;
        color: #b0b0b0;
        float: left;
        font-size: .65rem;
        text-transform: uppercase;
        & + .breadcrumb-item:before {
          font-weight: 400;
        }
        a {
          color: #b0b0b0;
        }
        a:hover {
          text-decoration: underline;
        }
      }
      .active {
        color: #b0b0b0;
        font-weight: 400;
      }
    }
  }
</style>

<script>
  export default {
    name: 'AccountBreadcrumbs',
    computed: {
      breadcrumb: function () {
        return this.$route.meta.breadcrumb.nameGettext
      }
    }
  }
</script>
