import SubMenuItem from '@/components/scripts/PageHeaderSSv4/LogoLine/MainMenu/SubMenuItem/index.vue'
import { mapState } from 'vuex'

export default {
  name: 'MainMenu',
  components: {SubMenuItem},
  computed: {
    ...mapState({
      apiHost: (state) => state.apiHost,
      treeData: (state) => state.menus,
      isMobile: (state) => state.isMobile,
      currency: (state) => ({...state.currency.allCurrency[state.currency.selected]}),
      exchange: (state) => state.currency.exchange,
      calculatePrice: (state) => state.calculatePrice
    })
  },
  data () {
    return {
      requestOptions: {
        headers: {
          'X-Requested-With': 'XMLHttpRequest'
        },
        emulateJSON: true
      }
    }
  },
  methods: {
    convertMenuContext (context) {
      return this.convertContext(context)
    }
  },
  watch: {
    currency: {
      handler: function () {
        this.$forceUpdate()
      },
      deep: true
    },
    treeData (data) {
      // fix it
    }
  }
}
