import { mapState } from 'vuex'

export default {
  name: 'VerticalMenuItem',
  props: ['menu', 'target', 'target_id'],
  computed: {
    ...mapState({
      currency: (state) => ({...state.currency.allCurrency[state.currency.selected]}),
      exchange: (state) => state.currency.exchange
    })
  },
  methods: {
    closeSidebar () {
      this.$store.dispatch('closeSidebar')
    },
    convertMenuContext (context) {
      return this.convertContext(context)
    }
  },
  watch: {
    currency: {
      handler: function () {
        this.$forceUpdate()
      },
      deep: true
    }
  }
}
