import config from '@/../config'
import { createHTTPClient } from '@/utils/http'
export const loadedLanguages = [
  config.defLocale
  // 'en',
  // 'de',
  // 'cn',
  // 'ru',
  // 'ca',
  // 'au'
]

var http = createHTTPClient()

export async function createI18n (context, VueI18n) {
  const messages = context.messages || await http.get(config.gettexts.getTranslations, {}, {
    headers: {
      'X-Requested-With': 'XMLHttpRequest'
    },
    emulateJSON: true
  }).then(response => {
    return response.data
  })

  /**
   * @todo need destructor
   */
  return new VueI18n({
    locale: context.locale,
    messages
  })
}

export function getAutoLang () {
  let result = localStorage && localStorage.getItem('lang') ? localStorage.getItem('lang') : (window.navigator.userLanguage || window.navigator.language)
  if (result) {
    result = result.substr(0, 2)
  }
  if (loadedLanguages.indexOf(result) === -1) {
    result = config.defLocale
  }
  return result
}
