import Breadcrumbs from '@/components/scripts/Breadcrumbs/index.vue'
import PageBanner from '@/components/scripts/AboutUsPage/PageBanner/index.vue'
import ReviewVideos from '@/components/scripts/AboutUsPage/ReviewVideos/index.vue'
import Trustpilot from '@/components/scripts/AboutUsPage/Trustpilot/index.vue'

export default {
  name: 'AboutUsPage',
  components: {
    Breadcrumbs,
    PageBanner,
    ReviewVideos,
    Trustpilot
  }
}
