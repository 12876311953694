/* eslint no-eval: 0 */
import { createApp } from './main.js'
const { getAutoLang } = require('./utils/i18n')
const locale = getAutoLang()

createApp({locale: locale}).then(({app, store, router}) => {
  const types = require('./store/types')
  const {getDescendantProp, setNestedKey, iterate} = require('./../config/helper')

  if (window.__INITIAL_STATE__) {
    let rawState = window.__INITIAL_STATE__
    let state = {}
    if (typeof rawState === 'string') {
      const findFunctionByName = (base, name) => {
        if (base === null || typeof base !== 'object') return false
        if (base[name]) {
          return base[name]
        }
        return Object.values(base).reduce((result, item) => {
          return result || findFunctionByName(item, name)
        }, false)
      }
      let reviver = (key, value) => {
        if (typeof value === 'string' && value.indexOf('function') === 0) {
          /**
           * @todo should need add real serialize & unserialize for Function without minifying!
           */
          return findFunctionByName(store.state, key)
        }
        return value
      }
      state = JSON.parse(rawState, reviver)
    } else {
      state = rawState
    }
    let keys = []
    iterate(keys, state, state, '')
    if (keys) {
      keys.map(key => {
        setNestedKey(state, key.split('.'), getDescendantProp(store.state, key))
      })
      keys.map(key => {
        setNestedKey(state, key.split('.'), getDescendantProp(state, key).bind(state))
      })
    }
    store.replaceState(state)
    store.commit(types.RESET_STATE, state)
  }
// this assumes App.vue template root element has `id="app"`
  router.onReady(() => {
    app.$mount('#app') // hydrate = true if need force hydration
  })
})

