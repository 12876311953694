import CatalogueTitle from '@/components/scripts/Products/CatalogueTitle/index.vue'
import Sort from '@/components/scripts/Products/Sort/index.vue'
import Filters from '@/components/scripts/Products/Filters/index.vue'
import Items from '@/components/scripts/Products/Items/index.vue'
import FilterList from '@/components/scripts/Products/FilterList/index.vue'
import FilterBlock from '@/components/scripts/Products/FilterBlock/index.vue'
import FilterBlockMobile from '@/components/scripts/Products/FilterBlockMobile/index.vue'
import Pagination from '@/components/scripts/Pagination/index.vue'
import Breadcrumbs from '@/components/scripts/Breadcrumbs/index.vue'

import { mapState } from 'vuex'
import config from '@/../config'
import catalogueInit from '../../../store/catalogue'
import {
  parseQueryString,
  reverseRouteName,
  arrayIntersect,
  getSearchString,
  arrayToObject
} from '@/../config/helper'
import * as auth from '../../../store/authorization'
import $ from 'jquery'

let elasticsearch = require('elasticsearch')
const qs = require('qs')

export default {
  name: 'products',
  components: {
    CatalogueTitle,
    Sort,
    Filters,
    Items,
    FilterList,
    FilterBlock,
    FilterBlockMobile,
    Pagination,
    Breadcrumbs
  },
  computed: {
    ...mapState({
      apiHost: (state) => state.apiHost,
      breadcrumbs: (state) => state.breadcrumbs,
      routerObj: (state) => state.pageMenuDescription,
      currency: (state) => ({...state.currency.allCurrency[state.currency.selected]}),
      exchange: (state) => state.currency.exchange,
      exchangeBack: (state) => state.currency.exchangeBack,
      backRoute: (state) => state.backRoute,
      calculatePrice: (state) => state.calculatePrice,
      catalogue: (state) => state.promiseData && state.promiseData.catalogue ? state.promiseData.catalogue : {},
      itemsLoading: (state) => state.itemsLoading,
      designersStore: (state) => state.allDesigners,
      categoriesStore: (state) => state.allCategories
    }),
    description: function () {
      let description = ''
      let routerObj = this.routerObj
      if (routerObj.page && routerObj.page.translations && Object.keys(routerObj.page.translations).length > 0) {
        description = this.$tf(routerObj.page.translations, 'description')
      }
      if (routerObj.catalogue && routerObj.catalogue.translations && Object.keys(routerObj.catalogue.translations).length > 0) {
        description = this.$tf(routerObj.catalogue.translations, 'description')
      }
      return description
    },
    isHistoryAvailable: function () {
      return !!(window.history && window.history.pushState)
    },
    calculatedItems: function () {
      return {
        ...this.items,
        priceFrom: (this.items.priceFrom && this.items.priceFrom.length > 0 ? parseInt(this.convertPrice(this.items.priceFrom)) : ''),
        priceTo: (this.items.priceTo && this.items.priceTo.length > 0 ? parseInt(this.convertPrice(this.items.priceTo)) : ''),
        data: this.items.data.map(item => {
          return {
            ...item,
            price: this.convertPrice(item.price),
            price_old: this.convertPrice(item.price_old)
          }
        })
      }
    },
    showLoader: function () {
      let show = this.loading
      if (this.itemsLoading !== undefined) {
        if (this.mounted) {
          show = this.loading
        } else {
          show = this.itemsLoading
        }
      }
      return show
    },
    categoryObj: function () {
      if (this.allCategories) {
        let category = this.category ? this.category : this.$route.path.split('/').pop()
        let routeCategory = Object.values(this.allCategories).find(obj => category === reverseRouteName(obj.category_slug))
        return routeCategory !== undefined ? routeCategory : null
      }
      return null
    },
    designerObj: function () {
      if (this.allDesigners) {
        let designer = this.designers ? this.designers : this.$route.path.split('/').pop()
        let routeDesigner = Object.values(this.allDesigners).find(obj => designer === reverseRouteName(obj.name))
        return routeDesigner !== undefined ? routeDesigner : null
      }
      return null
    },
    designer_name: function () {
      return this.designerObj ? this.designerObj.name : ''
    },
    isBrandPage: function () {
      let hasBrand = false
      if (this.routerObj.catalogue && this.routerObj.catalogue.filters) {
        hasBrand = Object.values(this.routerObj.catalogue.filters).filter(obj => obj.designer && obj.designer > 0).length > 0
      }
      return this.designerObj !== null || hasBrand
    },
    category_name: function () {
      return this.categoryObj ? this.categoryObj.name : ''
    },
    definedMainCategory: function () {
      return this.main_category_name !== null && this.main_category_link !== null
    }
  },
  props: ['page', 'category_id', 'designer_id', 'main_category', 'search_term', 'category', 'designers'],
  head: {
    title: function () {
      let title = 'SelectSpecs'
      if (this.routerObj.page && this.routerObj.page.translations && Object.keys(this.routerObj.page.translations).length > 0) {
        title = this.$tf(this.routerObj.page.translations, 'meta_title')
      }
      if (this.routerObj.catalogue && this.routerObj.catalogue.translations && Object.keys(this.routerObj.catalogue.translations).length > 0) {
        title = this.$tf(this.routerObj.catalogue.translations, 'meta_title')
      }

      if (this.designerDescription && this.designerDescription.meta_title) {
        title = this.designerDescription.meta_title
      }
      title = this.convertMenuContext(title)
      const textarea = document.createElement('div')
      textarea.innerHTML = title
      title = textarea.innerText ? textarea.innerText : (textarea.textContent ? textarea.textContent : textarea.innerHTML)
      return {
        inner: title
      }
    },
    link: function () {
      let link = this.navigation.protocol + '//' + this.navigation.host + this.navigation.pathname
      let links = [
        {
          rel: 'canonical',
          href: link
        },
        {
          rel: 'next',
          href: link + '?page=' + (parseInt(this.pagination.currentPage) + 1),
          id: 'next'
        }
      ]
      if (parseInt(this.pagination.currentPage) > 1) {
        links.push({
          rel: 'prev',
          href: link + '?page=' + (parseInt(this.pagination.currentPage) - 1),
          id: 'prev'
        })
      }
      return links
    },
    meta: function () {
      let description = ''
      let keywords = ''
      let noindex = 0
      if (this.routerObj.page) {
        if (this.routerObj.page.translations && Object.keys(this.routerObj.page.translations).length > 0) {
          description = this.$tf(this.routerObj.page.translations, 'meta_description')
          keywords = this.$tf(this.routerObj.page.translations, 'meta_keywords')
        }
        noindex = this.routerObj.page.noindex
      }
      if (this.routerObj.catalogue && this.routerObj.catalogue.translations && Object.keys(this.routerObj.catalogue.translations).length > 0) {
        description = this.$tf(this.routerObj.catalogue.translations, 'meta_description')
        keywords = this.$tf(this.routerObj.catalogue.translations, 'meta_keywords')
      }

      if (Object.keys(this.designerDescription).length > 0) {
        description = this.designerDescription.meta_description
        keywords = this.designerDescription.meta_keywords
      }

      return [
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1, user-scalable=no'
        },
        {
          hid: 'description',
          name: 'description',
          content: description !== null && description.length > 0 ? this.convertMenuContext(description) : ''
        },
        keywords !== null && keywords.length > 0 ? {
          hid: 'keywords',
          name: 'keywords',
          content: keywords
        } : {},
        noindex ? {
          name: 'robots',
          content: 'noindex,follow'
        } : {}
      ]
    }
  },
  data () {
    let copyCatalogueInit = JSON.stringify(catalogueInit)
    return {
      client: null,
      mounted: false,
      layout: false,
      loading: false,
      requestOptions: {
        headers: {
          'X-Requested-With': 'XMLHttpRequest'
        },
        emulateJSON: true
      },
      ...JSON.parse(copyCatalogueInit),
      navigation: {
        location: window.location.href,
        protocol: window.location.protocol,
        host: window.location.host,
        pathname: window.location.pathname,
        search: window.location.search,
        hash: window.location.hash
      },
      searchTitle: '',
      filterCountItems: {},
      scrollLoad: false,
      main_category_name: null,
      main_category_link: null,
      allDesigners: [],
      allCategories: [],
      oldFilters: '',
      oldRouter: ''
    }
  },
  asyncData ({store, router}) {
    let copyCatalogueInit = JSON.stringify(catalogueInit)
    return Promise.all([
      store.dispatch('loadDesigners'),
      store.dispatch('loadCategories')
    ]).then(() => Promise.all([
      store.dispatch('setRouteCategoryUrl'),
      store.dispatch('setPromiseData', {catalogue: JSON.parse(copyCatalogueInit)}),
      store.dispatch('loadCatalogue', {
        onlyItems: 0,
        onlyFilters: 1,
        router
      }),
      store.dispatch('loadCatalogue', {
        onlyItems: 1,
        onlyFilters: 0,
        router
      })
    ])).then(() => Promise.all([
      store.dispatch('getDescriptionForBrandPage')
    ]))
  },
  mounted () {
    this.mounted = true
  },
  created () {
    if (this.routerObj && this.routerObj.categoryPath) {
      this.main_category_link = this.routerObj.categoryPath
    }

    if (this.routerObj && this.routerObj.categoryName) {
      this.main_category_name = this.routerObj.categoryName
    }

    if (this.page) {
      this.layout = this.page
    } else {
      this.layout = false
    }

    this.searchTitle = ''
    if (this.search_term) {
      this.searchTitle = this.$trans('SearchResultsFor') + ' "' + decodeURIComponent(this.search_term) + '"'
    }

    let self = this
    if (this.catalogue) {
      Object.keys(this.catalogue).map(key => {
        if (self.hasOwnProperty(key)) {
          if (typeof self.catalogue[key] === 'object' && typeof self[key] === 'object' && Object.keys(self[key]).length > 0) {
            Object.keys(self.catalogue[key]).map(k => {
              if (self[key].hasOwnProperty(k)) {
                self[key][k] = self.catalogue[key][k]
              } else {
                this.$set(self[key], k, self.catalogue[key][k])
              }
            })
          } else {
            self[key] = self.catalogue[key]
          }
        }
      })
    }

    this.loadAllCategories()
    this.loadAllDesigners()

    if (this.$route.query.page) {
      this.pagination.currentPage = this.$route.query.page
      this.pagination.skip += (this.pagination.currentPage - 1) * this.pagination.limit
    }

    let _search = this.navigation.search.replace(/^\?/, '')
    if (!self.isHistoryAvailable) {
      _search = self.navigation.hash.replace(/^#/, '')
    }

    if (_search.length > 0) {
      let params = self.parseQueryString(_search)
      if (Object.keys(params).indexOf('page') !== -1) {
        delete params.page
      }
      Object.keys(params).map(key => {
        if (Object.keys(self.filters).indexOf(key) === -1) {
          self.$set(self.filters, key, params[key])
        } else {
          self.filters[key] = params[key]
        }
      })
    }

    this.client = new elasticsearch.Client({
      host: {
        host: config.elastic.host,
        protocol: config.elastic.protocol,
        port: config.elastic.port,
        path: 'elastic'
      }
    })

    /*
    if (!this.layout) {
      if (this.items.total === 0) {
        this.loadComponents(0, 1)
      }
      if (Object.keys(this.filtersList).length === 0) {
        this.loadComponents(1).then(() => {
          self.calculateFilterCountItems()
        })
      } else {
        this.calculateFilterCountItems()
      }
      this.generateBreadcrumbs()
    }
    */

    document.addEventListener('scroll', this.handleScroll)
  },
  destroyed: function () {
    this.$store.dispatch('clearPromiseDataKey', 'catalogue')
    document.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    loadAllCategories () {
      if (Object.keys(this.categoriesStore).length > 0) {
        this.allCategories = this.categoriesStore
      } else {
        let self = this
        return this.$axios.get(this.apiHost + config.prefix + config.categories.allCategories, {params: {lang: this.lang}}, this.requestOptions)
          .then(response => {
            self.allCategories = response.data
          })
      }
    },
    loadAllDesigners () {
      if (Object.keys(this.designersStore).length > 0) {
        this.allDesigners = this.designersStore
      } else {
        let self = this
        return this.$axios.get(this.apiHost + config.prefix + config.designers.allDesigners, {params: {lang: this.lang}}, this.requestOptions)
          .then(response => {
            self.allDesigners = response.data
          })
      }
    },
    convertMenuContext (context) {
      return this.convertContext(context)
    },
    getDescriptionForBrandPage: function () {
      if (this.isBrandPage) {
        let filters = []
        if (this.routerObj.catalogue && Object.keys(this.routerObj.catalogue.filters).length > 0) {
          filters = Object.values(this.routerObj.catalogue.filters)
        }
        let self = this
        let data = {
          filters: filters,
          designer_lang: this.lang
        }
        if (this.designerObj) {
          data.designer_number = this.designerObj.id
        }
        let requestId = 'brandsDescriptionRequest'
        let requestOpt = {
          requestId: requestId
        }
        return this.$axios.post(this.apiHost + config.prefix + config.products.getDesignerDescription, qs.stringify(data), Object.assign({}, this.requestOptions, requestOpt))
          .then(r => self.$store.dispatch(auth.AUTH_ACTION_SUCCESS, r), e => self.$store.dispatch(auth.AUTH_ACTION_ERROR, e))
          .then(response => response.data)
          .then(json => {
            self.designerDescription = json
            self.$emit('updateHead')
          })
          .catch(res => {
            return res
          })
      }
    },
    getSearchString (filter, withSign = true) {
      return getSearchString(filter, withSign)
    },
    loadComponents: function (onlyFilters = 0, onlyItems = 0, append = false) {
      if (onlyItems && (!this.currency || !this.currency.rate)) {
        return
      }

      if (!append && onlyItems) {
        this.loading = true
      }
      let self = this
      self.searchTitle = ''
      let data = {
        ...this.filters,
        lang: self.lang,
        skip: self.pagination.skip,
        limit: self.pagination.limit,
        onlyFilters: onlyFilters,
        onlyItems: onlyItems,
        price_from: this.filters.price_from || this.filters.price_from.length > 0 ? this.convertPriceBack(this.filters.price_from) : '',
        price_to: this.filters.price_to || this.filters.price_to.length > 0 ? this.convertPriceBack(this.filters.price_to) : ''
      }
      if (this.search_term) {
        self.searchTitle = this.$trans('SearchResultsFor') + ' "' + decodeURIComponent(this.search_term) + '"'
        Object.assign(data, {q: this.search_term})
      }

      if (this.designerObj) {
        Object.assign(data, {designer_id: this.designerObj.id})
      }

      if (this.routerObj.catalogue) {
        if (Object.keys(this.routerObj.catalogue.filters).length > 0) {
          Object.assign(data, {filters: Object.values(this.routerObj.catalogue.filters)})
        }
        Object.assign(data, {filter_groups: this.routerObj.catalogue.filter_groups})
      }

      let requestId = onlyItems ? 'itemsRequest' : 'filtersRequest'
      let requestOpt = {
        requestId: requestId
      }

      return this.$axios.post(this.apiHost + config.prefix + config.products.searchProducts, qs.stringify(data), Object.assign({}, requestOpt, this.requestOptions))
        .then(r => self.$store.dispatch(auth.AUTH_ACTION_SUCCESS, r), e => self.$store.dispatch(auth.AUTH_ACTION_ERROR, e))
        .then(response => response.data)
        .then(json => {
          if (json.filters) {
            json.filters.map(key => {
              if (Object.keys(self.filters).indexOf(key) === -1) {
                this.$set(self.filters, key, [])
              }
            })
          }
          if ((onlyFilters || (!onlyItems && !onlyFilters)) && json.categories) {
            self.filtersList = json.categories
          }

          if (json.items) {
            if (append) {
              self.items.data = self.items.data.concat(json.items.items)
            } else {
              self.items.data = json.items.items
            }
            self.items.total = json.items.total
            if (self.pagination.skip > self.items.total) {
              self.pagination.skip = 0
              self.pagination.currentPage = 1
            }
            if (self.search_term && json.items.total === 1) {
              let oneItem = json.items.items[0]
              self.$router.push({
                name: 'ItemPage',
                params: {
                  item: parseFloat(oneItem.id / 100).toFixed(2),
                  designer: reverseRouteName(oneItem.designer_name),
                  model: reverseRouteName(oneItem.model_name),
                  lang: self.lang
                }
              })
            }
            if (json.items.aggregate) {
              let aggregate = json.items.aggregate
              self.items.priceFrom = aggregate.min_price >= 0 ? aggregate.min_price : ''
              self.items.priceTo = aggregate.max_price >= 0 ? aggregate.max_price : ''

              self.items.armFrom = aggregate.min_arm >= 0 ? parseInt(aggregate.min_arm) : ''
              self.items.armTo = aggregate.max_arm >= 0 ? parseInt(aggregate.max_arm) : ''

              self.items.bridgeFrom = aggregate.min_bridge >= 0 ? parseInt(aggregate.min_bridge) : ''
              self.items.bridgeTo = aggregate.max_bridge >= 0 ? parseInt(aggregate.max_bridge) : ''

              self.items.lensFrom = aggregate.min_lens >= 0 ? parseInt(aggregate.min_lens) : ''
              self.items.lensTo = aggregate.max_lens >= 0 ? parseInt(aggregate.max_lens) : ''
            }
          }
        })
        .then(() => {
          if (self.items.total === 0 && (self.filters.price_from || self.filters.price_to)) {
            self.findRangePrice()
          }
          if (!append && onlyItems) {
            self.$nextTick(() => {
              self.loading = false
            })
          }
        })
        .catch(res => {
          /* if (!append && onlyItems) {
            self.loading = false
          } */
        })
    },
    generateBreadcrumbs: function () {
      let self = this
      let breadcrumbs = [
        {title: this.$t('Home'), path: '/'}
      ]
      if (this.designerObj) {
        breadcrumbs = breadcrumbs.concat([
          {title: this.$t('Designers'), path: {name: 'BrandList_' + this.lang}},
          {title: this.designerObj.name, path: this.$route.path}
        ])
        if (this.main_category_name) {
          breadcrumbs.push({
            title: this.main_category_name,
            path: '#'
          })
        }
      } else if (this.categoryObj) {
        if (this.definedMainCategory) {
          breadcrumbs.push({
            title: this.main_category_name,
            path: '/' + this.main_category_link
          })
        }
        breadcrumbs.push({
          title: this.categoryObj.name,
          path: '#'
        })
      }
      this.$store.dispatch('setBreadcrumbs', breadcrumbs)
      if (!this.definedMainCategory && this.routerObj.catalogue && this.routerObj.catalogue.main_category && this.routerObj.catalogue.main_category.category_number) {
        return this.$axios.get(this.apiHost + config.prefix + config.routes.findByCategory + '/' + this.routerObj.catalogue.main_category.category_number, {params: {lang: this.lang}}, this.requestOptions)
          .then(r => self.$store.dispatch(auth.AUTH_ACTION_SUCCESS, r), e => self.$store.dispatch(auth.AUTH_ACTION_ERROR, e))
          .then(response => response.data)
          .then(json => {
            if (json.url) {
              self.main_category_link = json.url
              let breadcrumbs = [
                {title: self.$t('Home'), path: '/'}
              ]
              if (self.designerObj) {
                breadcrumbs = breadcrumbs.concat([
                  {title: self.$t('Designers'), path: {name: 'BrandList_' + self.lang}},
                  {title: self.designerObj.name, path: self.$route.path}
                ])
              }
              if (json.category && json.category.category_name) {
                let categoryName = self.$tf(json.category.translations, 'category_name')
                if (!categoryName) {
                  categoryName = json.category.category_name
                }
                self.main_category_name = categoryName
                breadcrumbs.push({title: categoryName, path: '/' + json.url})
              }
              if (self.categoryObj) {
                breadcrumbs.push({title: self.categoryObj.name, path: '#'})
              }
              self.$store.dispatch('setBreadcrumbs', breadcrumbs)
            }
          })
      }
    },
    changeNavigation: function () {
      if (!this.mounted) return true
      let self = this
      let params = {}
      Object.keys(self.filters).map((key) => {
        if (self.filters[key].length > 0 || self.filters[key]) {
          Object.assign(params, {[decodeURIComponent(key)]: self.filters[key]})
        }
      })
      Object.assign(this.$route.query, params)

      let _hash = self.navigation.hash
      let _search = this.getSearchString(params)
      let sign = _search.length > 0 ? '&' : '?'
      let query = this.getSearchString(this.$root.search.params, false)
      _search += query.length > 0 ? sign + query : ''

      if (this.$route.query.page) {
        sign = _search.length > 0 ? '&' : '?'
        _search += sign + 'page=' + this.$route.query.page
      }

      let location = self.navigation.protocol + '//' + self.navigation.host + self.navigation.pathname

      if (self.isHistoryAvailable) {
        location += _search + _hash
        window.history.pushState({}, null, location)
      } else {
        _hash = '#' + _search.replace(/^\?/, '')
        _search = self.navigation.search
        location += _search
        window.location.hash = _hash
      }

      self.navigation.location = location
      self.navigation.search = _search
      self.navigation.hash = _hash
    },
    parseQueryString: function (queryString) {
      return parseQueryString(queryString)
    },
    handleScroll () {
      let self = this
      let el = document.querySelector('.shop-item:last-child')
      if (el && !this.loading && !this.scrollLoad && $('HTML, BODY').scrollTop() >= el.offsetTop - el.offsetHeight) {
        if (this.pagination.skip + this.pagination.limit < this.items.total) {
          this.pagination.skip += this.pagination.limit
          self.scrollLoad = true
          this.loadComponents(0, 1, true).then(() => {
            self.scrollLoad = false
          })
        }
      }
    },
    reverseRouteName (name) {
      return reverseRouteName(name)
    },
    findRangePrice () {
      let data = {
        ...this.filters,
        price_from: this.filters.price_from || this.filters.price_from.length > 0 ? this.convertPriceBack(this.filters.price_from) : '',
        price_to: this.filters.price_to || this.filters.price_to.length > 0 ? this.convertPriceBack(this.filters.price_to) : ''
      }

      if (this.designerObj) {
        Object.assign(data, {designer_id: this.designerObj.id})
      }

      if (this.routerObj.catalogue) {
        if (Object.keys(this.routerObj.catalogue.filters).length > 0) {
          Object.assign(data, {filters: Object.values(this.routerObj.catalogue.filters)})
        }
      }

      let self = this
      let requestId = 'rangePriceRequest'
      let requestOpt = {
        requestId: requestId
      }

      return this.$axios.post(this.apiHost + config.prefix + config.products.getRangePrice, qs.stringify(data), Object.assign({}, requestOpt, this.requestOptions))
        .then(response => response.data)
        .then(json => {
          if (json.min_price) {
            let minPrice = parseInt(this.convertPrice(json.min_price))
            if (minPrice == self.filters.price_from) {
              json.min_price = parseFloat(json.min_price) - 1
              minPrice = parseInt(this.convertPrice(json.min_price))
            }
            self.filters.price_from = minPrice.toString()
          }
          if (json.max_price) {
            let maxPrice = parseInt(this.convertPrice(json.max_price))
            if (maxPrice == self.filters.price_to) {
              json.max_price = parseFloat(json.max_price) + 1
              maxPrice = Math.ceil(this.convertPrice(json.max_price))
            }
            self.filters.price_to = maxPrice.toString()
          }
        })
        .catch(res => {
          return res
        })
    },
    convertPrice (price) {
      try {
        return this.calculatePrice(price)
      } catch (e) {
        return this.exchange(price)
      }
    },
    convertPriceBack (price) {
      try {
        return this.calculatePrice(price, true)
      } catch (e) {
        return this.exchangeBack(price)
      }
    },
    calculateFilterCountItems () {
      let queryMust = []
      let queryDesignerMust = []
      let ranges = {}
      let self = this
      let designerPage = false

      if (this.search_term) {
        queryMust.push({
          match: {
            _all: {
              query: this.search_term,
              operator: 'and'
            }
          }
        })
      }
      if (this.routerObj.catalogue && Object.keys(this.routerObj.catalogue.filters).length > 0) {
        let queryShould = []
        let queryDesignerShould = []
        Object.values(this.routerObj.catalogue.filters).map(obj => {
          let must = []
          Object.values(obj.categories).map(cat => {
            must.push({
              terms: {
                category_ids: [cat]
              }
            })
          })

          queryDesignerShould.push({
            bool: {
              must: must.slice(0)
            }
          })

          if (obj.designer && obj.designer > 0) {
            must.push({
              terms: {
                designer_ids: [obj.designer]
              }
            })
          }
          queryShould.push({
            bool: {
              must: must
            }
          })
        })

        queryDesignerMust.push({
          bool: {
            should: queryDesignerShould
          }
        })

        queryMust.push({
          bool: {
            should: queryShould
          }
        })
      }

      if (this.designerObj) {
        designerPage = true
        queryMust.push({
          terms: {
            designer_ids: !Array.isArray(this.designerObj.id) ? [this.designerObj.id] : this.designerObj.id
          }
        })
      }

      if (this.filters) {
        let filters = this.filters
        Object.keys(this.filters).map(key => {
          if (filters[key].length > 0 && key !== 'sort') {
            if (key.search(/_from$/i) !== -1) {
              let bodyKey = key.replace('_from', '')
              let val = filters[key]
              if (bodyKey === 'price') {
                val = this.convertPriceBack(val)
              }
              if (ranges[bodyKey]) {
                ranges[bodyKey] = Object.assign({}, ranges[bodyKey], {gte: parseInt(val)})
              } else {
                ranges[bodyKey] = {gte: parseInt(val)}
              }
            } else if (key.search(/_to$/i) !== -1) {
              let bodyKey = key.replace('_to', '')
              let val = filters[key]
              if (bodyKey === 'price') {
                val = this.convertPriceBack(val)
              }
              if (ranges[bodyKey]) {
                ranges[bodyKey] = Object.assign({}, ranges[bodyKey], {lte: Math.ceil(val)})
              } else {
                ranges[bodyKey] = {lte: Math.ceil(val)}
              }
            } else if (key.search(/\[\d+\]$/i) !== -1) {
              queryMust.push({
                terms: {
                  [key.replace(/\[\d+\]$/i, '')]: !Array.isArray(filters[key]) ? [filters[key]] : filters[key]
                }
              })
            } else {
              queryMust.push({
                terms: {
                  [key]: !Array.isArray(filters[key]) ? [filters[key]] : filters[key]
                }
              })
            }
          }
        })

        if (Object.keys(ranges).length > 0) {
          Object.keys(ranges).map(key => {
            queryMust.push({
              range: {
                [key]: ranges[key]
              }
            })
          })
        }
      }
      if (queryMust.length > 0 && Object.keys(this.filtersList).length > 0) {
        let aggs = {}
        Object.keys(this.filtersList).map(name => {
          let key = name
          let ownName = name.replace('[', '').replace(']', '')
          if (key.search(/\[\d+\]$/i) !== -1) {
            key = key.replace(/\[\d+\]$/i, '')
          }
          let own = arrayIntersect(Object.values(self.filtersList[name].value), self.filters[name])
          if (own.length !== 0) {
            let queryMustCopy = queryMust.slice(0)
            let findIndex = queryMustCopy.findIndex((obj) => obj.terms && obj.terms[key] && obj.terms[key].filter(val => !!own.find(el => el.id === val)).length === own.length)
            queryMustCopy.splice(findIndex, 1)
            aggs = Object.assign({}, aggs, {
              ['own_' + ownName]: {
                filters: {
                  filters: {
                    query: {
                      bool: {
                        must: queryMustCopy
                      }
                    }
                  }
                },
                aggs: {
                  ['grouped_own_' + ownName]: {
                    terms: {
                      field: key,
                      size: 10000000
                    }
                  }
                }
              }
            })
          }
          let queryMustCopy = queryMust.slice(0)
          if (designerPage && key.indexOf('designer') !== -1) {
            queryMustCopy = queryDesignerMust
          }
          if (!aggs[key]) {
            aggs = Object.assign({}, aggs, {
              [key]: {
                filters: {
                  filters: {
                    query: {
                      bool: {
                        must: queryMustCopy
                      }
                    }
                  }
                },
                aggs: {
                  ['grouped_' + key]: {
                    terms: {
                      field: key,
                      size: 10000000
                    }
                  }
                }
              }
            })
          }
        })
        let body = {
          size: 0,
          aggs: aggs
        }

        return this.client.search({
          index: config.elastic.index,
          type: config.elastic.type,
          body: body
        })
          .then(function (body) {
            let grouped = body.aggregations

            self.filterCountItems = {}
            if (grouped) {
              Object.keys(grouped).map(key => {
                let queryKey = 'grouped_' + key
                grouped[key].buckets.query[queryKey].buckets.map((obj) => {
                  self.filterCountItems[key] = Object.assign({}, self.filterCountItems[key], {[obj.key]: obj.doc_count})
                })
              })
            }

            Object.keys(self.filtersList).map(key => {
              let index = Object.keys(self.filterCountItems).filter(category => key.search(category) !== -1)
              if (index && self.filterCountItems[index]) {
                let ownName = key.replace('[', '').replace(']', '')
                if (arrayIntersect(Object.values(self.filtersList[key].value), self.filters[key]).length !== 0 && self.filterCountItems['own_' + ownName]) {
                  Object.keys(self.filtersList[key].value).map((idx) => {
                    let countItems = 0
                    let obj = self.filtersList[key].value[idx]
                    if (self.filterCountItems['own_' + ownName][obj.id]) {
                      countItems = self.filterCountItems['own_' + ownName][obj.id]
                    }
                    self.filtersList[key].value[idx] = Object.assign({}, obj, {count_items: countItems})
                  })
                } else {
                  if (Array.isArray(self.filtersList[key])) {
                    let filter = self.filtersList[key]
                    Object.keys(filter).map(idx => {
                      if (Array.isArray(filter[idx].value)) {
                        filter[idx].value = arrayToObject(filter[idx].value)
                      }
                      Object.keys(filter[idx].value).map((indx) => {
                        let countItems = 0
                        let obj = self.filtersList[key][idx].value[indx]
                        if (self.filterCountItems[index][obj.id]) {
                          countItems = self.filterCountItems[index][obj.id]
                        }
                        self.filtersList[key][idx]['value'][indx] = Object.assign({}, obj, {count_items: countItems})
                      })
                    })
                  } else {
                    if (Array.isArray(self.filtersList[key].value)) {
                      self.filtersList[key].value = arrayToObject(self.filtersList[key].value)
                    }
                    Object.keys(self.filtersList[key].value).map((idx) => {
                      let countItems = 0
                      let obj = self.filtersList[key].value[idx]
                      if (self.filterCountItems[index][obj.id]) {
                        countItems = self.filterCountItems[index][obj.id]
                      }
                      self.filtersList[key].value[idx] = Object.assign({}, obj, {count_items: countItems})
                    })
                  }
                }
              }
            })
          }, function (error) {
            console.log('calculateFilterCountItems error:::', error)
          })
      }
    }
  },
  watch: {
    '$root.search': {
      handler: function () {
        this.changeNavigation()
        this.calculateFilterCountItems()
        this.loadComponents(0, 1)
      },
      deep: true
    },
    'filters': {
      handler: function (data) {
        if (!data || JSON.stringify(data) === this.oldFilters) {
          return true
        }
        this.oldFilters = JSON.stringify(data)
        if (!this.layout) {
          this.pagination.skip = 0
          this.changeNavigation()
          this.loadComponents(0, 1).then(() => { this.calculateFilterCountItems() })
        }
      },
      deep: true
    },
    'routerObj': {
      handler: function (data) {
        if (!data || JSON.stringify(data) === this.oldRouter) {
          return true
        }
        this.oldRouter = JSON.stringify(data)
        if (!this.page) {
          this.main_category_link = null
          this.main_category_name = null
          const promise = this.generateBreadcrumbs() || Promise.resolve(true)
          promise.then(() => {
            this.loadComponents(1)
            this.loadComponents(0, 1).then(() => { this.calculateFilterCountItems() })
            this.getDescriptionForBrandPage()
          })
        }
      },
      deep: true
    },
    '$route' (to, from) {
      $('HTML, BODY').scrollTop()
      this.navigation = {
        location: window.location.href,
        protocol: window.location.protocol,
        host: window.location.host,
        pathname: window.location.pathname,
        search: window.location.search,
        hash: window.location.hash
      }
      if (this.page) {
        this.layout = this.page
      } else {
        this.layout = false
      }
      if (!this.$route.query.page) {
        let copyCatalogueInit = JSON.stringify(catalogueInit)
        let filters = JSON.parse(copyCatalogueInit).filters
        Object.keys(this.filtersList).map(key => {
          Object.assign(filters, {[key]: []})
        })
        this.filters = filters
        this.designerDescription = {}
        this.pagination = JSON.parse(copyCatalogueInit).pagination
      }
    }
  }
}
