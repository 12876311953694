import pagination from './pagination'

export default {
  items: {
    data: [],
    total: 0,
    priceFrom: '',
    priceTo: '',
    armFrom: '',
    armTo: '',
    bridgeFrom: '',
    bridgeTo: '',
    lensFrom: '',
    lensTo: ''
  },
  pagination: {
    ...pagination
  },
  filters: {
    price_from: '',
    price_to: '',
    arm_from: '',
    arm_to: '',
    bridge_from: '',
    bridge_to: '',
    lens_from: '',
    lens_to: '',
    sort: ''
  },
  filtersList: {},
  designerDescription: {}
}
