<template lang="pug">
  .row
    .col-12
      .container.tracking-stage(v-for="{code, date, status} in calculatedStages")
        .row.align-items-center
          .col
            p.small
              strong {{status}}
          .col-2.text-right
            p(style="height: 25px;").small
              i(v-if="date").fa.fa-2x.fa-check-circle.light-green
          .col-3.col-sm-2.col-lg-3.text-right.pl-0
            p(v-if="date").small {{moment(date).format('Do MMM YYYY')}}


</template>

<style lang="scss" scoped>
  .tracking-stage {
    background-color: #f6f6f6;
    padding-top: .75rem;
    padding-bottom: .75rem;
    margin-bottom: .25rem;
    .fa-2x {
      vertical-align: middle;
    }
    .light-green {
      color: #a7d163;
    }
  }
</style>

<script>
  import moment from 'moment'

  export default {
    name: 'TrackingList',
    data () {
      return {
        trackingStagesDecode: {
          'pf': 'Preparing frames',
          'ol': 'Preparing lenses',
          'lrg': 'Lenses ready for glazing',
          'frg': 'Frames ready for glazing',
          'egl': 'Entered glazing lab',
          'eqc': 'Entered quality control',
          'd': 'Dispatched'
        }
      }
    },
    computed: {
      calculatedStages: function () {
        let mockQuery = [
          {
            code: 'ol',
            date: new Date(2018, 11, 24)
          },
          {
            code: 'lrg',
            date: new Date(2018, 11, 29)
          },
          {
            code: 'pf',
            date: new Date(2019, 0, 5)
          },
          {
            code: 'egl',
            date: null
          }
        ]
        return [
          ...mockQuery
            .map(obj => ({
              ...obj,
              status: this.trackingStagesDecode[obj.code]
            }))

        ].sort((a, b) => a.date > b.date)
      }
    },
    methods: {
      moment
    }
  }
</script>
