import { mapState } from 'vuex'
import { queryObject } from '@/../config/helper'
export default {
  name: 'Filters',
  data () {
    return {
      selected: {},
      mounted: false
    }
  },
  computed: {
    ...mapState({
      catalogue: (state) => state.promiseData && state.promiseData.catalogue ? state.promiseData.catalogue : {}
    })
  },
  mounted () {
    this.mounted = true // is not SSR
  },
  created () {
    if (this.catalogue) {
      let self = this
      self.selected = {}
      this.fillSelectedFilters(this.catalogue.filtersList)
    }
  },
  methods: {
    clearOne: function (filterName, value) {
      let index = this.$parent.filters[filterName].findIndex(val => val == value)
      this.$parent.filters[filterName].splice(index, 1)
    },
    setFilterValues (obj) {
      if (this.$parent.filters[obj.name]) {
        let values = []
        this.$parent.filters[obj.name].map(filter => {
          let value = Object.values(obj.value).find(_obj => _obj.id == filter)
          if (value) {
            values.push(value)
          }
        })
        if (values && Object.values(values).length > 0) {
          if (!this.selected[obj.name]) {
            this.selected[obj.name] = []
          }
          this.selected[obj.name] = this.selected[obj.name].concat(values)
        }
      }
    },
    fillSelectedFilters (filtersList) {
      let self = this
      self.selected = {}
      if (filtersList) {
        Object.values(filtersList).map(obj => {
          if (Array.isArray(obj)) {
            obj.map(filter => {
              self.setFilterValues(filter)
            })
          } else {
            self.setFilterValues(obj)
          }
        })
      }
    },
    routeLink (key, value) {
      if (this.mounted) {
        return ''
      }

      let query = JSON.stringify(this.$route.query)
      let newQuery = queryObject(JSON.parse(query), key, value)

      const {resolved} = this.$router.resolve({
        name: this.$route.name,
        query: {
          ...newQuery
        },
        params: {lang: this.lang}
      })
      return (resolved.fullPath).replace(/\/\?/g, '?')
    }
  },
  watch: {
    '$parent.filters': {
      handler: function () {
        let self = this
        self.selected = {}
        this.fillSelectedFilters(this.$parent.filtersList)
      },
      deep: true
    }
  }
}
