/* eslint-disable no-unused-vars */
/* eslint no-eval: 0 */
/* eslint-disable no-useless-escape */

export async function createRouter (Vue) {
  const Router = require('vue-router')
  const VueHead = require('vue-head')
  const { createHTTPClient } = require('./../utils/http')

  const loadView = (name) => {
    return async () => {
      const {default: component} = await import(/* webpackChunkName: "app.view." */ `./../components/scripts/${name}/index.vue`)
      return component
    }
  }

  const Login = require('./../components/scripts/LoginPage/index.vue').default
  const Logout = require('./../components/scripts/Logout/index.vue').default
  const CheapGlasses = require('./../components/scripts/Products/CheapGlasses/index.vue').default
  const Profile = require('./../components/scripts/Profile/index.vue').default
  const Settings = require('./../components/scripts/Settings/index.vue').default
  const Account = require('./../components/scripts/AccountPage/index.vue').default
  const AccountContents = require('./../components/scripts/AccountPage/AccountContents/index.vue').default
  const AccountPrescriptions = require('./../components/scripts/AccountPage/AccountPrescriptions/index.vue').default
  const AccountPrescriptionEdit = require('./../components/scripts/AccountPage/AccountPrescriptions/EditView/index.vue').default
  const AccountReviews = require('./../components/scripts/AccountPage/AccountReviews/index.vue').default
  const AccountProfile = require('./../components/scripts/AccountPage/AccountProfile/index.vue').default
  const AccountPassword = require('./../components/scripts/AccountPage/AccountPassword/index.vue').default
  const AccountOrders = require('./../components/scripts/AccountPage/AccountOrders/index.vue').default
  const OrderView = require('./../components/scripts/AccountPage/AccountOrders/OrderView/index.vue').default
  const AccountAddresses = require('./../components/scripts/AccountPage/AccountAddresses/index.vue').default
  const AccountContacts = require('./../components/scripts/AccountPage/AccountContacts/index.vue').default
  const Orders = require('./../components/scripts/Orders/index.vue').default
  const Order = require('./../components/scripts/Order/index.vue').default
  const Products = require('./../components/scripts/Products/index.vue').default
  const Prescription = require('./../components/scripts/Prescription/index.vue').default
  const SavedPrescriptions = require('./../components/scripts/SavedPrescriptions/index.vue').default
  const Basket = loadView('Basket')
  const Checkout = loadView('Checkout')
  // const Checkout = require('./../components/scripts/Checkout/index.vue').default
  const Thank = require('./../components/scripts/Checkout/Thank/index.vue').default
  const Fail = require('./../components/scripts/Checkout/Fail/index.vue').default
  const Register = require('./../components/scripts/Register/index.vue').default
  const PageItem = require('./../components/scripts/PageItem/index.vue').default
  const PageNotFound = require('./../components/scripts/PageNotFound/index.vue').default
  const Payments = require('./../components/scripts/PaymentFrame/index.vue').default
  const Main = loadView('Main')
  const Wishlist = require('./../components/scripts/Wishlist/index.vue').default
  const Review = require('./../components/scripts/Review/index.vue').default
  const ReviewsList = require('./../components/scripts/ReviewsList/index.vue').default
  const Goggles = require('./../components/scripts/Goggles/index.vue').default
  const ContactLenses = require('./../components/scripts/ContactLenses/index.vue').default
  const Glasses = require('./../components/scripts/Glasses/index.vue').default
  const Sunglasses = require('./../components/scripts/Sunglasses/index.vue').default
  const BrandList = require('./../components/scripts/BrandList/index.vue').default
  const SalePage = require('./../components/scripts/SalePage/index.vue').default
  const AboutUsPage = require('./../components/scripts/AboutUsPage/index.vue').default
  const Reglaze = require('./../components/scripts/Reglaze/index.vue').default
  const Amazon = require('./../components/scripts/Checkout/Amazon/index.vue').default
  const InfoPage = require('./../components/scripts/InfoPage/index.vue').default
  const ChooseByFaceShapePage = require('./../components/scripts/ChooseByFaceShapePage/index.vue').default
  const ProductionDeliveryPage = require('./../components/scripts/ProductionDeliveryPage/index.vue').default
  const KidsGlasses = require('./../components/scripts/KidsGlasses/index.vue').default
  const BuyingGuides = require('./../components/scripts/HelpPages/BuyingGuides/index.vue').default
  const EyeHealth = require('./../components/scripts/HelpPages/EyeHealth/index.vue').default
  const HowToGuides = require('./../components/scripts/HelpPages/HowToGuides/index.vue').default
  const GuideToLenses = require('./../components/scripts/HelpPages/GuideToLenses/index.vue').default
  const LensPrices = require('./../components/scripts/HelpPages/LensPrices/index.vue').default
  const Store = require('./../components/scripts/HelpPages/Store/index.vue').default
  // const CartSSv4 = loadView('CartSSv4')
  const config = require('./../../config')

  const baseComponents = {
    Products,
    CheapGlasses,
    Login,
    Logout,
    Profile,
    Settings,
    Account,
    Orders,
    Order,
    Prescription,
    SavedPrescriptions,
    Basket,
    Checkout,
    Thank,
    Fail,
    Register,
    PageItem,
    PageNotFound,
    Payments,
    Main,
    Wishlist,
    Review,
    ReviewsList,
    Goggles,
    ContactLenses,
    Glasses,
    Sunglasses,
    BrandList,
    SalePage,
    AboutUsPage,
    Reglaze,
    Amazon,
    InfoPage,
    ChooseByFaceShapePage,
    ProductionDeliveryPage,
    KidsGlasses,
    BuyingGuides,
    EyeHealth,
    HowToGuides,
    GuideToLenses,
    LensPrices,
    Store
    // CartSSv4
  }

  Vue.use(Router)
  Vue.use(VueHead, {
    complement: '',
    separator: ''
  })
  const langPath = config.subDomain === '/' ? '' : config.subDomain
  const router = new Router({
    mode: 'history',
    routes: [
      {
        path: langPath + '/login',
        name: 'Login',
        component: Login
      },
      {
        path: langPath + '/logout',
        name: 'Logout',
        component: Logout
      },
      {
        path: langPath + '/register',
        name: 'Register',
        component: Register,
        props: {
          breadcrumb: 'Register'
        }
      },
      {
        path: langPath + '/account',
        name: 'Account',
        component: Account,
        meta: { requiresAuth: true },
        children: [
          {
            path: 'profile',
            component: AccountContents,
            name: 'AccountIndexPage',
            meta: {
              breadcrumb: {nameGettext: 'YourAccount'}
            },
            props: {titleGettext: 'YourAccount'}
          },
          {
            path: 'orders',
            component: AccountOrders,
            name: 'AccountOrders',
            meta: {
              breadcrumb: {nameGettext: 'YourOrders'}
            },
            props: {titleGettext: 'YourOrders'}
          },
          {
            path: 'orders/:id',
            component: OrderView,
            name: 'OrderView',
            meta: {
              breadcrumb: {nameGettext: 'Order'}
            },
            props: {titleGettext: 'Order'}
          },
          {
            path: 'prescriptions',
            component: AccountPrescriptions,
            name: 'AccountPrescriptions',
            meta: {
              breadcrumb: {nameGettext: 'YourPrescriptions'}
            },
            props: {titleGettext: 'YourPrescriptions'}
          },
          {
            path: 'prescription_edit',
            component: AccountPrescriptionEdit,
            name: 'AccountPrescriptionCreate',
            meta: {
              breadcrumb: {nameGettext: 'YourPrescriptions'}
            },
            props: {titleGettext: 'YourPrescriptions'}
          },
          {
            path: 'prescription_edit/:id',
            component: AccountPrescriptionEdit,
            name: 'AccountPrescriptionEdit',
            meta: {
              breadcrumb: {nameGettext: 'YourPrescriptions'}
            },
            props: {titleGettext: 'YourPrescriptions'}
          },
          {
            path: 'reviews',
            component: AccountReviews,
            name: 'AccountReviews',
            meta: {
              breadcrumb: {nameGettext: ''}
            },
            props: {titleGettext: ''}
          },
          {
            path: 'update',
            component: Profile,
            name: 'UpdateProfile',
            meta: {
              breadcrumb: {nameGettext: 'ItemDetails'}
            },
            props: {titleGettext: 'YourDetails'}
          },
          {
            path: 'password',
            component: AccountPassword,
            name: 'UpdatePassword',
            meta: {
              breadcrumb: {nameGettext: 'ItemDetails'}
            },
            props: {titleGettext: 'YourDetails'}
          },
          {
            path: 'addresses',
            component: AccountAddresses,
            name: 'AccountAddresses',
            meta: {
              breadcrumb: {nameGettext: 'YourAddressesProfile'}
            },
            props: {titleGettext: 'YourAddressesProfile'}
          },
          {
            path: 'contact-preferences',
            component: AccountContacts,
            name: 'ContactPreferences',
            meta: {
              breadcrumb: {nameGettext: ''}
            },
            props: {title: ''}
          }
        ]
      },
      /* {
        path: langPath + '/account/update',
        name: 'Account/update',
        component: Profile,
        props: {
          title: 'Profile'
        }
      },
      {
        path: langPath + '/account/password/',
        name: 'Account/password/',
        component: Settings,
        props: {
          title: 'Settings'
        }
      },
      {
        path: langPath + '/account/profile',
        name: 'Account',
        component: Account,
        props: {
          title: 'My Account'
        }
      },
      {
        path: langPath + '/account/orders',
        name: 'Account/orders',
        component: Orders,
        props: {
          title: 'Orders'
        }
      }, */
      {
        path: langPath + '/account/review-list',
        name: 'Account/reviews',
        component: ReviewsList,
        props: {
          title: 'Product Reviews'
        }
      },
      {
        path: langPath + '/reviews/write/:item_id',
        name: 'Reviews',
        component: Review,
        props: {
          title: 'Add or EditProduct Review'
        }
      },
      /* {
        path: langPath + '/account/prescriptions',
        name: 'Account/prescriptions',
        component: SavedPrescriptions,
        props: {
          title: 'Prescriptions'
        }
      },
      {
        path: langPath + '/account/view_order/:id(\\d+)/',
        name: 'Account/order/view',
        component: Order,
        props: true
      }, */
      {
        path: langPath + '/catalogue/index/term/:search_term',
        name: 'ProductListSearch',
        component: Products,
        props: true
      },
      {
        path: langPath + '/:menu(.+)?/prescription/select_use',
        name: 'SelectUse',
        component: Prescription,
        props: {stage: 'SelectUse'}
      },
      {
        path: langPath + '/:menu(.+)?/prescription/prescriptions',
        name: 'Prescriptions',
        component: Prescription,
        props: {stage: 'Prescriptions'}
      },
      {
        path: langPath + '/:menu(.+)?/prescription/lens_type',
        name: 'LensType',
        component: Prescription,
        props: {stage: 'LensType'}
      },
      {
        path: langPath + '/:menu(.+)?/prescription/lens_options',
        name: 'LensOptions',
        component: Prescription,
        props: {stage: 'LensOptions'}
      },
      {
        path: langPath + '/checkout/fail/:id',
        name: 'Fail',
        component: Fail
      },
      {
        path: '/ua/checkout/fail/:id',
        name: 'Fail',
        component: Fail
      },
      {
        path: '/us/checkout/fail/:id',
        name: 'Fail',
        component: Fail
      },
      {
        path: langPath + '/checkout/amazon/:order_id',
        name: 'Amazon',
        component: Amazon
      },
      {
        path: langPath + '/checkout/thank/:order_id',
        name: 'Thank',
        component: Thank
      },
      {
        path: '/ua/checkout/thank/:order_id',
        name: 'Thank',
        component: Thank
      },
      {
        path: '/us/checkout/thank/:order_id',
        name: 'Thank',
        component: Thank
      },
      {
        path: langPath + '/payment/:order_id',
        name: 'CheckoutPayment',
        component: Checkout
      },
      {
        path: langPath + '/checkout/:order_id',
        name: 'Checkout',
        component: Checkout
      },
      {
        path: '/us/checkout/:order_id',
        name: 'Checkout',
        component: Checkout
      },
      {
        path: '/ua/checkout/:order_id',
        name: 'Checkout',
        component: Checkout
      },
      {
        path: langPath + '/accessories',
        name: 'ProductListAccessories',
        component: Products,
        props: true
      },
      {
        path: langPath + '/:category?/:designer?/:model?/(.+)?/ss:item(\\d+\\.?\\d+).html',
        name: 'ItemPage',
        component: PageItem
      },
      {
        path: langPath + '/payments/:order_id',
        name: 'Payments',
        component: Payments
      },
      /*
      {
        path: langPath + '/cart/index',
        name: 'BasketSSv4',
        component: CartSSv4
      },
      */
      {
        path: langPath + '/cart/index',
        name: 'Basket',
        component: Basket
      }
    ],
    scrollBehavior (to, from, savedPosition) {
      return { x: 0, y: 0 }
    }
  })
  const components = {}
  Object.keys(baseComponents).map(key => {
    components[key] = baseComponents[key]
  })

  const http = createHTTPClient()

  let matches = document.cookie.match(new RegExp('(?:^|; )' + 'token'.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'))
  if (matches) {
    try {
      await http.get(config.users.checkToken, {params: {token: matches[1]}})
    } catch (e) {
      console.log('Authorization fail.')
    }
  }

  await Promise.all([
    http.get(config.routes.getPagesRoutes, {params: {lang: config.defLocale}}, {
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      },
      emulateJSON: true
    }).then(response => response.data)
      .then((data) => {
        let routes = []
        Object.values(data).map(page => {
          let subName = '_' + page.lang
          let filtered = routes.filter(obj => obj.name === (page.name + subName))
          routes.push({
            name: page.name + subName
          })

          let path = ('/' + config.subDomain + '/' + page.path).replace(new RegExp('/{2,}', 'g'), '/')
          router.addRoutes([{
            path: path,
            name: page.name + subName + (filtered.length > 0 ? filtered.length : ''),
            component: components[page.component],
            meta: {
              lang: page.lang
            }
          }])
        })

        router.addRoutes([{
          path: '*',
          name: 'PageNotFound',
          component: PageNotFound
        }])
      }),
    http.get(config.routes.getRedirects, {params: {lang: config.defLocale}}, {
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      },
      emulateJSON: true
    }).then(response => response.data)
      .then((data) => {
        Object.values(data).map(obj => {
          let redirect = ('/' + langPath + '/' + obj.path).replace(new RegExp('/{2,}', 'g'), '/').replace(/(\/$)/, '')
          let path = ('/' + langPath + '/' + obj.redirect).replace(new RegExp('/{2,}', 'g'), '/').replace(/(\/$)/, '')
          if (redirect && path !== redirect) {
            router.addRoutes([{
              path: path,
              redirect: redirect
            }])
          }
        })
      })
  ])

  return router
}

