const storeVue = {
  data () {
    return {
      search: {
        query: '',
        params: {}
      }
    }
  }
}

export default storeVue
