<template lang="pug">
  .row
    .col-12
      p.small.mb-2
        strong Prescription
      table.table.item-script
        thead
          tr
            th Eye
            th.sph SPH
            th.cyl CYL
            th.axis AXIS
            th.add ADD
        tbody
          tr
            td.eye
              strong Right
            td - 1.50
            td 0
            td 0
            td 0.00
          tr
            td.eye
              strong Left
            td - 1.25
            td 0
            td 0
            td 0.00
      p.small Pupillary Distance (PD): 63
      p.small.mb-3.mb-lg-0
        strong Prescription attached:&nbsp;
        a(href="#").blue-ul myprescription.jpg

</template>

<style lang="scss" scoped>
  .item-script {
    background-color: #fefefe;
    tr {
      border-bottom: 1px solid #e2e6e7;
      border-left: 1px solid #e2e6e7;
      border-right: 1px solid #e2e6e7;
    }
    th {
      font-size: .8rem;
      border-top: 1px solid #e2e6e7;
      background: #eaeaea;
      padding: 5px 2px;
      text-align: center;
    }
    td {
      background: #fff;
      font-size: .7rem;
      text-align: center;
      padding: .5rem .2rem;
      &.eye {
        text-align: left;
        background-color: #f6f6f6;
      }
    }
  }
</style>

<script>
  export default {
    name: 'PrescriptionTable'
  }
</script>
