<template lang="pug">
  .order-block
    .d-none.d-lg-block
      template(v-if="orderType === 'processed'")
        .order-wrapper.order-in-progress
          .row
            .col-lg-3
              p
                strong Order # {{order.order_number}}
              p.text-muted
                small
                  strong ORDER PLACED: {{moment(order.created_at).format('D MMM YY')}}
              p.text-muted
                small
                  strong TOTAL:&nbsp;
                  strong(:id="`order${order.order_number}TotalPrice`")
                    span(v-html="currency.symbol")
                    span {{order.final_total}}
            .col-lg-6.text-center
              p.d-inline-block.large
                i.fa(:class="[order.payment.status === 'COMPLETED' ? 'fa-check-circle' : 'fa-clock-o']").fa-2x.mr-2.light-green
                strong(v-html="header").align-middle
              p(v-html="subText").small
            .col-lg-3.text-center
              p
                router-link(:id="`viewOrder${order.order_number}Link`",
                :to='{name: "OrderView", params: {id: order.order_number}}').btn.orange-button View Order »
      template(v-else-if="orderType === 'unprocessed'")
        .order-wrapper
          .row.text-center
            .col-12
              p.large
                strong(v-html="header")
              p.small
                span(v-html="subText")
                br
                span(v-if="order.payment.status === 'WAITING'")
                  a(:href='`/payments/${order.payment_record_id}`').blue-ul Pay Now
                  |&nbsp;or you can&nbsp;
                  a(@click='emitDeleteOrder(order.order_number)', href='').blue-ul Cancel Order


    .d-lg-none
      template(v-if="orderType === 'processed'")
        router-link(:id="`viewOrder${order.order_number}LinkMobile`",
          :to='{name: "OrderView", params: {id: order.order_number}}').order-mobile-link
          .row
            .col-1
              i.fa(:class="[order.payment.status === 'COMPLETED' ? 'fa-check-circle' : 'fa-clock-o']").fa-2x.light-green
            .col-10
              p.large
                strong(v-html="header")
              p.text-muted
                small
                  strong ORDER PLACED: {{moment(order.created_at).format('D MMM YY')}}
              p.text-muted
                small
                  strong TOTAL:&nbsp;
                  strong(:id="`order${order.order_number}TotalPrice`")
                    span(v-html="currency.symbol")
                    span {{order.final_total}}
            .col-1.align-self-center
              i.fa.fa-chevron-right
      template(v-if="orderType === 'unprocessed'")
        router-link(v-if="order.payment.status === 'WAITING'", :id="`payOrder${order.payment_record_id}Link`",
          :to="$link(`/payments/${order.payment_record_id}`)").order-mobile-link
          .row
            .col-11
              p.large
                strong(v-html="header")
              p.text-muted
                small(v-html="subText")
            .col-1.align-self-center
              i.fa.fa-chevron-right.fa-2x
        .row(v-else)
          .col-12
            p.large
              strong(v-html="header")
            p.text-muted
              small(v-html="subText")

</template>

<style lang="scss" scoped>
  .order-block {
    @media (max-width: 1024px) {
      display: block;
      background: #fff;
      padding: 1rem .5rem 1rem 0;
      &:not(:last-child) {
        border-bottom: 1px solid #eee;
        margin-bottom: 1rem;
      }
    }
    &:hover, &:focus {
      color: initial;
      text-decoration: none;
    }
    a.order-mobile-link, div.order-mobile-link {
      &:hover, &:focus {
        color: initial;
        text-decoration: none;
      }
      p.text-muted {
        line-height: 1.1;
      }
    }
  }

  .order-wrapper {
    background: #ecf0f1;
    padding: 1rem .5rem;
    margin-bottom: 1rem;
    &.order-in-progress {
      border: 2px solid #eeeeee;
      background: #fff;
      padding: 1rem .5rem;
      &:not(:last-of-type) {
        margin-bottom: 1rem;
      }
    }
  }

  .fa-2x {
    vertical-align: middle;
  }
  .light-green {
    color: #a7d163;
  }
  .col-form-label {
    font-size: .75rem;
    font-weight: 400;
  }
  .fa-chevron-right {
    font-size: 1.2rem;
  }
</style>

<script>
  import { mapState } from 'vuex'
  import moment from 'moment'

  export default {
    name: 'OrderTemplate',
    props: {
      order: {
        required: true,
        type: Object
      },
      orderType: String
    },
    computed: {
      ...mapState({
        currency: (state) => ({...state.currency.allCurrency[state.currency.selected]})
      }),
      header () {
        switch (this.order.payment.status) {
          case 'COMPLETED':
            return this.$t('OrderCompleted')
          case 'AUTHORISED':
            return this.$t('OrderInProgress')
          case 'WAITING':
            return `Order # ${this.order.order_number} Awaiting Payment`
          case 'PROCESSING_PAYMENT':
            return `Order # ${this.order.order_number} Processing...`
          case 'CANCELLED':
            return `Order # ${this.order.order_number} Cancelled`
          default: break
        }
      },
      subText () {
        switch (this.order.payment.status) {
          case 'COMPLETED':
            return 'Your order has been completed & shipped.'
          case 'AUTHORISED':
            return `Your order is now in production and can take up to 10 working days to complete. See our <a id='prodGuidesLink' href="/info/shipping-delivery/" class='blue-ul'>production timescales</a> for more details.`
            // return this.$t('YourOrderInProduction', {number: this.order.payment_record_id})
          case 'WAITING':
            return `You have attempted to place an order but we've not received payment. Click 'Pay Now' to complete this order <br>`
            // return this.$t('AwaitingPaymentSubTexts', {number: this.order.payment_record_id})
          // case 'PROCESSING_PAYMENT':
          case 'PROCESSING_PAYMENT':
            return 'We\'ve received your order and payment. You will be able to view progress shortly (allow 24 hours).'
          case 'CANCELLED':
            return 'This order has been cancelled. Any refund due will be made within 5 working days of cancellation.'
          default: break
        }
      }
    },
    methods: {
      moment,
      emitDeleteOrder (orderNumber) {
        this.$emit('delete-order', orderNumber)
      }
    }
  }
</script>
