import SettingsForms from '@/components/scripts/PageFooterSSv4/FooterNavigation/SettingsForms/index.vue'
import Socials from '@/components/scripts/PageFooterSSv4/FooterNavigation/Socials/index.vue'
import PaymentsTypes from '@/components/scripts/PageFooterSSv4/FooterNavigation/PaymentsTypes/index.vue'

export default {
  name: 'FooterNavigation',
  components: {
    SettingsForms,
    Socials,
    PaymentsTypes
  }
}
