import Vue from 'vue'
import swiper from 'vue-awesome-swiper'

Vue.use(swiper)

export default {
  name: 'BrandsCarousel',
  data () {
    return {
      swiperOptions: {
        loop: false,
        slidesPerView: 'auto',
        autoplay: {
          delay: 2500,
          disableOnInteraction: false
        },
        autoHeight: true,
        centeredSlides: true,
        navigation: {
          nextEl: '.slick-next',
          prevEl: '.slick-prev'
        }
      },
      brands: [
        {
          route: this.$link('/glasses/prescription/tiffany-co-/d31593/'),
          imgSrc: 'https://d9qzjwuieyamt.cloudfront.net/res/images/_png/brand/tiffany.png?20170829201433'
        },
        {
          route: this.$link('/glasses/prescription/ray-ban/d43/'),
          imgSrc: 'https://d9qzjwuieyamt.cloudfront.net/res/images/_png/brand/ray-ban.png?20170829201433'
        },
        {
          route: this.$link('/glasses/prescription/oakley/d391/'),
          imgSrc: 'https://d9qzjwuieyamt.cloudfront.net/res/images/_png/brand/oakley.png?20170829201433'
        },
        {
          route: this.$link('/glasses/prescription/mont-blanc/d285/'),
          imgSrc: 'https://d9qzjwuieyamt.cloudfront.net/res/images/_png/brand/mont-blanc.png?20170829201433'
        },
        {
          route: this.$link('/glasses/prescription/tom-ford/d284/'),
          imgSrc: 'https://d9qzjwuieyamt.cloudfront.net/res/images/_png/brand/tom-ford.png?20170829201433'
        },
        {
          route: this.$link('/glasses/prescription/prada/d111/'),
          imgSrc: 'https://d9qzjwuieyamt.cloudfront.net/res/images/_png/brand/prada.png?20170829201433'
        },
        {
          route: this.$link('/glasses/prescription/oliver-peoples/d31936/'),
          imgSrc: 'https://d9qzjwuieyamt.cloudfront.net/res/images/_png/brand/oliver-peoples.png?20170829201433'
        }
      ],
      brandsAdditional: [
        {
          route: this.$link('/glasses/prescription/dior/d61/'),
          imgSrc: 'https://d9qzjwuieyamt.cloudfront.net/res/images/_png/brand/dior.png?20170829201433'
        },
        {
          route: this.$link('/glasses/prescription/jimmy-choo/d395/'),
          imgSrc: 'https://d9qzjwuieyamt.cloudfront.net/res/images/_png/brand/jimmychoo.png?20170829201433'
        },
        {
          route: this.$link('/glasses/prescription/michael-kors/d409/'),
          imgSrc: 'https://d9qzjwuieyamt.cloudfront.net/res/images/_png/brand/michaelkors.png?20170829201433'
        },
        {
          route: this.$link('/glasses/prescription/celine/d31595/'),
          imgSrc: 'https://d9qzjwuieyamt.cloudfront.net/res/images/_png/brand/celine.png?20170829201433'
        },
        {
          route: this.$link('/glasses/prescription/boss-hugo-boss/d246/'),
          imgSrc: 'https://d9qzjwuieyamt.cloudfront.net/res/images/_png/brand/boss.png?20170829201433'
        },
        {
          route: this.$link('/glasses/prescription/chopard/d423/'),
          imgSrc: 'https://d9qzjwuieyamt.cloudfront.net/res/images/_png/brand/chopard.png?20170829201433'
        },
        {
          route: this.$link('/glasses/prescription/william-morris-london/d31571/'),
          imgSrc: 'https://d9qzjwuieyamt.cloudfront.net/res/images/_png/brand/williammorris.png?20170829201433'
        },
        {
          route: this.$link('/glasses/prescription/barbour/d31782/'),
          imgSrc: 'https://d9qzjwuieyamt.cloudfront.net/res/images/_png/brand/barbour.png?20170829201433'
        }
      ]
    }
  },
  mounted () {
    if (this.swiperBrands) {
      this.swiperBrands.update()
    }
  }
}
