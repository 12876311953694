import { mapState } from 'vuex'
import Breadcrumbs from '@/components/scripts/Breadcrumbs/index.vue'
import { reverseRouteName } from '@/../config/helper'

export default {
  name: 'BrandList',
  components: {
    Breadcrumbs
  },
  computed: {
    ...mapState({
      apiHost: (state) => state.apiHost,
      routerObj: (state) => state.pageMenuDescription,
      brandsList: (state) => state.promiseData && state.promiseData.brandsList ? state.promiseData.brandsList : [],
      path: (state) => state.promiseData && state.promiseData.categoryPath ? state.promiseData.categoryPath : {}
    })
  },
  data () {
    return {
      requestOptions: {
        headers: {
          'X-Requested-With': 'XMLHttpRequest'
        },
        emulateJSON: true,
        credentials: true
      },
      def_path: this.$route.path
    }
  },
  asyncData ({store}) {
    return Promise.all([
      store.dispatch('detectCategoryPath'),
      store.dispatch('loadBrandsList')
    ])
  },
  destroyed () {
    this.$store.dispatch('clearPromiseDataKey', 'brandsList')
    this.$store.dispatch('clearPromiseDataKey', 'categoryPath')
  },
  methods: {
    reverseRouteName: function (str) {
      return reverseRouteName(str)
    },
    loadBrands () {
      this.$store.dispatch('detectCategoryPath')
      this.$store.dispatch('loadBrandsList')
    }
  },
  watch: {
    'routerObj': {
      handler: function () {
        this.loadBrands()
      },
      deep: true
    }
  }
}
