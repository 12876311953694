export default function () {
  const BigNumber = require('bignumber.js')

  return {
    allCurrency: [],
    selected: undefined,
    exchangeFunc: function (rawPrice, print = true, isRate = true, toNumber = true) {
      if (!this.selected) {
        return rawPrice
      }

      let price = new BigNumber(rawPrice)
      const currency = Object.assign({}, this.allCurrency[this.selected])
      const precis = (currency.precision_digit || currency.precision_digit === 0) ? currency.precision_digit : 0
      const rate = new BigNumber(currency.rate)
      if (isRate) {
        price = price.multipliedBy(rate)
      }
      BigNumber.config(currency.to_greater && {ROUNDING_MODE: BigNumber.ROUND_CEIL} || {ROUNDING_MODE: BigNumber.ROUND_HALF_UP})
      price = price.shiftedBy(precis)
      price = price.decimalPlaces(0)
      const result = price.shiftedBy(-precis)
      return (precis >= 0 && print) ? result.toFixed(precis) : toNumber && result.toNumber() || result
    },
    exchangeBackFunc: function (rawPrice, toNumber = true) {
      if (!this.selected) {
        return rawPrice
      }

      const currency = Object.assign({}, this.allCurrency[this.selected])
      const price = new BigNumber(rawPrice)
      const rate = new BigNumber(currency.rate)

      return toNumber && price.dividedBy(rate).toNumber() || price.dividedBy(rate)
    },
    exchangeBackFuncByCurrency: function (rawPrice, currency, toNumber = true) {
      const price = new BigNumber(rawPrice)
      const rate = new BigNumber(currency.rate)
      return toNumber && price.dividedBy(rate).toNumber() || price.dividedBy(rate)
    },
    exchange: price => 0.0,
    exchangeBack: price => 0.0
  }
}
