import {mapState} from 'vuex'

export default {
  name: 'Logout',
  computed: {
    ...mapState({
      loading: (state) => state.loading,
      auth: (state) => state.authorization
    })
  },
  created () {
    this.$store.dispatch('onLogout', this.$router)
  }
}
