import OrderTemplate from '@/components/scripts/AccountPage/AccountOrders/OrderTemplate'
import { mapState } from 'vuex'
import moment from 'moment'

export default {
  name: 'Orders',
  components: {
    OrderTemplate
  },
  computed: {
    ...mapState({
      meta: (state) => ({
        ...state.formMeta.orders || {}
      }),
      loading: (state) => state.loading,
      orderLoading: (state) => state.orderLoading,
      apiHost: (state) => state.apiHost,
      user (state) {
        const {...user} = state.user
        if (user.user_number && !this.loaded++) {
          this.loaded = true
          this.getOrders(user)
        }
        return user
      },
      profile: (state) => {
        const {user: {profile: data}} = state
        const {...profile} = data

        return profile
      },
      orders: (state) => (state.orders || []),
      currency: (state) => ({...state.currency.allCurrency[state.currency.selected]}),
      currencies: (state) => (state.currency.allCurrency || []),
      auth: (state) => state.authorization,
      exchange: (state) => state.currency.exchange,
      exchangeBack: (state) => state.currency.exchangeBack,
      vat: (state) => state.vat
    })
  },
  data: function () {
    return {
      ordersByYear: [],
      period: 1,
      periodsArray: [
        {
          value: 1,
          text: 'All orders'
        }
      ]
    }
  },
  watch: {
    orders (data) {
      if (!data || !data.length) return []
    },
    user: () => {}
  },
  methods: {
    moment,
    getOrders (user) {
      if (!user.user_number) return
      const calculatedDate = this.period === 6 ? moment().subtract(6, 'month') : moment(this.period + '-01-01 00:00:00')
      const time = calculatedDate.format('YYYY-MM-DD HH:mm:ss')
      return this.$store.dispatch('fetchOrders', {user, time})
        .then(() => this.calculateOrdersYears())
    },
    getOrdersByYear (year) {
      this.ordersByYear = this.orders.filter(order => order.created_at.includes(String(year)))
    },
    deleteOrder (id) {
      let unprocessed = this.filterOrders('WAIT_PAYMENT')
      const key = unprocessed.findIndex(item => (item.order_number == id))
      unprocessed.splice(key, 1)
      this.$store.dispatch('deleteOrder', {id, user: this.user})
        .then(() => this.$store.dispatch('getBasket'))
        .then(() => this.fetchOrders(this.user))
    },
    acceptOrders (field, remove, all) {
      all.map(item => {
        const key = this[field].findIndex(jtem => (jtem.order_number === item.order_number))
        const rkey = this[remove].findIndex(jtem => (jtem.order_number === item.order_number))
        if (rkey >= 0) {
          this[remove].splice(rkey, 1)
        }
        if (key === -1) {
          this[field].push(item)
          return
        }
        const old = this[field][key]
        Object.keys(old).map(jey => {
          old[jey] = item[jey]
        })
      })
    },
    calculateOrdersYears () {
      function onlyUnique (value, index, self) {
        return self.indexOf(value) === index
      }

      this.orders
        .map(order => moment(order.created_at).format('YYYY'))
        .filter(onlyUnique)
        .forEach(i => {
          this.periodsArray.push({
            value: i,
            text: i
          })
        })
      this.periodsArray.sort((a, b) => b.value - a.value)
    },
    filterOrders (status) {
      let orders = this.ordersByYear.length > 0 ? this.ordersByYear : this.orders
      console.log(status)
      return orders.filter(order => order.status === status)
    }
  },
  mounted () {
  },
  destroyed () {
  }
}
