import Breadcrumbs from '@/components/scripts/Breadcrumbs/index.vue'
import PageBanner from '@/components/scripts/SalePage/PageBanner/index.vue'
import ExcellentRating from '@/components/scripts/ExcellentRating/index.vue'
import SaleBanner from '@/components/scripts/SalePage/SaleBanner/index.vue'
import SalesLinks from '@/components/scripts/SalePage/SalesLinks/index.vue'

export default {
  name: 'SalePage',
  components: {
    Breadcrumbs,
    PageBanner,
    ExcellentRating,
    SaleBanner,
    SalesLinks
  }
}
