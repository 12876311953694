import { mapState } from 'vuex'
import moment from 'moment'

export default {
  name: 'AccountPrescriptions',
  computed: {
    ...mapState({
      prescriptions: (state) => state.prescriptions
    })
  },
  created () {
    this.$store.dispatch('fetchAccountPrescriptions')
  },
  methods: {
    moment,
    deletePrescription (id) {
      this.$store.dispatch('deletePrescription', id)
    },
    capitalize (s) {
      if (typeof s !== 'string') return ''
      return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()
    }
  }
}
