export default {
  name: 'SearchModal',
  data () {
    return {
      inputSearchText: ''
    }
  },
  methods: {
    handleSubmit: function (e) {
      e.preventDefault()
      if (this.inputSearchText) {
        const str = this.inputSearchText.replace(/\//g, ' ').replace(/\s+/g, ' ')
        if (str.length !== 0) {
          document.location.href = this.getSSv4Link('/catalogue/index/term/') + encodeURI(str) + '?q=' + encodeURI(str) + '&all=0' +
            '&back=' + document.location.protocol + '//' + document.location.host + document.location.pathname
        }
      } else {
        e.stopPropagation()
      }
    }
  }
}
