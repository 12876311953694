<template lang="pug">
    .order-block.col-12
        .order-header
            .row.align-items-center
                .col-5.text-left
                    p.large
                        strong Order # {{$route.params.id}}
                .col-7.text-right
                    p.d-inline-block.large
                        i.fa.fa-check-circle.fa-2x.mr-2.light-green
                        strong.align-middle Order {{order.payment.status ? order.payment.status : 'Completed'}}
        .order-body
            .row
                .col-12.col-lg-6
                    .row.mb-4.mb-lg-0
                        .col-6(v-for="address in addresses")
                            p.small
                                strong {{address.address_type ? address.address_type : ''  | titleCase}} Address
                            p.small {{address.line1 | titleCase}}
                            p.small {{address.line2 | titleCase}}
                            p.small {{address.town | titleCase}}
                            p.small {{address.state | titleCase}}
                            p.small {{address.zip.toUpperCase()}}
                .col-12.col-lg-6
                    p.small.mb-2
                        strong Order Date:
                        |  {{moment(order.created_at).format('D MMMM YY')}}
                    p.small.mb-2
                        strong Being delivered by:
                        span  Courier -&nbsp;
                        span(v-html="currencies[order.currency_id].symbol")
                        span(:id="order.order_number + 'DeliveryPrice'") {{order.shipping_cost}}
                    p.small.mb-2
                        strong Order Total (inc. delivery):&nbsp;
                        span(v-html="currencies[order.currency_id].symbol")
                        span(:id="order.order_number + 'TotalPrice'") {{order.final_total}}
                    p.small
                        strong Invoice:&nbsp;
                        a(v-if="order.payment.status === 'COMPLETED'", href="#").blue-ul Invoice # 8888888888
                        span(v-else) Available once your order is completed
        .order-footer
            .row
                .col-12
                    p.small
                        strong No messages regarding your order
                    p.small Everything is progressing as normal. If you have any questions or need to make an amendment please&nbsp;
                        a(href="#").blue-ul contact us
                        | .
</template>

<style lang="scss" scoped>
    .order-block {
        background-color: #fff;
        margin-bottom: 1rem;
    }

    .order-header, .order-body {
        padding: 1rem 0;
        border-bottom: 1px solid #eee;
    }

    .order-footer {
        padding: 1rem 0;
    }

    .fa-2x {
        vertical-align: middle;
    }

    .light-green {
        color: #a7d163;
    }
</style>

<script>
  import {mapState} from 'vuex'
  import moment from 'moment'

  export default {
    name: 'OrderInfo',
    computed: {
      ...mapState({
        currencies: (state) => state.currency.allCurrency
      }),
      addresses: function () {
        const order = this.order
        let addressKeys = Object.keys(order).filter(data => ~data.indexOf('Address'))
        let addresses = []
        for (let key in order) {
          if (order.hasOwnProperty(key) && ~addressKeys.indexOf(key)) {
            addresses.push(order[key])
          }
        }
        return addresses
      }
    },
    props: {
      order: {
        required: true
      }
    },
    methods: {moment}
  }
</script>
