import Flash from '@/components/scripts/FlashMessage/index.vue'
import DatePicker from 'vuejs-datepicker'
import { mapActions, mapState } from 'vuex'
import * as types from '@/store/types'

export default {
  name: 'UpdateProfile',
  computed: {
    ...mapState({
      meta (state) {
        const meta = {
          ...state.formMeta['profileUpdate'],
          message: state.formMeta.message
        }
        return meta
      },
      profile: (state) => state.user.profile
    }),
    newData: function () {
      return {
        ...this.profile
      }
    }
  },
  data () {
    return {
      showPassword: false
    }
  },
  components: {
    DatePicker,
    Flash
  },
  methods: {
    ...mapActions({
      onChange: 'onChangeForm',
      showAlert: 'formMessage',
      updateProfile: 'onUpdateProfile'
    }),
    onSubmit () {
      this.$store.commit(types.FORM_FIELD_CLEAR, {formName: 'profileUpdate'})
      let updatedUser = {
        password: this.newData.password,
        profile: {
          ...this.newData,
          subscribed: +this.newData.subscribed
        }
      }
      if (!this.newData.password) {
        this.showAlert({
          message: 'Please, enter password to verify',
          type: 'error'
        })
      } else {
        this.updateProfile(updatedUser)
          .then(() => {
            this.$store.commit(types.PASSWORD_CLEAR)
          })
      }
    }
  },
  destroyed () {
    this.$store.commit(types.FORM_FIELD_CLEAR, {formName: 'profileUpdate'})
  }
}
