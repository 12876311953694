import AddressBlock from '@/components/scripts/AccountPage/AccountAddresses/AddressBlock/index.vue'

import { mapState } from 'vuex'

export default {
  name: 'AccountAddresses',
  components: {
    AddressBlock
  },
  computed: {
    ...mapState({
      apiHost: (state) => state.apiHost,
      auth: (state) => state.authorization,
      profile: (state) => {
        const {user: {profile: data = {}}} = state
        const {...profile} = data
        return profile
      },
      loading: (state) => state.loading,
      user: (state) => ({
        ...state.user
      }),
      billingAddress: (state) => {
        const {profile = {}} = state.user
        const {addresses = []} = profile

        if (addresses.length > 0) {
          return addresses.find((item) => item.address_type === 'billing')
        }
      },
      deliveryAddress: (state) => {
        const {profile = {}} = state.user
        const {addresses = []} = profile

        if (addresses.length > 0) {
          return addresses.find((item) => item.address_type === 'delivery')
        }
      }
    })
  }
}
