<template lang="pug">
  .row.mb-3
    .col-6
      p.small
        strong {{item.modelName | titleCase}}
      p(v-if="item.option.name").small {{item.option.name}}
      p(v-if="item.option.description").small {{item.option.description | titleCase}}
      p(v-if="item.option").small.mb-1 Size {{item.option.arm}}/{{item.option.bridge}}/{{item.option.lens}}/{{item.option.frame_number}}
      p.small
        span(v-html="currencies[order.currency_id].symbol")
        span(:id="`item${item.item_number}PriceMobile`") {{(parseFloat(item.option.price*item.quantity)).toFixed(2)}}
    .col-6(v-if=hasPrescription)
      p.small
        strong Distance (single vision)
      p.small Super thin 1.61 index
      p.small Clear lenses
      p.small.mb-1 UV, AR, SR
      p.small
        span(v-html="currencies[order.currency_id].symbol")
        span(:id="`item${item.item_number}PriceRXMobile`") {{(parseFloat(item.option.price*item.quantity)).toFixed(2)}}
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'ItemInfo',
    computed: {
      ...mapState({
        currencies: (state) => state.currency.allCurrency
      })
    },
    props: {
      item: {
        required: true
      }
    }
  }
</script>
