// that function return initial state for store
export function getInitialState () {
  const MobileDetect = require('mobile-detect')
  const vat = require('./vat').default()
  const calculatePrice = require('./price').default
  const auth = require('./authorization')
  const currency = require('./currency').default()
  const config = require('./../../config/index')
  const data = {
    ...global.initialState,
    apiHost: config.apiHost,
    integrationHost: config.integrationHost,
    lang: config.defLocale,
    formData: {},
    formMeta: {},
    breadcrumbs: [],
    menus: [],
    mobileMenu: [],
    redirects: [],
    backRoute: {
      path: undefined,
      params: {}
    },
    loading: undefined,
    basketLoading: undefined,
    currencyLoading: undefined,
    orderLoading: undefined,
    user: {},
    address: {},
    regData: {},
    basket: {},
    ssv4Order: {},
    ssv4SavedItems: [],
    ssv4User: [],
    pageMenuDescription: {},
    currency,
    orders: [],
    allCategories: [],
    allDesigners: [],
    authorization: auth.UNAUTHORIZED,
    countries: [],
    countryLoading: undefined,
    geoLoading: undefined,
    itemsLoading: undefined,
    geoInfo: {},
    wishlist: [],
    activeWL: [],
    blocks: {},
    promiseData: null,
    cookies: {},
    headers: undefined,
    urls: {},
    urlCommit: {},
    calculatePriceFunc: calculatePrice,
    vatFunc: vat,
    ssv4: false,
    reviews: null
  }
  const md = new MobileDetect(global.navigator.userAgent)
  data.vat = vat.bind(data)
  data.calculatePrice = calculatePrice.bind(data)
  data.isMobile = !!md.mobile()
  return data
}

const loadModule = (name, Vue, state, actions, mutations, getters) => {
  const data = require(`./modules/${name}`).default(Vue)
  Object.keys(data.state).forEach((key) => {
    state[key] = data.state[key]
  })
  Object.keys(data.mutations).forEach((key) => {
    mutations[key] = data.mutations[key]
  })
  Object.keys(data.actions).forEach((key) => {
    actions[key] = data.actions[key]
  })
  Object.keys(data.getters).forEach((key) => {
    getters[key] = data.getters[key]
  })
}

export function createStore (Vue) {
  const Vuex = require('vuex')
  const mutations = require('./mutations').default()
  const actions = require('./actions').default(Vue)
  const getters = require('./getters').default
  Vue.use(Vuex)
  const state = getInitialState()

  loadModule('account', Vue, state, actions, mutations, getters)

  const store = new Vuex.Store({
    state,
    getters,
    actions,
    mutations
  })
  return store
}
