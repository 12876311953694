import {mapState} from 'vuex'
import PageItemMobile from '@/components/scripts/PageItem/PageItemMobile/index.vue'
import Breadcrumbs from '@/components/scripts/Breadcrumbs/index.vue'
import BasketConfirmModal from '@/components/scripts/PageItem/BasketConfirmModal/index.vue'
import CustomerDescriptionModal from '@/components/scripts/PageItem/CustomerDescriptionModal/index.vue'
import SizeGuideModal from '@/components/scripts/PageItem/SizeGuideModal/index.vue'
import VideoModal from '@/components/scripts/PageItem/VideoModal/index.vue'
import ShareButtons from '@/components/scripts/ShareButtons/index.vue'
import TrustpilotWidget from '@/components/scripts/TrustpilotWidget/index.vue'
import Media360Modal from '@/components/scripts/PageItem/360MediaModal/index.vue'
import Reviews from '@/components/scripts/PageItem/Reviews/index.vue'

import config from '@/../config'
import {reverseRouteName} from '@/../config/helper'
import $ from 'jquery'

export default {
  name: 'PageItem',
  components: {
    BasketConfirmModal,
    CustomerDescriptionModal,
    SizeGuideModal,
    VideoModal,
    Breadcrumbs,
    PageItemMobile,
    ShareButtons,
    TrustpilotWidget,
    Media360Modal,
    Reviews
  },
  computed: {
    ...mapState({
      apiHost: (state) => state.apiHost,
      breadcrumbs: (state) => state.breadcrumbs,
      currency: (state) => ({...state.currency.allCurrency[state.currency.selected]}),
      exchange: (state) => state.currency.exchange,
      exchangeBack: (state) => state.currency.exchangeBack,
      backRoute: (state) => state.backRoute,
      vat: (state) => state.vat,
      item: (state) => state.promiseData && state.promiseData.storeItem ? state.promiseData.storeItem : {}
    }),
    options () {
      return this.item && this.item.options ? this.item.options : {}
    },
    defFrameSizeIndex () {
      return this.options && this.item.def_option && this.options[this.item.def_option] ? this.options[this.item.def_option].frame_sizes.findIndex(obj => obj.status === 'IN_STOCK') : -1
    },
    isActive () {
      let isActive = true
      if (this.options && this.selected.option && this.selected.option.frame_sizes) {
        let option = this.options[this.selected.option]
        isActive = Object.values(option.frame_sizes).filter(obj => obj.status === 'IN_STOCK').length > 0
      }
      return isActive
    },
    hasView360 () {
      return this.options[this.selected.option] && this.options[this.selected.option].view360_images && this.options[this.selected.option].view360_images.length > 0
    },
    view360Images () {
      let self = this
      return this.hasView360 ? this.options[this.selected.option].view360_images.map(obj => self.confCdnUrl + self.cdnUrlPrefix + obj.src) : []
    },
    designerUrl () {
      return this.item && this.item.designerUrl ? this.item.designerUrl : '#'
    },
    designerName: function () {
      return this.item.designer ? this.$tf(this.item.designer.translations, 'designer_name') : ''
    },
    modelName: function () {
      return this.item.model ? this.$tf(this.item.model.translations, 'model_name') : ''
    }
  },
  data () {
    return {
      mounted: false,
      changeSlick: false,
      activeSwiperIndex: 1,
      cdnUrlPrefix: config.cdnUrlPrefix,
      selected: {
        item: null,
        option: null,
        frameSizeIndex: 0
      },
      requestOptions: {
        headers: {
          'X-Requested-With': 'XMLHttpRequest'
        },
        emulateJSON: true,
        credentials: true
      },
      swiperOption: {
        loop: true,
        slidesPerView: 1,
        loopedSlides: 1,
        loopFillGroupWithBlank: true,
        centeredSlides: true,
        speed: 500,
        autoHeight: true,
        effect: 'fade',
        observer: true,
        navigation: {
          nextEl: '#item_images_block .slick-next',
          prevEl: '#item_images_block .slick-prev'
        },
        watchOverflow: true
      },
      slickOptions: {
        autoplay: false,
        pauseOnDotsHover: true,
        infinite: true,
        speed: 500,
        fade: true,
        cssEase: 'linear',
        swipeToSlide: true,
        slidesToShow: 1,
        // adaptiveHeight: true,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              dots: true,
              arrows: false
            }
          },
          {
            breakpoint: 1024,
            settings: {
              dots: false,
              arrows: true
            }
          }
        ]
      },
      slickOptionsColours: {
        autoplay: false,
        pauseOnDotsHover: true,
        infinite: true,
        speed: 500,
        cssEase: 'linear',
        swipeToSlide: true,
        slidesToShow: 3,
        slidesToScroll: 1
      },
      wishlist: {
        state: false
      }
    }
  },
  head: {
    title: function () {
      let title = ''
      if (Object.keys(this.item).length > 0) {
        title = this.designerName + ' ' + this.modelName
      }
      return {
        inner: title
      }
    }
  },
  updated () {
    if (this.selected.option > 0 && this.options[this.selected.option] &&
      this.swiperItem && this.changeSlick) {
      let slides = $('#item_images_block .swiper-slide:not(.swiper-slide-duplicate)').removeAttr('class').attr('class', 'swiper-slide')
      this.swiperItem.removeAllSlides()
      this.swiperItem.appendSlide(slides)
      this.swiperItem.update()
      this.swiperItem.slideTo(1, 100, false)
      this.changeSlick = false
      let self = this
      this.swiperItem.on('slideChange', function () {
        self.activeSwiperIndex = this.activeIndex
      })
    }
  },
  created () {
    this.fetchItem()
    if (this.item.def_option) {
      this.selected.option = this.item.def_option
      this.selected.frameSizeIndex = this.options[this.item.def_option].frame_sizes.findIndex(obj => obj.status === 'IN_STOCK')
    }
    // this.getWishlistStateItem(clearItemNumber)
  },
  mounted () {
    this.mounted = true
  },
  destroyed: function () {
    this.$store.dispatch('clearPromiseDataKey', 'storeItem')
  },
  asyncData ({store, router}) {
    return store.dispatch('fetchItem', router)
  },
  methods: {
    fetchItem () {
      return this.$store.dispatch('fetchItem', this.$router)
    },
    setSelectedOption (id) {
      this.selected.option = id
      this.changeSlick = true
    },
    setSelectedItem (id, e) {
      this.selected.item = id
      if (e) {
        e.preventDefault()
      }
    },
    setSwitchWishlist (id) {
      let params = {
        item: this.item.item_number,
        option: this.selected.option
      }
      let itemData = {
        id: id,
        type: 'pageitem',
        params: params
      }
      this.$store.dispatch('switchWishlist', itemData)
    },
    getWishlistStateItem (id) {
      let self = this
      this.$axios.post(this.apiHost + config.prefix + config.wishlist.existItemWishlist, {params: {id: id}}).then(function (response) {
        self.wishlist.state = response.data
      }, function (error) {
        console.log(error.statusText)
      })
    },
    reverseRouteName: function (str) {
      return reverseRouteName(str)
    },
    convertPrice: function (price) {
      try {
        return this.calculatePrice(price)
      } catch (e) {
        return parseFloat(this.exchange(price)).toFixed(2)
      }
    },
    animateBars: function () {
      this.$nextTick(function () {
        $('.progress-bar').each(function () {
          let maxAmount = $(this).attr('aria-valuenow')
          $(this).animate(
            {width: maxAmount},
            2000, 'linear'
          )
        })
      })
    }
  },
  watch: {
    'breadcrumbs': {
      handler: function (newVal, oldVal) {
        if (this.mounted && Object.keys(oldVal).length > Object.keys(newVal).length) {
          this.$store.dispatch('setBreadcrumbs', oldVal)
        }
      }
    },
    'item': {
      handler: function () {
        this.selected.option = this.item.def_option
        this.selected.frameSizeIndex = this.options[this.item.def_option].frame_sizes.findIndex(obj => obj.status === 'IN_STOCK')
        this.$emit('updateHead')
      },
      deep: true
    }
  }
}
