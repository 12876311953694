import ExcellentCarousel from '@/components/scripts/PageHeaderSSv4/ExcellentCarousel/index.vue'
import UpperBar from '@/components/scripts/PageHeaderSSv4/UpperBar/index.vue'
import LogoLine from '@/components/scripts/PageHeaderSSv4/LogoLine/index.vue'
import MessageBlock from '@/components/scripts/PageHeaderSSv4/MessageBlock/index.vue'
import DiscreteMessageBar from '@/components/scripts/PageHeaderSSv4/DiscreteMessageBar/index.vue'

export default {
  name: 'PageHeader',
  components: {
    ExcellentCarousel,
    UpperBar,
    DiscreteMessageBar,
    LogoLine,
    MessageBlock
  },
  computed: {
    showCarousel () {
      return ['Register', this.$rname('Login'), 'Reviews', 'SalePage'].indexOf(this.$route.name) < 0
    }
  }
}
