import Breadcrumbs from '@/components/scripts/Breadcrumbs/index.vue'
import DeliveryMain from '@/components/scripts/ProductionDeliveryPage/DeliveryMain/index.vue'
import InfoSidebar from '@/components/scripts/InfoSidebar/index.vue'

export default {
  name: 'ProductionDeliveryPage',
  components: {
    Breadcrumbs,
    DeliveryMain,
    InfoSidebar
  }
}
