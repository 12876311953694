import Breadcrumbs from '@/components/scripts/Breadcrumbs/index.vue'
import InfoMain from '@/components/scripts/InfoPage/InfoMain/index.vue'
import InfoSidebar from '@/components/scripts/InfoPage/InfoSidebar/index.vue'
import TrustpilotWidget from '@/components/scripts/TrustpilotWidget/index.vue'

export default {
  name: 'InfoPage',
  components: {
    Breadcrumbs,
    InfoMain,
    InfoSidebar,
    TrustpilotWidget
  }
}
