import { mapState } from 'vuex'

export default {
  name: 'SettingsForms',
  data () {
    return {
      sspayMode: false,
      selectedLang: 'en'
    }
  },
  computed: {
    ...mapState({
      apiHost: (state) => state.apiHost,
      currencies: (state) => state.currency.allCurrency,
      selectedCurrency: (state) => state.currency.selected,
      calculatePrice: (state) => state.calculatePrice
    }),
    loadedCurrencies () {
      return Object.values(this.currencies).length > 0
    },
    countriesSite () {
      return {
        en: {
          title: 'English',
          lang: 'en',
          link: '/'
        },
        de: {
          title: 'Deutsch',
          lang: 'de',
          link: '/de'
        },
        cn: {
          title: 'Chinese',
          lang: 'cn',
          link: '/cn'
        },
        ca: {
          title: 'Canadian',
          lang: 'ca',
          link: '/ca'
        },
        au: {
          title: 'Australian',
          lang: 'au',
          link: '/au'
        }
      }
    }
  },
  methods: {
    setCurrency (e) {
      this.$store.dispatch('setCurrency', e.target.value)
    },
    changeLanguage () {
      let lang = this.selectedLang
      let link = this.selectedLang === 'en' ? '/' : this.selectedLang
      console.log(lang, link)
      this.$i18n.locale = lang
      localStorage.setItem('lang', lang)
      this.$store.dispatch('changeLanguage', lang)
      document.location.href = this.$link(link)
    }
  }
}
