import { mapState } from 'vuex'
import $ from 'jquery'

export default {
  name: 'DiscreteMessageBar',
  computed: {
    ...mapState({
      currency: (state) => ({...state.currency.allCurrency[state.currency.selected]}),
      currencies: (state) => state.currency.allCurrency,
      geoInfo: (state) => state.geoInfo,
      // geoInfo: (state) => ({countryCode: 'CA'}),
      countries: (state) => state.countries
    }),
    geoCountry () {
      let country = null
      if (this.geoInfo && this.geoInfo.countryCode) {
        let countryCode = this.geoInfo.countryCode.toUpperCase()
        country = Object.values(this.countries).find(obj => obj && (obj.country_code_2 === countryCode || obj.country_code_3 === countryCode))
      }
      return country
    },
    currentCountryCode () {
      let lang = this.lang
      let code = null
      let idx = Object.values(this.allowedCustomers).findIndex(obj => obj.lang === lang)
      if (idx !== -1) {
        code = Object.keys(this.allowedCustomers)[idx]
      }
      return code
    },
    geoLang () {
      let lang = this.lang
      if (this.geoInfo && this.geoInfo.countryCode) {
        let countryCode = this.geoInfo.countryCode.toUpperCase()
        if (this.allowedCustomers[countryCode]) {
          lang = this.allowedCustomers[countryCode].lang
        }
      }
      return lang
    },
    isWrongLocation () {
      let isWrong = false
      if (this.geoInfo && this.geoInfo.countryCode) {
        let countryCode = this.geoInfo.countryCode.toUpperCase()
        isWrong = countryCode in this.allowedCustomers && countryCode !== this.currentCountryCode
      }
      return isWrong
    },
    showBlock () {
      let show = this.isWrongLocation
      if (this.$cookies.get('hideLocaleMessage')) {
        show = false
      }
      return false && show && this.hideMessageBlock === false
    }
  },
  data () {
    return {
      allowedCustomers: {
        GB: {
          lang: 'en'
        },
        UK: {
          lang: 'en'
        },
        US: {
          lang: 'us'
        },
        DE: {
          lang: 'de'
        },
        CA: {
          lang: 'ca'
        },
        AU: {
          lang: 'au'
        }
      },
      hideMessageBlock: false
    }
  },
  mounted () {
    if (this.geoCountry && this.showBlock) {
      this.$store.dispatch('setCurrency', this.geoCountry.currency_id)
    }
    let self = this
    $(document).on('click', '#toLocationSite', function () {
      let lang = self.geoLang
      console.log(lang)
      self.$i18n.locale = lang
      localStorage.setItem('lang', lang)
      self.$store.dispatch('changeLanguage', lang)
      setTimeout(function () {
        self.$cookies.set('hideLocaleMessage', 1, 60 * 60 * 12)
        self.hideMessageBlock = true
      }, 1000)
      document.location.href = self.$link('/')
      // self.$router.push({name: self.$rname('Main'), params: {lang: lang}})
    })
  },
  methods: {
    hideBlock () {
      if (this.isWrongLocation) {
        this.$cookies.set('hideLocaleMessage', 1, 60 * 60 * 12)
        this.hideMessageBlock = true
      }
    }
  },
  destroyed () {
    this.hideBlock()
  },
  watch: {
    '$route' (to, from) {
      this.hideBlock()
    },
    'geoLang': function () {
      if (this.geoCountry && this.showBlock) {
        this.$store.dispatch('setCurrency', this.geoCountry.currency_id)
      }
    }
  }
}

