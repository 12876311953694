import { render, staticRenderFns } from "@/components/templates/default/PageHeaderSSv4/LogoLine/index.pug?vue&type=template&id=3644c2ec&scoped=true&lang=pug&"
import script from "./index.js?vue&type=script&lang=js&"
export * from "./index.js?vue&type=script&lang=js&"
import style0 from "@/components/templates/default/PageHeaderSSv4/LogoLine/index.scss?vue&type=style&index=0&id=3644c2ec&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3644c2ec",
  null
  
)

component.options.__file = "index.vue"
export default component.exports