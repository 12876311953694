export default {
  name: 'pagination',
  computed: {
    disabledNext: function () {
      return this.$parent.pagination.skip + this.$parent.pagination.limit > this.$parent.items.total
    },
    disabledPrevious: function () {
      return this.$parent.pagination.skip === 0
    },
    routerNext: function () {
      return {
        name: this.$route.name,
        query: {
          ...this.$route.query,
          page: parseInt(this.$parent.pagination.currentPage) + 1
        },
        params: {lang: this.lang}
      }
    },
    routerPrevious: function () {
      return {
        name: this.$route.name,
        query: {
          ...this.$route.query,
          page: parseInt(this.$parent.pagination.currentPage) - 1
        },
        params: {lang: this.lang}
      }
    }
  }
}
