import Breadcrumbs from '@/components/scripts/Breadcrumbs/index.vue'
import OffersModal from '@/components/scripts/Products/CheapGlasses/OffersModal/index.vue'
import Pagination from '@/components/scripts/Pagination/index.vue'
import TrustpilotWidget from '@/components/scripts/TrustpilotWidget/index.vue'
import ShareButtons from '@/components/scripts/ShareButtons/index.vue'

import { mapState } from 'vuex'
import config from '@/../config'

import cheapGlassesInit from '../../../../store/cheapGlasses'

export default {
  name: 'CheapGlasses',
  data () {
    return {
      cdnUrlPrefix: config.cdnUrlPrefix,
      layout: false,
      modalData: {},
      requestOptions: {
        headers: {
          'X-Requested-With': 'XMLHttpRequest'
        },
        emulateJSON: true
      },
      ...cheapGlassesInit,
      scrollLoad: false
    }
  },
  components: {
    Breadcrumbs,
    OffersModal,
    Pagination,
    TrustpilotWidget,
    ShareButtons
  },
  computed: {
    ...mapState({
      apiHost: (state) => state.apiHost,
      breadcrumbs: (state) => state.breadcrumbs,
      backRoute: (state) => state.backRoute,
      routerObj: (state) => state.pageMenuDescription,
      currency: (state) => ({...state.currency.allCurrency[state.currency.selected]}),
      exchange: (state) => state.currency.exchange,
      exchangeBack: (state) => state.currency.exchangeBack,
      vat: (state) => state.vat,
      lang: (state) => state.lang,
      catalogue: (state) => state.promiseData && state.promiseData.cheapGlasses ? state.promiseData.cheapGlasses : {},
      loading: (state) => state.loading
    }),
    calculatedItems: function () {
      if (this.catalogue) {
        let self = this
        Object.keys(this.catalogue).map(key => {
          if (self[key]) {
            self[key] = self.catalogue[key]
          }
        })
      }
      return {
        ...this.items
      }
    }
  },
  head: {
    title: function () {
      let title = 'SelectSpecs'
      if (this.routerObj.page && this.routerObj.page.translations) {
        title = this.$tf(this.routerObj.page.translations, 'meta_title')
      }
      if (this.routerObj.catalogue && this.routerObj.catalogue.translations) {
        title = this.$tf(this.routerObj.catalogue.translations, 'meta_title')
      }
      title = title !== null && title.length > 0 ? this.convertMenuContext(title) : 'SelectSpecs'
      const textarea = document.createElement('div')
      textarea.innerHTML = title
      title = textarea.innerText
      return {
        inner: title
      }
    },
    link: function () {
      let link = window.location.protocol + '//' + window.location.host + window.location.pathname
      let links = [
        {
          rel: 'canonical',
          href: link
        },
        {
          rel: 'next',
          href: link + '?page=' + (parseInt(this.pagination.currentPage) + 1),
          id: 'next'
        }
      ]
      if (parseInt(this.pagination.currentPage) > 1) {
        links.push({
          rel: 'prev',
          href: link + '?page=' + (parseInt(this.pagination.currentPage) - 1),
          id: 'prev'
        })
      }
      return links
    }
  },
  mounted () {
    if (this.$route.query.page) {
      this.pagination.currentPage = this.$route.query.page
      this.pagination.skip += (this.pagination.currentPage - 1) * this.pagination.limit
    }
    this.loadItems()
  },
  asyncData ({store}) {
    return store.dispatch('loadCheapGlasses', {append: 0})
  },
  created () {
    document.body.addEventListener('scroll', this.handleScroll)
  },
  destroyed: function () {
    this.$store.dispatch('clearPromiseDataKey', 'cheapGlasses')
    document.body.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    convertMenuContext (context) {
      return this.convertContext(context)
    },
    loadItems (append = false) {
      this.$store.dispatch('loadCheapGlasses', {append})
    },
    handleScroll (event) {
      let self = this
      let el = document.querySelector('.shop-item:last-child')
      if (el && !this.loading && !this.scrollLoad && document.body.scrollTop >= el.offsetTop - el.offsetHeight) {
        if (this.pagination.skip + this.pagination.limit < this.calculatedItems.total) {
          this.pagination.skip += this.pagination.limit
          self.scrollLoad = true
          this.loadItems(true).then(() => { self.scrollLoad = false })
        }
      }
    },
    chooseLenses (id) {
      console.log('choose Lenses:::', id)
      let item = this.calculatedItems.data[id]
      let option = Object.values(item.options)[0]
      let frameSize = option.frame_sizes[0]
      let data = {
        'item_number': item.item_number,
        'quantity': 1,
        'supplier_name': item.supplier_name,
        'ordered_item_type': 'ordinary',
        'option': {
          order: option.option_order,
          price_retailer: option.price_retailer,
          price_old: option.price_old,
          price: option.price,
          status: option.status,
          is_out_of_stock: option.is_out_of_stock,
          featured: option.featured,
          discontinued: option.discontinued,
          name: option.name,
          description: option.description,
          lang: option.lang,
          image_src: option.thumbnail_images.length > 0 ? option.thumbnail_images[0].src : null,
          image_type: option.thumbnail_images.length > 0 ? 'THUMBNAIL' : null,
          image_order: option.thumbnail_images.length > 0 ? option.thumbnail_images[0].image_order : null,
          eld: frameSize.eld,
          gtin: frameSize.gtin,
          arm: frameSize.arm,
          bridge: frameSize.bridge,
          lens: frameSize.lens,
          height: frameSize.height,
          disk: frameSize.disk,
          back: frameSize.back,
          stock: frameSize.stock,
          frame_status: frameSize.status,
          frame_number: frameSize.fame_number,
          special_name: option.option_specials.special_name,
          special_discount: option.option_specials.special_discount,
          special_discount_percent: option.option_specials.special_discount_percent,
          option_number: option.option_number
        }
      }
      this.$store.dispatch('addItemToBasket', data)
    },
    showFullsizeImage (id) {
      let item = this.calculatedItems.data[id]
      this.modalData = item.options[item.def_option]
    },
    convertPrice: function (price) {
      try {
        return this.calculatePrice(price)
      } catch (e) {
        return parseFloat(this.calculatePrice(price)).toFixed(2)
      }
    }
  },
  watch: {
    'pagination.currentPage': {
      handler: function () {
        this.loadItems()
      },
      deep: true
    },
    'routerObj': {
      handler: function () {
        this.loadItems()
      },
      deep: true
    }
  }
}
