/* eslint no-undef: 0 */
const axios = require('axios').default
const config = require('../../config/index')
const polyfill = require('es6-promise').polyfill
const axiosCancel = require('axios-cancel').default
const { Log } = require('.//logger')()
const {parseCookieJarToString, parseCookieJar} = require('./../../config/helper')
require('axios-debug-log')
const httpHandler = {
  defLocale: (typeof localStorage != typeof undefined) && localStorage.getItem('lang') ? localStorage.getItem('lang') : config.defLocale
}
const getIsServer = () => {
  return global && global['process'] && global['process'].env && global['process'].env.VUE_ENV === 'server'
}

function initAxios () {
  const currentAxios = getAxios()
  polyfill()
  currentAxios.defaults.headers.common['Accept-Language'] = httpHandler.defLocale
  currentAxios.defaults.withCredentials = true
  axiosCancel(currentAxios, {
    debug: false
  })
}

function getAxios () {
  return (typeof window != typeof undefined) && window.axios || axios
}

initAxios()

module.exports.createHTTPClient = (setBase = true) => {
  let httpConfig = {}
  if (setBase) {
    httpConfig.baseURL = config.apiHost + config.prefix
  }
  const http = getAxios().create(httpConfig)

  http.interceptors.request.use(config => {
    if (getIsServer() && global.toughCookie && global.cookieJar) {
      const url = config.url.match(/^https?:\/\//) ? config.url : (config.baseURL + config.url)
      const domain = url.match(/^https?:\/\/([^/]+)\/?/)
      config.headers = {
        Cookie: parseCookieJarToString(global.cookieJar, domain[1])
      }
      const remoteAddress = getRemoteAddress()
      let isObjectBody = false
      try {
        isObjectBody = config.data instanceof Object || ((typeof config.data === 'string') && (JSON.parse(config.data) instanceof Object))
      } catch (e) {
        isObjectBody = false
      }
      if (remoteAddress) {
        config.headers['CF-Connecting-IP'] = remoteAddress
      }
      config.headers['X-Requested-With'] = 'XMLHttpRequest'
      if (config.method.toLowerCase() === 'post' && !Object.keys(config.headers).find(key => key.toLowerCase() === 'content-type')) {
        config.headers['Content-Type'] = isObjectBody ? 'application/json;charset=UTF-8' : 'application/x-www-form-urlencoded'
      }
      config.headers['Accept-Language'] = httpHandler.defLocale
      const cookie = parseCookieJar(global.cookieJar, domain[1])
      const logger = new Log()
      logger.sendToServer({
        'axios request': {
          cookie,
          url,
          domain: domain[1],
          remoteAddress,
          config
        }
      })
    }

    return config
  }, err => Promise.reject(err))

  http.interceptors.response.use(res => {
    if (getIsServer() && global.toughCookie && global.cookieJar && res.headers['set-cookie']) {
      let cookies = []
      if (res.headers['set-cookie'] instanceof Array) {
        cookies = res.headers['set-cookie']
      } else {
        cookies = [res.headers['set-cookie']]
      }
      const logger = new Log()
      logger.sendToServer({
        'axios response': {
          cookie: cookies,
          config: res.config
        }
      })
      cookies.map(header => {
        let cookie = global.toughCookie.parse(header, {loose: true})
        global.cookieJar.setCookie(cookie, res.config.url, {loose: true}, (err) => {
          if (err) {
            logger.sendToServer({
              'response cookie error': {
                err: err.message || err.toString()
              }
            })
          }
        })
      })
    }
    return res
  }, err => Promise.reject(err))

  return http
}
