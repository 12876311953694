import PromoModal from '@/components/scripts/PageHeaderSSv4/UpperBar/PromoModal/index.vue'
import { mapState } from 'vuex'

export default {
  name: 'UpperBar',
  components: {PromoModal},
  computed: {
    ...mapState({
      apiHost: (state) => state.apiHost,
      currencies: (state) => state.currency.allCurrency,
      selectedCurrency: (state) => state.currency.selected,
      calculatePrice: (state) => state.calculatePrice
    }),
    selectedCountryImage () {
      return this.countriesSite[this.lang] ? this.countriesSite[this.lang].image : ''
    },
    loadedCurrencies () {
      return Object.values(this.currencies).length > 0
    },
    countriesSite () {
      return {
        en: {
          title: 'English',
          lang: 'en',
          image: this.confCdnUrl + '/res/images/_png/flags/uk.png',
          link: this.getSSv4Link('/')
        },
        de: {
          title: 'Deutsch',
          lang: 'de',
          image: this.confCdnUrl + '/res/images/_png/flags/de.png',
          link: this.getSSv4Link('/de')
        },
        cn: {
          title: 'Chinese',
          lang: 'cn',
          image: this.confCdnUrl + '/res/images/_png/flags/cn.png',
          link: this.getSSv4Link('/cn')
        },
        ca: {
          title: 'Canadian',
          lang: 'ca',
          image: this.confCdnUrl + '/res/images/_png/flags/ca.png',
          link: this.getSSv4Link('/ca')
        },
        au: {
          title: 'Australian',
          lang: 'au',
          image: this.confCdnUrl + '/res/images/_png/flags/au.png',
          link: this.getSSv4Link('/au')
        }
      }
    }
  },
  methods: {
    setCurrency (e) {
      this.$store.dispatch('setCurrency', e.target.value)
    },
    changeLanguage (lang) {
      this.$i18n.locale = lang
      localStorage.setItem('lang', lang)
      this.$store.dispatch('changeLanguage', lang)
      document.location.href = this.$link('/')
    }
  }
}
