import { mapState } from 'vuex'
import config from '@/../config'

export default {
  name: 'Breadcrumbs',
  computed: {
    ...mapState({
      breadcrumbs: (state) => state.breadcrumbs
    })
  },
  data () {
    return {
      mounted: false
    }
  },
  mounted () {
    this.mounted = true
  },
  methods: {
    rememberRoute (to) {
      let params = {}
      if (typeof to === 'object' && to.params) {
        params = to.params
      }
      this.$store.dispatch('setRouterBack', {path: this.$route.fullPath, params: params})
    },
    convertLink (link) {
      if (typeof link === 'object') {
        if (!this.mounted) {
          const {resolved} = this.$router.resolve({...link})
          return resolved.fullPath
        }
        return link
      }
      link = link.replace(new RegExp('^' + config.subDomain + '/', 'g'), '/').replace(new RegExp('/{2,}', 'g'), '/')
      return this.$link(link)
    },
    convertTitle (title) {
      if (title) {
        const textarea = document.createElement('div')
        textarea.innerHTML = title
        let text = textarea.innerText ? textarea.innerText : (textarea.textContent ? textarea.textContent : title)
        return text.toUpperCase()
      }
      return ''
    }
  }
}
