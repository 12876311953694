/* eslint no-unneeded-ternary: ["error", { "defaultAssignment": true }] */
import config from '@/../config'

export function translateComponent (key) {
  let context
  if (arguments[1] && arguments[2]) {
    context = this.$i18n.t(key, arguments[1], arguments[2])
  } else if (arguments[1]) {
    context = this.$i18n.t(key, arguments[1])
  } else {
    context = this.$i18n.t(key)
  }

  context = context.replace(/(href=['"])\{\{(.+)\}\}(['"])/, ':$1$2$3')
  let opts = this.$options
  let component = {
    name: key,
    template: '<span>' + context + '</span>'
  }
  if (opts.data) {
    component.data = opts.data
  }
  if (opts.computed) {
    component.computed = {
      ...opts.computed
    }
  }
  if (opts.methods) {
    component.methods = opts.methods
  }
  return component
}

export function translate (key) {
  let context
  if (arguments[1] && arguments[2]) {
    context = this.$i18n.t(key, arguments[1], arguments[2])
  } else if (arguments[1]) {
    context = this.$i18n.t(key, arguments[1])
  } else {
    context = this.$i18n.t(key)
  }
  let convert = convertContext.bind(this)
  return convert(context)
}

export function translateField (field, key) {
  let lang = this.lang ? this.lang : config.defLocale
  let result = ''
  if (field) {
    result = field[lang] ? field[lang][key] : (field[config.defLocale] ? field[config.defLocale][key] : '')
  }
  return result
}

export function convertContext (context) {
  let options = this
  if (this.$options && this.$options.propsData && this.$options.parent && this.$options.parent.$options) {
    options = this.$options.parent.$options
  }
  try {
    context = context.replace(/(href=['"])\{\{(.+)\}\}(['"])/, 'v-bind:$1$2$3')
    const el = document.createElement('div')
    const vm = new this.$root.constructor({
      ...options,
      template: '<span>' + context + '</span>'
    })
    vm.$mount(el)
    let result = vm.$el.innerHTML
    vm.$destroy()
    return result ? result : context
  } catch (e) {
    console.log('error translate::', e)
    return context
  }
}
