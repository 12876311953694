export async function createApp (context) {
  const VueFunc = context.isServer ? require('@vue/test-utils').createLocalVue() : require('vue')
  const VueResource = require('vue-resource')
  const VueI18n = require('vue-i18n')
  const VueAwesomeSwiper = require('vue-awesome-swiper')
  const VueCookies = require('vue-cookies')
  const App = require('./App.vue').default
  const { sync } = require('vuex-router-sync')
  const { createRouter } = require('./router')
  const { createStore } = require('./store')
  const storeVue = require('./store/storeVue').default
  const { createHTTPClient } = require('./utils/http')
  const { createI18n } = require('./utils/i18n')
  const mixin = require('./utils/mixins').default
  const config = require('./../config')
  const Vuetify = require('vuetify')

  // Global Vue events. Use: window.globalEvents.$emit('event', 'eventParams') and window.globalEvents.$on('event',() => {// Do something}))
  window.globalEvents = new VueFunc()

  const initVue = (VueFunc) => {
    const defLocale = localStorage && localStorage.getItem('lang') ? localStorage.getItem('lang') : config.defLocale
    VueFunc.use(VueI18n)
    VueFunc.use(VueResource)
    VueFunc.use(VueAwesomeSwiper)
    VueFunc.use(VueCookies)
    VueFunc.use(Vuetify)
    VueFunc.http.headers.common['Accept-Language'] = defLocale
    VueFunc.config.productionTip = false
    VueFunc.config.silent = true
    VueFunc.mixin(mixin)
    VueFunc.prototype.$axios = createHTTPClient(false)

    return VueFunc
  }

  require('bootstrap')
  require('tether')
  if (!context.isServer) {
    require('html5shiv')
    // require('jquery.rateit')
    require('slick-carousel')
  }

  const Vue = initVue(VueFunc)
  async function bootstrap (Vue) {
    const store = createStore(Vue)
    const router = await createRouter(Vue)

    router.beforeEach((to, from, next) => {
      const {path} = to
      let menuData = {
        link: path
      }

      let lang = config.defLocale
      if (to.params.lang) {
        lang = to.params.lang
      } else if (to.meta.lang) {
        lang = to.meta.lang
      }
      menuData.link = menuData.link.replace(new RegExp('^' + config.subDomain + '($|/)', 'g'), '/').replace(new RegExp('/{2,}', 'g'), '/')
      store.dispatch('changeLanguage', lang)
      if (from.path !== path) {
        store.dispatch('getMenuDescription', [menuData, to])
      }

      next()
    })

    /*
    router.beforeEach((to, from, next) => {
      function proceed () {
        if (to.matched.some(record => record.meta.requiresAuth)) {
          if (store.state.authorization === 'unauthorized') {
            next('/')
          } else {
            next()
          }
        } else {
          next()
        }
      }
      if (store.state.loading) {
        store.watch(
          (state) => state.loading,
          (value) => {
            if (value === false) {
              proceed()
            }
          })
      } else {
        proceed()
      }
    })
    */

    const unwatch = sync(store, router)
    return {store, router, unwatch}
  }
  const {store, router, unwatch} = await bootstrap(Vue)

  const i18n = await createI18n(context, VueI18n)

  store.dispatch('clearCookies')
  if (context.cookies) {
    store.dispatch('setCookies', context.cookies)
  }

  let remoteAddress = context.remoteAddress ? context.remoteAddress : null

  store.dispatch('changeLanguage', context.locale)
  if (context.isServer) {
    await store.dispatch('checkUser')
    await Promise.all([
      store.dispatch('setHeaders', context.headers),
      store.dispatch('loadAllCurrencies'),
      store.dispatch('loadCountries'),
      store.dispatch('loadGeoIp', remoteAddress),
      store.dispatch('getBasket'),
      (!store.state.isMobile && store.dispatch('loadMenus') || store.dispatch('loadMobileMenu')),
      store.dispatch('loadItemsWishlist')
    ])
  } else {
    await store.dispatch('checkUser')
    await Promise.all([
      store.dispatch('loadAllCurrencies'),
      store.dispatch('loadCountries'),
      store.dispatch('loadGeoIp'),
      store.dispatch('getBasket'),
      (!store.state.isMobile && store.dispatch('loadMenus') || store.dispatch('loadMobileMenu')),
      store.dispatch('loadItemsWishlist')
    ])
  }
  const app = new Vue({
    ...storeVue,
    router,
    i18n,
    store,
    // the root instance simply renders the App component.
    render: h => h(App)
  })

  if (context.isServer) {
    app.$cookies.keys().map(key => app.$cookies.remove(key))
  }

  if (context.cookies) {
    Object.keys(context.cookies).map(key => {
      app.$cookies.set(key, context.cookies[key])
    })
  }

  return {app, store, router, unwatch}
}
