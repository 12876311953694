import Breadcrumbs from '@/components/scripts/Breadcrumbs/index.vue'
import FaceMain from '@/components/scripts/ChooseByFaceShapePage/FaceMain/index.vue'
import InfoSidebar from '@/components/scripts/InfoSidebar/index.vue'

export default {
  name: 'ChooseByFaceShapePage',
  components: {
    Breadcrumbs,
    FaceMain,
    InfoSidebar
  }
}
