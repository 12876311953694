import { render, staticRenderFns } from "@/components/templates/default/Checkout/Amazon/index.html?vue&type=template&id=923d4ab4&scoped=true&lang=pug&"
import script from "./index.js?vue&type=script&lang=js&"
export * from "./index.js?vue&type=script&lang=js&"
import style0 from "@/components/templates/default/Checkout/Amazon/index.scss?vue&type=style&index=0&id=923d4ab4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "923d4ab4",
  null
  
)

component.options.__file = "index.vue"
export default component.exports