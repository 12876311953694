import {mapState} from 'vuex'
import $ from 'jquery'
import config from '@/../config'
import {iframeResizer} from 'iframe-resizer'

export default {
  name: 'PaymentFrame',
  data () {
    return {
      msg: 'Payments Frame',
      iframeUrl: '',
      canShowFrame: false,
      frameLoaded: false,
      googleLock: false,
      activeMethod: null,
      sandboxFrame: '',
      errors: null,
      paymentMethods: {
        WorldPay: '',
        PayPal: '',
        Klarna3: '',
        Cheque: ''
      },
      paymentBlock: {},
      requestOptions: {
        headers: {
          'X-Requested-With': 'XMLHttpRequest'
        },
        emulateJSON: true,
        withCredentials: true
      },
      iframeOptions: {
        log: false,
        checkOrigin: false,
        inPageLinks: true,
        autoResize: false,
        sizeHeight: true,
        resizeFrom: 'child'
      }
    }
  },
  props: [
    'order_hash',
    'sandbox',
    'payment_system_number',
    'allowGetPaymentUrl',
    'deliveryAddress',
    'send_get',
    'send_post',
    'restrictDelivery',
    'privacyChecked',
    'is_express'
  ],
  computed: {
    ...mapState({
      apiHost: (state) => state.apiHost,
      integrationHost: (state) => state.integrationHost
    })
  },
  watch: {
    payment_system_number: function () {
      this.getLink()
    },
    sandbox: function () {
      this.getLink()
    },
    activeMethod: {
      handler: function (changed) {
        console.log(this.activeMethod)
      },
      deep: true
    },
    allowGetPaymentUrl () {
      setTimeout(this.getLink, 1000)
    }
  },
  methods: {
    getLink: function () {
      var self = this
      // const recalcDelivery = Vue.resource(this.apiHost + config.prefix + config.checkout.getPaymentUrl + (this.$route.query.sspay ? '-sspay' : ''))
      // recalcDelivery.get({
      //   'order_hash': this.order_hash,
      //   'ps': this.payment_system_number,
      //   'sb': this.sandbox
      // })
      this.send_get(config.checkout.getPaymentUrl + (this.$route.query.sspay ? '-sspay' : ''), {
        params: {
          'order_hash': this.order_hash,
          'ps': this.payment_system_number,
          'sb': this.sandbox,
          'ap': window.ApplePaySession
        }
      }).then(function (resp) {
        if (resp.data.result && resp.data.result !== 'false') {
          // self.iframeUrl = resp.data.url
          self.iframeHidden = resp.data
          self.paymentMethods = resp.data.paymentMethods
          self.paymentBlock = resp.data.paymentBlock
          self.sandboxFrame = resp.data.paymentMethods.worldpay ? 'allow-forms allow-scripts allow-same-origin allow-top-navigation allow-modals' : ''
          self.frameLoaded = true
          self.errors = null
        } else {
          self.frameLoaded = true
          self.errors = resp.data.other_errors || null
          console.log(resp.data.errors || null)
          console.log(resp.data.other_errors || null)
          self.sendError(config.checkout.sendError, {
            'error_on': 'Generate frame',
            'error': resp.data.other_errors || null
          }, self.$route.query.sspay ? self.integrationHost : false)
        }
      }, (err) => (console.log(err)))
    },
    sendError: function (url, error, host) {
      error['url'] = window.location.href
      error['browser'] = window.navigator.userAgent
      console.log(url, error, host)
      this.send_post(url, error, host)
    },
    injectCode: function (selector) {
      if (!this.googleLock && $('div').is('.block')) {
        this.googleLock = true
        $('#googleLoader').remove()
        $('#google-container').remove()
        let snippet = this.paymentBlock[selector]
        console.log('#' + selector)
        $('#' + selector).append($(snippet))
        $('#' + selector).append('<div id="googleLoader"><script>setTimeout(()=>(onGooglePayLoaded()), 1000);</script></div>')
      }
      return true
    },
    onChangeMethod: function (meth) {
      let self = this
      if (this.restrictDelivery) {
        return
      }
      this.canShowFrame = false
      this.activeMethod = meth
      setTimeout(() => {
        // Vue.http.post(this.integrationHost + config.integrationPrefix + '/checkout/checkout/change-payment-system/', {
        //   'order_hash': this.order_hash,
        //   'payment_system': meth
        // }, this.requestOptions)
        this.send_post('/checkout/checkout/change-payment-system', {
          order_hash: this.order_hash,
          payment_system: meth
        }, self.$route.query.sspay ? self.integrationHost : false)
          .then((resp) => {
            this.canShowFrame = true
            setTimeout(function () {
              iframeResizer(this.iframeOptions, '#paymentIframe' + meth)
              $('#paymentIframe' + meth).prev().remove()
            }, 1000)
            // console.log(resp.data)
          }, (err) => {
            this.canShowFrame = true
            setTimeout(function () {
              iframeResizer(this.iframeOptions, '#paymentIframe' + meth)
              $('#paymentIframe' + meth).prev().remove()
            }, 1000)
            setTimeout(() => {
              let iframe = document.getElementById('paymentIframe' + meth)
              if (iframe.style.display === 'none' || iframe.style.display === 'hidden' || iframe.style.visibility === 'hidden' || iframe.offsetHeight === 0) {
                alert(this.$trans('3rdPartyMessage'))
              }
            }, 1800)
            console.log('Error:', err)
          })
      }, 100)
    },
    showDisabledAlert: function (error) {
      let alertText = ''

      function parseAlertText (str) {
        return $('<textarea />').html(str).text()
      }

      if (!this.privacyChecked && !this.restrictDelivery) {
        alertText = this.$t('TCPPrivacyAlert')
      } else if (this.restrictDelivery && this.privacyChecked) {
        alertText = this.$t('RestrictItemsAlert')
      } else if (this.restrictDelivery && !this.privacyChecked) {
        alertText = this.$t('RestrictItemsPrivacyAlert')
      }
      alert(parseAlertText(alertText) || error)
      if (this.restrictDelivery) {
        this.pulsateRestrictBox()
      }
    },
    pulsateRestrictBox: function () {
      const deviceWidth = $(window).width()
      let blockToPulsate = $('.checkout-item.error-wrapper')

      function addPulsation () {
        blockToPulsate.addClass('pulsate')
        blockToPulsate.on('animationend', function () {
          blockToPulsate.removeClass('pulsate')
        })
      }

      if (deviceWidth < 1024) {
        $('html, body').animate({
          scrollTop: $('#miniCartMobile').find(blockToPulsate).offset().top - 16
        }, 500, addPulsation())
      } else {
        addPulsation()
      }
    }
  },
  created: function () {
    if (!this.$route.query.sspay) {
      this.canShowFrame = true
    }
    setTimeout(this.getLink, 1000)
  }
}

