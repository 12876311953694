import {mapActions, mapState} from 'vuex'
import * as types from '@/store/types'

export default {
  name: 'AddressBlock',
  props: {
    profile: {
      required: true,
      type: Object
    },
    address: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      editingMode: false,
      newAddress: {}
    }
  },
  computed: {
    addressType: function () {
      if (this.address) {
        return this.address.address_type
      }
    },
    thisFormName: function () {
      return `${this.addressType}Edit`
    },
    ...mapState({
      apiHost: (state) => state.apiHost,
      auth: (state) => state.authorization,
      user: (state) => ({
        ...state.user
      }),
      countries: (state) => Object.values(state.countries),
      meta: function (state) {
        return {
          ...state.formMeta[this.thisFormName]
        }
      }
    })
  },
  methods: {
    ...mapActions({
      onChange: 'onChangeForm'
    }),
    countryName (id) {
      return this.countries.find(i => i.number === id).translation
    },
    openEdit () {
      this.editingMode = true
      this.newAddress = this.address
    },
    checkFields () {
      const FORM_NAME = this.thisFormName
      let validated = true

      this.$store.commit(types.FORM_FIELD_CLEAR, {formName: FORM_NAME})

      Object.keys(this.newAddress).forEach((field) => {
        if (field !== 'line2' && this.newAddress[field] == false) {
          this.$store.commit(types.FORM_ERROR_FIELD, {
            msg: 'Please fill in the field',
            field,
            formName: FORM_NAME
          })
          validated = false
        }
      })
      return validated
    },
    submitAddressChange () {
      if (this.checkFields()) {
        return this.$store.dispatch('onUpdateAddress', this.newAddress)
          .then(() => (this.editingMode = false))
      }
    }
  },
  created () {
  },
  mounted () {
  },
  updated () {
  }
}
