module.exports = () => {
  const debug = require('debug')('server-debug')
  const error = debug

  const profileMemory = (init = false) => {
    const mem = process.memoryUsage()
    if (!init) {
      return mem
    }

    return {
      rss: mem.rss - init.rss,
      heapTotal: mem.heapTotal - init.heapTotal,
      heapUsed: mem.heapUsed - init.heapUsed,
      external: mem.external - init.external
    }
  }

  class Log {
    constructor () {
      this.requestMemory = null
    }

    send (msg, profile = true) {
      if (process.env.NODE_ENV !== 'production') {
        debug(JSON.stringify(profile ? {
          'memory': (this.requestMemory = profileMemory(this.requestMemory)),
          'process': process.pid,
          'point': msg
        } : msg, null, 4))
      }
    }

    debug (msg) {
      this.send(msg)
    }

    error (err) {
      error(JSON.stringify({
        'memory': (this.requestMemory = profileMemory(this.requestMemory)),
        'process': process.pid,
        'message': err
      }, null, 4))
    }

    sendToServer (msg) {
      if (process.env.NODE_ENV !== 'production') {
        process.send(JSON.stringify({
          debug: {
            data: msg,
            process: process.pid
          }
        }))
      }
    }
  }

  return {
    Log
  }
}
