import { mapState } from 'vuex'

export default {
  name: 'AccountHeader',
  computed: {
    ...mapState({
      profile: function (state) {
        return {...state.user.profile}
      }
    }),
    username: function () {
      return this.profile.first_name
    },
    currentPageTitle: function () {
      let matchedRoute = [...this.$route.matched].reverse()
      let thisRouteObject = matchedRoute.find((r) => r.path.match(this.$route.regex))
      return thisRouteObject.props.default.titleGettext
    }
  },
  methods: {
    onLogout (e) {
      e.preventDefault()
      this.$store.dispatch('onLogout', this.$router)
      this.$cookies.remove('token', null, 'ssfront-dev.ssyii.com')
    }
  }
}
