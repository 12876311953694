import pagination from './pagination'

export default {
  items: {
    data: [],
    total: 0
  },
  pagination: {
    ...pagination
  }
}
