import { mapState } from 'vuex'
import PageHeader from '@/components/scripts/PageHeader/index.vue'
import PrintPage from '@/components/scripts/Checkout/PrintPage/index.vue'
import Step from '@/components/scripts/Step/index.vue'
import TrustpilotWidget from '@/components/scripts/TrustpilotWidget/index.vue'
import PageFooter from '@/components/scripts/PageFooter/index.vue'
import ScrollToTop from '@/components/scripts/ScrollToTop/index.vue'
import config from '@/../config'
import axios from 'axios/index'
import script from '@/utils/script'
import { currentCountry } from '@/../config/helper'

export default {
  name: 'thank',
  data () {
    return {
      msg: 'Thank you page',
      confirmPassword: '',
      newPassword: '',
      disableButton: false,
      order: {},
      messageError: '',
      messageInfo: '',
      printPage: false,
      confirmSaveDetails: false,
      sspayMode: false,
      errorData: null,
      chck: false,
      printMode: false,
      checkGuestData: false,
      checkGuestDataOnPageLoad: null
    }
  },
  computed: {
    ...mapState({
      apiHost: (state) => state.apiHost,
      regData: (state) => state.regData,
      integrationHost: (state) => state.integrationHost,
      currency: (state) => ({...state.currency.allCurrency[state.currency.selected]}),
      exchange: (state) => state.currency.exchange,
      currencyLoading: (state) => state.currencyLoading
    }),
    httpClient: function () {
      return axios.create({
        // baseURL: config.apiHost + config.prefix
      })
    },
    errorInputClass () {
      return {
        'form-error': this.messageError
      }
    },
    warningInputClass () {
      return {
        'form-warning': this.messageInfo
      }
    }
  },
  components: {
    PageHeader,
    Step,
    TrustpilotWidget,
    PageFooter,
    ScrollToTop,
    PrintPage
  },
  methods: {
    sendError: function (url, error, host) {
      error['url'] = window.location.href
      error['browser'] = window.navigator.userAgent
      this.send_post(url, error, host)
    },
    fetchOrder: function (orderId) {
      console.log('OrderId: ', orderId)
      let url = config.checkout.fetchOrderUrl
      return this.send_get(url, {
        params: {
          'order_id': orderId,
          'check': 0
        }
      }, this.sspayMode ? this.integrationHost : false)
        .then((resp) => {
          if (!this.order.number) {
            this.sendError(config.checkout.sendError, {
              'error_on': 'fetchOrder THANK Empty order number ORAND other fields',
              'error': this.order
            }, 'https://www.selectspecs.com')
          }
          if (!resp.data.status && resp.data.error) {
            window.location.href = 'https://' + window.location.host + '/404/'
            return
          }
          this.order = resp.data
          if (!this.$route.query.nc) {
            this.send_post('/checkout/checkout/complete', {
              order_id: this.order.hash,
              paymentStatus: 'waiting'
            }, (this.$route.query.sspay ? this.integrationHost : false))
            this.removeCookie('order_hash')
          }
          // this.$store.dispatch('clearBasket')
          this.checkGuest()
          try {
            const testLocal = (process && process.env && process.env.NODE_ENV && process.env.NODE_ENV !== 'local') || !process || !process.env
            setTimeout(function () {
              Promise.all([
                testLocal && script.loadScript('getGa', null, this.domain ? 'GTM-PC8DBCW' : null),
                testLocal && script.loadScript('getEmarsys'),
                testLocal && script.loadScript('getFbPixel')
              ]).then(() => {
                this.gaLoaded = true
              })
            }, 1000)
          } catch (err) { console.log(err) }
        }, (err) => {
          this.showLoader = false
          this.errorData = err.response && err.response.data && err.response.data.service && err.response.data.service[0] ? err.response.data.service[0] : 'Order not found'
          this.sendError(config.checkout.sendError, {
            'error_on': 'fetchOrder THANK error',
            'error': err
          }, false)
          console.log(err)
        })
    },
    removeCookie (name) {
      document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
    },
    fetchCountry (orderId) {
      const countryData = this.$root.constructor.resource((this.$route.query.sspay ? this.integrationHost : this.apiHost) + config.prefix + config.checkout.getCountryListUrl + (this.$route.query.sspay ? '/' : ''))
      countryData.get({}).then((resp) => (this.countryList = resp.data), (err) => (console.log(err)))
    },
    redirectPartn () {
      window.location.href = `https://cleareyetest.io/partner?partnerId=B0781CA1-47C1-417A-8DBF-2C1308C94B49&partnerAccountId=${this.order.profile_number}`
    },
    checkGuest: function () {
      if (this.sspayMode) {
        this.checkGuestDataOnPageLoad = this.order.profile && this.order.profile.Role && this.order.profile.Role.name && this.order.profile.Role.name === 'guest'
        this.checkGuestData = this.order.profile && this.order.profile.Role && this.order.profile.Role.name && this.order.profile.Role.name === 'guest'
      } else {
        this.checkGuestData = this.order.user_number === 1
      }
    },
    send_post: function (module, data, apiHost) {
      apiHost = apiHost || false
      let prefix = apiHost ? config.integrationPrefix : config.prefix
      let host = apiHost || this.apiHost
      this.debugLog('send_post: ' + module, {'host': host, 'module': module, 'data': data})
      // return Vue.http.post(host + prefix + module + (apiHost ? '/' : ''), data, this.requestOptions)
      var qs = require('qs')
      return this.httpClient.post(host + prefix + module + (apiHost ? '/' : ''), qs.stringify(data), {
        withCredentials: true,
        headers: {'Content-Type': 'application/x-www-form-urlencoded', 'Access-Control-Allow-Credentials': true}
      })
    },
    send_get: function (module, data, apiHost) {
      apiHost = apiHost || false
      let prefix = apiHost ? config.integrationPrefix : config.prefix
      let host = apiHost || this.apiHost
      this.debugLog('send_get: ' + module, {'host': host, 'module': module, 'data': data})
      return this.httpClient.get(host + prefix + module + (apiHost ? '/' : ''), {
        ...data,
        withCredentials: true
      })
      // return Vue.http.get(host + prefix + module + (apiHost ? '/' : ''), data)
    },
    debugLog: function (one, two) {

    },
    getProfileCred: function (userNumber) {
      userNumber = userNumber || false
      let $obj = {
        title: this.order.profile.title,
        first_name: this.order.profile.first_name,
        last_name: this.order.profile.last_name,
        subscribed: 1,
        phone: this.order.profile.phone,
        email: this.order.profile.email,
        currency_id: this.order.currency_id
      }
      if (userNumber) {
        $obj['user_number'] = userNumber
      }
      return $obj
    },
    getNewUserCred: function () {
      return {
        password: this.newPassword,
        email: this.order.profile.email,
        profile: this.getProfileCred()
      }
    },
    createUser: function () {
      if (this.newPassword === '' || this.confirmPassword === '') {
        this.messageError = 'Please enter password'
        return
      }
      if (this.newPassword !== this.confirmPassword) {
        this.messageError = 'Password mismatch'
        return
      }
      var self = this
      this.send_post('/users', this.getNewUserCred(), (this.$route.query.sspay ? this.integrationHost : false))
        .then((resp) => {
          self.disableButton = true
          self.messageInfo = 'Success registration'
          self.messageError = ''
          self.confirmSaveDetails = true
          if (this.sspayMode) {
            this.authorizationLogin()
            return this.setOrderUserSspay(resp)
          } else {
            return self.send_get('/checkout/checkout/set-order-user', {
              params: {
                order_id: self.order.order_number,
                profile_id: resp.data.profile.profile_number,
                user_id: resp.data.user_number
              }
            })
          }
        }, (err) => {
          self.messageError = err.data[0].message
        })
    },
    authorizationLogin: function (email, password) {
      email = email || false
      password = password || false
      this.$store.dispatch('changeFormData', {
        'data': {
          'email': (email || this.order.profile.email),
          'password': (password || this.newPassword)
        },
        'formName': 'popupLogin'
      })
      this.$store.dispatch('onSubmitPopupLogin', this.$router)
    },
    setOrderUserSspay: function (resp) {
      let self = this
      if (resp.data.id && resp.data.profile_id === undefined) {
        resp.data.profile_number = resp.data.id
      }
      this.debugLog('setOrderUserSspay', {
        'self.order.order_number': self.order.order_number,
        'resp.data.profile_number': resp.data.profile_number,
        'resp.data.profile.profile_number': resp.data.profile !== undefined && resp.data.profile.profile_number !== undefined ? resp.data.profile.profile_number : undefined,
        'profile_id': resp.data.profile_id,
        'user_id': resp.data.user_id || null
      })
      return this.send_get(config.checkout.setOrderUser, {
        params: {
          order_id: self.order.order_number,
          profile_id: resp.data.profile_id !== undefined ? resp.data.profile_id : resp.data.profile_number,
          user_id: resp.data.user_id || resp.data.user_number
        }
      }, this.sspayMode ? this.integrationHost : false).then(() => {
        this.checkGuestData = false
        this.$forceUpdate()
      })
    },
    print: function () {
      this.printMode = true
      setTimeout(() => (window.print()), 1000)
    },
    afterPrint: function () {
      this.printMode = false
    }
  },
  mounted: function () {
    let self = this
    setTimeout(() => (document.title = this.$trans('SelectSpecsTitleThank')), 300)
    if (window.matchMedia) {
      var mediaQueryList = window.matchMedia('print')
      mediaQueryList.addListener(function (mql) {
        if (mql.matches) {
        } else {
          self.afterPrint()
        }
      })
    }
  },
  created: function () {
    this.domain = currentCountry()
    try {
      Promise.resolve().then(() => {
        this.sspayMode = !(this.$route.query.sspay === undefined)
        this.chck = !(this.$route.query.chck === undefined)
        // this.fetchCountry()
        return this.fetchOrder(this.$route.params.order_id, this.$route.params.sspay)
      }).then(() => {
        // let ctr = localStorage.getItem('OT_GTM')
        // let code = null
        // if (ctr === 'us') {
        //   code = 'GTM-PC8DBCW'
        // }
        // testLocal && script.loadScript('getGa', null, code)
        // testLocal && script.loadScript('getEmarsys')
        // testLocal && script.loadScript('getFbPixel')
      })
    } catch (err) { console.log(err) }
  }
}
