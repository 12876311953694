import Breadcrumbs from '@/components/scripts/Breadcrumbs/index.vue'
import General from '@/components/scripts/HelpPages/Store/General/index.vue'
import Description from '@/components/scripts/HelpPages/Store/Description/index.vue'
import Map from '@/components/scripts/HelpPages/Store/Map/index.vue'

export default {
  name: 'Store',
  components: {
    Breadcrumbs,
    General,
    Description,
    Map
  }
}
