import { mapState } from 'vuex'
import Step from '@/components/scripts/Step/index.vue'
import TrustpilotWidget from '@/components/scripts/TrustpilotWidget/index.vue'
import config from '@/../config'
import checkoutIntegration from '@/components/scripts/Checkout/integration'
import axios from 'axios/index'

export default {
  name: 'fail',
  data () {
    return {
      msg: 'This is modules catalog',
      order: {}
    }
  },
  computed: {
    ...mapState({
      apiHost: (state) => state.apiHost,
      regData: (state) => state.regData,
      integrationHost: (state) => state.integrationHost
    }),
    httpClient: function () {
      return axios.create({
        // baseURL: config.apiHost + config.prefix
      })
    }
  },
  components: {
    Step,
    TrustpilotWidget
  },
  methods: {
    fetchOrder: function (orderId) {
      console.log('OrderId: ', orderId)
      let url = config.checkout.fetchOrderUrl
      this.send_get(url, {
        params: {
          'order_id': orderId
        }
      }, this.sspayMode ? this.integrationHost : false)
        .then((resp) => {
          this.order = resp.data
        }, (err) => {
          this.showLoader = false
          this.errorData = err.response && err.response.data && err.response.data.service && err.response.data.service[0] ? err.response.data.service[0] : 'Order not found'
          console.log(err)
        })
    },
    send_post: function (module, data, apiHost) {
      apiHost = apiHost || false
      let prefix = apiHost ? config.integrationPrefix : config.prefix
      let host = apiHost || this.apiHost
      this.debugLog('send_post: ' + module, {'host': host, 'module': module, 'data': data})
      // return Vue.http.post(host + prefix + module + (apiHost ? '/' : ''), data, this.requestOptions)
      var qs = require('qs')
      return this.httpClient.post(host + prefix + module + (apiHost ? '/' : ''), qs.stringify(data), {
        withCredentials: true,
        headers: {'Content-Type': 'application/x-www-form-urlencoded', 'Access-Control-Allow-Credentials': true}
      })
    },
    send_get: function (module, data, apiHost) {
      apiHost = apiHost || false
      let prefix = apiHost ? config.integrationPrefix : config.prefix
      let host = apiHost || this.apiHost
      this.debugLog('send_get: ' + module, {'host': host, 'module': module, 'data': data})
      return this.httpClient.get(host + prefix + module + (apiHost ? '/' : ''), {
        ...data,
        withCredentials: true
      })
      // return Vue.http.get(host + prefix + module + (apiHost ? '/' : ''), data)
    },
    debugLog: function (one, two) {

    }
  },
  mounted: function () {
    this.$store.dispatch('clearBasket')
    setTimeout(() => (document.title = this.$trans('SelectSpecsTitleFail')), 300)
  },
  created: function () {
    if (this.$route.name !== 'Thank' && ((process && process.env && process.env.NODE_ENV && process.env.NODE_ENV !== 'local') || !process || !process.env)) {
      try {
        checkoutIntegration.loadScript(this, document, 'getGa')
        checkoutIntegration.loadScript(this, document, 'getEmarsys')
        checkoutIntegration.loadScript(this, document, 'getFbPixel')
      } catch (err) { console.log(err) }
    }
    this.sspayMode = !(this.$route.query.sspay === undefined)
    this.fetchOrder(this.$route.params.id)
  }
}
