import {mapState} from 'vuex'
import StarRating from 'vue-star-rating'

export default {
  name: 'Reviews',
  components: {
    StarRating
  },
  data: function () {
    return {
      info: [],
      totalReviews: [],
      fiveReviews: [],
      totalPercentage: 0,
      showButtonHandler: false
    }
  },
  computed: {
    ...mapState({
      fetchData: (state) => state.reviews,
      itemId: (state) => state.route.params.item
    })
  },
  asyncData () {
    this.getDataReviews()
  },
  mounted () {
    this.getDataReviews()
  },
  methods: {
    async getDataReviews () {
      let itemId = this.itemId.toString().replace('.', '')
      await this.$store.dispatch('fetchDataReviews', itemId)
      this.info = JSON.parse(JSON.stringify(this.fetchData.reviews.reviews))
      this.totalReviews = this.fetchData.reviews.reviews
      this.percentageRecommendation()
      this.showFirstReviews()
    },
    showFirstReviews () {
      this.fiveReviews = this.info.splice(0, 5)
      if (this.totalReviews.length > 5) {
        this.showButtonHandler = true
      }
    },
    showFiveReviews: function () {
      if (this.info.length >= 5) {
        let showNextFive = this.info.splice(0, 5)
        this.fiveReviews = [...this.fiveReviews, ...showNextFive]
      } else if (this.info.length > 0) {
        let showNextFive = this.info.splice(0)
        this.fiveReviews = [...this.fiveReviews, ...showNextFive]
        this.showButtonHandler = false
      }
    },
    percentageRecommendation: function () {
      let totalNumberReviews = []
      if (this.fetchData.reviews.reviews.length > 0) {
        this.fetchData.reviews.reviews.map(el => totalNumberReviews.push(el.recommend_product))
        this.totalPercentage = (totalNumberReviews.reduce((acc, el) => acc + el, 0) / totalNumberReviews.length * 100).toFixed(0)
      } else {
        return
      }
    }
  }
}
