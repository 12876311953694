import { mapState } from 'vuex'
import Address from '@/components/scripts/Address/index.vue'
import config from '@/../config'
import Validator from '@/components/scripts/Validator'
import { createHTTPClient } from '@/utils/http'

let http = createHTTPClient()

export default {
  name: 'Profile',
  components: {
    'address-form': Address
  },
  computed: {
    ...mapState({
      data: (state) => {
        const {user: {profile: data}} = state
        const {...profile} = data

        return {
          profile
        }
      },
      meta: (state) => {
        let val = new Validator('profile')
        const result = val.prepare(state)

        if (!result.formError.profile) {
          result.formError.profile = {}
        }

        if (!result.formError.billingAddress) {
          result.formError.billingAddress = {}
        }

        if (!result.formError.deliveryAddress) {
          result.formError.deliveryAddress = {}
        }

        if (!result.formError.alt_deliveryAddress) {
          result.formError.alt_deliveryAddress = {}
        }

        return result
      },
      loading: (state) => state.loading,
      user: (state) => {
        const {...user} = state.user
        return user
      },
      profile: (state) => {
        const {user: {profile: data}} = state
        const {...profile} = data
        return profile
      },
      billing_address: (state) => {
        const {profile = {}} = state.user
        const {addresses = []} = profile
        return addresses.find((item) => {
          return item.address_type === 'billing'
        }) || {
          address_type: 'billing'
        }
      },
      delivery_address: (state) => {
        const {profile = {}} = state.user
        const {addresses = []} = profile
        return addresses.find((item) => {
          return item.address_type === 'delivery'
        }) || {
          address_type: 'delivery'
        }
      },
      alt_delivery_address: (state) => {
        const {profile = {}} = state.user
        const {addresses = []} = profile
        return addresses.find((item) => {
          return item.address_type === 'alt_delivery'
        }) || {
          address_type: 'alt_delivery'
        }
      },
      apiHost: (state) => state.apiHost,
      auth: (state) => state.authorization
    })
  },
  data () {
    return {
      countries: {},
      languages: {},
      currencies: {},
      addresses: {},
      formData: {
        title: null,
        first_name: null,
        last_name: null,
        phone: null,
        password: null
      }
    }
  },
  methods: {
    fetchCountries () {
      const {hashedPassword} = this.user

      http.get(config.profiles.countries, {
        headers: {
          'Authorization': 'Basic ' + btoa(hashedPassword + ':')
        },
        withCredentials: true
      })
        .then((result) => (this.countries = result.data.items), (err) => console.log('err::', err))
    },
    onSubmit (e) {
      var form = e.currentTarget.closest('form')
      let keys = Object.keys(this.addresses)
      let addresses = {}
      let promise = Promise.resolve(false)

      e.preventDefault()
      e.stopPropagation()

      form.classList.add('was-validated')
      var invalids = form.querySelectorAll('.form-control.is-invalid, .form-control:invalid')
      for (var i = 0; i < invalids.length; i++) {
        invalids[i].parentNode.classList.add('is-invalid-group')
      }

      if (keys.length > 0) {
        this.data.profile.addresses.map(address => {
          addresses[address.address_type] = address
        })
        keys.map(key => {
          let address = this.addresses[key]
          address.profile_id = this.data.profile.profile_number
          address.number = addresses[key] ? addresses[key].number : null
          addresses[key] = address
          promise = promise.then((data) => {
            if (data) {
              addresses[data.address_type] = data
            }
            return this.$store.dispatch('onSubmitAddress', {e, address})
          })
        })
      }

      promise.then((data) => {
        if (data) {
          addresses[data.address_type] = data
        }
        const profileData = Object.assign(this.data.profile)
        Object.keys(this.formData).forEach(key => {
          profileData[key] = this.formData[key]
        })
        profileData.addresses = []
        Object.keys(addresses).map(key => {
          profileData.addresses.push(addresses[key])
        })
        return this.$store.dispatch('onSubmitProfile', {
          profile: profileData
        })
      })
    },
    setAddress (data) {
      if (!data || !data.address_type) {
        return
      }
      this.addresses[data.address_type] = data
    }
  },
  mounted () {
    this.fetchCountries()
  },
  created () {
    if (this.data.profile.profile_number) {
      this.formData.title = this.data.profile.title
      this.formData.first_name = this.data.profile.first_name
      this.formData.last_name = this.data.profile.last_name
      this.formData.phone = this.data.profile.phone
      this.formData.email = this.data.profile.email
    }
  },
  watch: {
    meta (data) {
      if (!data || !data.formError) return
      let val = new Validator()
      val.goto(data.formError)
    }
  }
}
