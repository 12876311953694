import Flash from '@/components/scripts/FlashMessage/index.vue'
import { mapActions, mapState } from 'vuex'
import * as types from '@/store/types'

export default {
  name: 'UpdatePassword',
  computed: {
    ...mapState({
      meta (state) {
        return {
          ...state.formMeta['updatePassword'],
          message: state.formMeta.message
        }
      },
      profile (state) {
        return {...state.user.profile}
      }
    })
  },
  data () {
    return {
      showPassword: false,
      showNewPassword: false,
      newPassword: ''
    }
  },
  components: {
    Flash
  },
  methods: {
    ...mapActions({
      onChange: 'onChangeForm',
      showAlert: 'formMessage',
      updatePassword: 'onUpdatePassword'
    }),
    onSubmit () {
      this.$store.commit(types.FORM_FIELD_CLEAR, {formName: 'updatePassword'})
      if (!this.profile.password) {
        this.showAlert({
          message: 'Please, enter your password',
          type: 'warning'
        })
      } else if (!this.newPassword) {
        this.showAlert({
          message: 'Please, enter new password',
          type: 'warning'
        })
      } else {
        this.updatePassword({
          password: this.profile.password,
          newPassword: this.newPassword
        })
          .then(result => {
            if (result) {
              this.newPassword = ''
            }
          })
          .then(() => {
            this.$store.commit(types.PASSWORD_CLEAR)
          })
      }
    }
  }
}
