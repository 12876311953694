import { mapState } from 'vuex'

export default {
  name: 'SubMenuItem',
  props: ['menu', 'onClick', 'onHover', 'openDelay', 'closeDelay', 'target_id'],
  computed: {
    ...mapState({
      currency: (state) => ({...state.currency.allCurrency[state.currency.selected]}),
      exchange: (state) => state.currency.exchange,
      calculatePrice: (state) => state.calculatePrice
    })
  },
  methods: {
    convertMenuContext (context) {
      return this.convertContext(context)
    }
  },
  watch: {
    currency: {
      handler: function () {
        this.$forceUpdate()
      },
      deep: true
    }
  }
}
