import PrescriptionTable from '@/components/scripts/AccountPage/AccountOrders/OrderView/PrescriptionTable'
import TrackingList from '@/components/scripts/AccountPage/AccountOrders/OrderView/TrackingList'
import OrderInfo from '@/components/scripts/AccountPage/AccountOrders/OrderView/OrderInfo'
import ItemInfo from '@/components/scripts/AccountPage/AccountOrders/OrderView/ItemInfo'
import {mapState} from 'vuex'
import moment from 'moment'
// Only for tests in fallback case
import mockOrderJSON from './mockOrder'

export default {
  name: 'OrderView',
  components: {
    PrescriptionTable,
    TrackingList,
    OrderInfo,
    ItemInfo
  },
  computed: {
    ...mapState({
      meta: (state) => ({
        ...state.formMeta.orders || {}
      }),
      currencies: (state) => state.currency.allCurrency,
      user: (state) => {
        const {...user} = state.user

        return user
      }
    }),
    hasPrescription: function () {
      return true
    },
    cancelled: function () {
      return false
    }
  },
  data () {
    return {
      order: {}
    }
  },
  methods: {
    async loadOrder () {
      const user = await this.user
      const id = await this.$route.params.id
      // Only for tests in fallback case
      this.order = await this.$store.dispatch('fetchOrder', {user, id}) || mockOrderJSON
    },
    buyAgainItem (item) {
      console.log(item)
      this.$store.dispatch('addItemToBasket', item)
        .then(() => this.$router.push({name: this.$rname('Basket'), params: {lang: this.lang}}))
    },
    moment
  },
  mounted () {
    this.loadOrder()
  }
}
